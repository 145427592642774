import React from 'react';
import styled from 'styled-components';

/**
interface Props {
  v_pos: "top" | "bottom";
  h_pos: "left" | "right";
  offset: {h: number, v: number};
  onClick: () => void;
}
 */

const Button = styled.div`
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1563ee;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  display: grid;
  place-items: center;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    background-color: #5d92f1;
  }

  &.left {
    left: ${(props) => 10 + props.css_offset.h}px;
  }
  &.right {
    right: ${(props) => 10 + props.css_offset.h}px;
  }
  &.top {
    top: ${(props) => 10 + props.css_offset.v}px;
  }
  &.bottom {
    bottom: ${(props) => 10 + props.css_offset.v}px;
  }
`;

const FaqButton = ({ h_pos = 'left', v_pos = 'bottom', offset, onClick }) => {
  const classes = h_pos + ' ' + v_pos;
  return (
    <Button className={classes} css_offset={offset} onClick={() => onClick()}>
      ?
    </Button>
  );
};

export default FaqButton;
