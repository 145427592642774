import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router';
import oh from 'output-helpers';
import withStore from 'with-store';

import * as doc_helpers from '../../helpers/document_helpers';
import api_helpers from '../../helpers/api_helpers';

import PromptHOC from '../base/prompt_hoc/PromptHOC';
import DocPreview from '../base/DocPreview';
import Button from '../base/Button';
import Scroller from '../base/Scroller';

import FileSaver from 'file-saver';

const IconBack = require('react-icons/lib/md/arrow-back');
// const IconHistory = require('react-icons/lib/md/history');
const IconInformation = require('react-icons/lib/md/info');
const IconActions = require('react-icons/lib/md/build');
const IconEdit = require('react-icons/lib/md/edit');
const IconBusy = require('react-icons/lib/md/supervisor-account');
const IconClone = require('react-icons/lib/md/filter-none');
const IconExport = require('react-icons/lib/md/print');
// const IconCenter = require('react-icons/lib/md/filter-center-focus');
const IconAdd = require('react-icons/lib/md/add');
const IconRemove = require('react-icons/lib/md/remove');

const Panels = {
  NONE: 0,
  HISTORY: 1,
  INFORMATION: 2,
  ACTIONS: 3,
};

const fadeIn = keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
          transform: none;
        }
    `;

const fadeInLeft = keyframes`
        from {
          opacity: 0;
          transform: translateX(-10%);
        }
        to {
          opacity: 1;
          transform: none;
        }
    `;

const Container = styled.div`
  animation: ${fadeIn} 0.5s 0s both;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.dark[2]};
  overflow: hidden;
  .doc_preview_container {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.archived_doc {
    .archived_doc {
      display: flex;
    }
  }
  .archived_doc {
    display: none;
    height: 100px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    background-color: ${(props) => props.theme.colors.brand[1]};
    align-items: center;
    justify-content: center;
  }
  .doc_preview {
    cursor: default !important;
    transition: width 0.5s, height 0.5s;
    .doc_name {
      display: none;
    }
  }
  .preview_actions {
    margin: ${(props) =>
      props.theme.sizes.general.top_padding +
      'px ' +
      props.theme.sizes.general.side_padding +
      'px'};
    display: inline-block;
    z-index: 10;
    position: relative;
    .preview_action {
      animation: ${fadeInLeft} 0.5s 0.5s both;
      cursor: pointer;
      display: inline-block;
      border-radius: 200px;
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      padding: 12px;
      transition: all ${(props) => props.theme.sizes.general.transition_time}s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .preview_document_title {
      animation: ${fadeInLeft} 0.5s 0.2s both;
      display: inline-flex;
      height: 64px;
      margin-left: 20px;
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
    }
  }
  .preview_panels {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    display: grid;
    grid-template-columns: calc(100% - 240px) 240px;
    transition: grid-template-columns
      ${(props) => props.theme.sizes.general.transition_time}s;
    &.open_1 {
      grid-template-columns: calc(100% - 460px) 460px;
      .preview_panel_container {
        grid-template-columns: 300px 80px 80px;
      }
    }
    &.open_2 {
      grid-template-columns: calc(100% - 380px) 380px;
      .preview_panel_container {
        grid-template-columns: 300px 80px;
      }
    }
    &.open_3 {
      grid-template-columns: calc(100% - 380px) 380px;
      .preview_panel_container {
        grid-template-columns: 80px 300px;
      }
    }
    &.open_4 {
      // edit open
      grid-template-columns: 50% 50%;
    }
    .preview_panel_container {
      display: grid;
      grid-template-columns: 80px 80px 80px;
    }
    .preview_panel {
      border-left: 1px solid ${(props) => props.theme.colors.dark[0]};
      cursor: pointer;
      position: relative;
      &:not(.open) {
        &:hover {
          background-color: ${(props) => props.theme.colors.dark[0]};
        }
      }
      &.open {
        overflow: auto;
        cursor: default;
        background-color: ${(props) => props.theme.colors.dark[1]};
        .preview_panel_icon {
          display: none;
        }
        .preview_panel_title {
          user-select: none;
          background-color: ${(props) => props.theme.colors.dark[0]};
          margin-top: 0;
          text-align: left;
          padding: ${(props) => props.theme.sizes.general.top_padding}px;
          transition: background-color
            ${(props) => props.theme.sizes.general.transition_time}s
            ${(props) => props.theme.sizes.general.transition_time}s;
          p {
            transform: rotate(0deg);
          }
        }
        .preview_panel_block {
          height: auto;
          visibility: visible;
          opacity: 1;
          transition: all
            ${(props) => props.theme.sizes.general.transition_time}s
            ${(props) => props.theme.sizes.general.transition_time}s;
        }
        .preview_panel_actions {
          margin-top: 0;
        }
        .preview_panel_block_bottom {
          display: block;
        }
        .preview_panel_action {
          margin: 12px 20px;
          width: calc(100% - 40px);
          padding: 16px 20px;
          border-radius: 4px;
          background-color: ${(props) => props.theme.colors.brand[1]};
          &:disabled {
            opacity: 0.3;
            pointer-events: none;
          }
          &:hover {
            background-color: ${(props) => props.theme.colors.brand[2]};
          }
          .action_icon,
          .action_label {
            display: inline-block;
          }
          .action_icon {
            svg {
              height: 32px;
              width: 32px;
            }
          }
        }
      }
      .preview_panel_icon {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        display: none;
        &.small {
          left: 25px;
          right: 25px;
          bottom: 25px;
          svg {
            height: 30px;
            width: 30px;
          }
        }
        svg {
          height: 40px;
          width: 40px;
        }
      }
      .preview_panel_title {
        background-color: transparent;
        margin-top: 56px;
        text-align: right;
        transition: background-color 0s;
        p {
          user-select: none;
          transform: rotate(-90deg);
          transition: transform
            ${(props) => props.theme.sizes.general.transition_time}s;
        }
      }
      .preview_panel_block_bottom {
        border-top: 1px solid ${(props) => props.theme.colors.dark[0]};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 12px 20px 0;
        display: none;
      }
      .preview_panel_block {
        height: 0;
        padding: 12px ${(props) => props.theme.sizes.general.top_padding}px 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0s;
        border-bottom: 1px solid ${(props) => props.theme.colors.dark[0]};
      }
      .preview_panel_section {
        margin-bottom: 12px;
        .title {
          opacity: 0.7;
          font-size: 14px;
          margin-bottom: 8px;
        }
        .data {
          font-size: 16px;
          font-weight: bold;
          span {
            padding: 1px 2px;
            border-radius: 3px;
            background-color: rgb(207, 207, 207);
            color: ${(props) => props.theme.colors.dark[0]};
            margin: 4px;
            display: inline-block;
          }
        }
      }
    }
  }

  .preview_doc_scale {
    position: fixed;
    left: calc(100% - 450px);
    top: 200px;
    .preview_doc_scale_bigger,
    .preview_doc_scale_smaller {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      background-color: ${(props) => props.theme.colors.dark[1]};
      border: 1px solid ${(props) => props.theme.colors.dark[0]};
      &:hover {
        background-color: ${(props) => props.theme.colors.dark[0]};
      }
      svg {
        width: 28px;
        height: 28px;
      }
    }
    .preview_doc_scale_bigger {
      border-radius: 2px 2px 0 0;
      border-bottom: none;
    }
    .preview_doc_scale_smaller {
      border-radius: 0 0 2px 2px;
    }
  }

  .preview_panel_actions {
    margin-top: 52px;
  }

  .preview_panel_action {
    display: block;
    border-width: 0;
    color: #fff;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 20px;
    &:hover {
      background-color: #161a25;
    }
    .action_icon,
    .action_label {
      vertical-align: middle;
    }
    .action_icon {
      width: 44px;
      svg {
        height: 26px;
        width: 26px;
      }
    }
    .action_label {
      display: none;
      width: calc(100% - 44px);
      padding-left: 14px;
      text-align: left;
      .action_label_title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }
      .action_label_subtitle {
        font-size: 14px;
        opacity: 0.6;
      }
    }
  }
`;

class PreviewView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open_panel: 3,
      live_edit: false,
      preview_scale: 0.4,
      css_style: {
        width: 1240 * 0.4 + 'px',
        height: 1754 * 0.4 + 'px',
      },
    };
  }

  componentDidMount() {
    let doc = this.props.store_ext.getDoc(this.props.match.params.doc_id);
    if (doc) {
      this.requestPreview(doc);
    }
  }

  componentWillUnmount() {
    this.props.store_actions.image.setPreview(null);
  }

  renderScaleControl(doc) {
    let size = this.props.store_ext.getStyle(doc.settings.style_id);
    const doc_height = size.height;
    const doc_width = size.width;

    return (
      <div className="preview_doc_scale">
        <div
          className="preview_doc_scale_bigger"
          onClick={(e) => {
            e.stopPropagation();
            const new_scale =
              this.state.preview_scale < 1.0
                ? (10 * this.state.preview_scale + 1) / 10
                : this.state.preview_scale; // floating point fuckery
            this.setState({
              preview_scale: new_scale,
              css_style: {
                width: doc_width * new_scale + 'px',
                height: doc_height * new_scale + 'px',
              },
            });
          }}
        >
          <IconAdd />
        </div>
        <div
          className="preview_doc_scale_smaller"
          onClick={(e) => {
            e.stopPropagation();
            const new_scale =
              this.state.preview_scale > 0.2
                ? (10 * this.state.preview_scale - 1) / 10
                : this.state.preview_scale; // floating point fuckery
            this.setState({
              preview_scale: new_scale,
              css_style: {
                width: doc_width * new_scale + 'px',
                height: doc_height * new_scale + 'px',
              },
            });
          }}
        >
          <IconRemove />
        </div>
      </div>
    );
  }

  triggerArchiveDoc(doc) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('archived_items_cant_be_changed'),
      title: oh.translate('are_you_sure'),
      class: 'archive',
    };
    let archiveCallback = (values) => {
      this.props.store_actions.spinner.add();
      doc_helpers.archiveDocument(doc.id).then((archive_doc) => {
        this.props.store_actions.docs.setDocument(archive_doc);
        this.props.store_actions.spinner.remove();
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      archiveCallback,
    );
  }

  triggerDeleteDoc(doc) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('confirming_will_delete'),
      title: oh.translate('are_you_sure'),
      class: 'delete',
    };
    let deleteCallback = (values) => {
      this.props.store_actions.spinner.add();
      doc_helpers
        .deleteDocument(doc.id, doc.last_modified)
        .then((deleted_doc) => {
          this.props.store_actions.docs.deleteDoc(doc.id);
          this.goBack(deleted_doc);
          this.props.store_actions.spinner.remove();
        });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      deleteCallback,
    );
  }

  triggerCloneDoc(doc) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('confirm_create_a_doc_copy'),
      title: oh.translate('are_you_sure'),
      class: 'clone',
    };
    let cloneCallback = (values) => {
      this.props.store_actions.spinner.add();
      //TODO: Add input modal for name of new document.
      doc_helpers
        .cloneDoc(doc.id, doc.name + ' (' + oh.translate('clone', false) + ')')
        .then((doc_from_server) => {
          this.props.store_actions.docs.addDocument(doc_from_server);
          this.props.store_actions.spinner.remove();
          this.props.history.push('/folder/' + doc_from_server.folder_id);
        });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      cloneCallback,
    );
  }

  triggerExportDoc(doc, png = false) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('are_you_sure'),
      title: oh.translate('export'),
    };
    let exportCallback = (values) => {
      this.props.store_actions.spinner.addSmall();
      doc_helpers.exportDocument(doc, png).then((data) => {
        FileSaver.saveAs(data, doc.name + (png ? '.png' : '.pdf'));
        this.props.store_actions.spinner.removeSmall();
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      exportCallback,
    );
  }

  requestPreview(doc) {
    this.props.store_actions.spinner.addPreview();
    api_helpers.fetchPreview(doc.id).then((png) => {
      this.props.store_actions.spinner.removePreview();
      this.props.store_actions.image.setPreview(png);
      let size = this.props.store_ext.getStyle(doc.settings.style_id);
      const doc_height = size.height;
      const doc_width = size.width;
      this.setState({
        css_style: {
          width: doc_width * this.state.preview_scale + 'px',
          height: doc_height * this.state.preview_scale + 'px',
        },
      });
    });
  }

  openEdit(doc) {
    this.props.history.push('/edit/' + doc.id);
  }

  goBack(doc) {
    let origin_folder_id = this.props.match.params.origin_folder_id;
    if (origin_folder_id) {
      let folder = this.props.store.folders.by_id[origin_folder_id];
      if (folder) {
        this.props.history.push('/folder/' + origin_folder_id);
        return;
      }
    }

    /* intentional fall-through */
    this.props.history.push('/');
  }

  renderPreview(doc) {
    return (
      <div>
        {this.state.live_edit ? null : (
          <div className="preview_actions">
            <div className="preview_action" onClick={() => this.goBack(doc)}>
              <IconBack />
            </div>
            <div className="preview_document_title">
              <h1>{doc.name}</h1>
            </div>
          </div>
        )}

        <div
          className={`preview_panels ${
            this.state.open_panel ? 'open_' + this.state.open_panel : ''
          }`}
        >
          <Scroller className="preview_doc_render">
            <div className="archived_doc">
              <p>{oh.translate('archived_doc')}</p>
            </div>
            <DocPreview
              thumbnail={false}
              centered
              doc={doc}
              size="large"
              name={doc.name}
              dark_bg
              css_style={this.state.css_style}
              style_id={doc.settings.style_id}
            />
            {this.renderScaleControl(doc)}
          </Scroller>

          {this.state.live_edit ? null : this.renderPanels(doc)}
        </div>
      </div>
    );
  }

  // renderDocPanelHistory(doc) {
  //     return (
  //         <div
  //             className={`preview_panel ${this.state.open_panel === Panels.HISTORY ? 'open' : ''}`}
  //             onClick={() => this.setState({ open_panel: Panels.HISTORY })}
  //         >
  //             <div className="preview_panel_title">
  //                 <p>{oh.translate('history')}</p>
  //             </div>
  //             <div className="preview_panel_icon">
  //                 <IconHistory />
  //             </div>
  //             {
  //                 /* FIXME: reenable once we implement proper support for history
  //                 doc.change_log.map((item, i) => {
  //                     return (
  //                         <div key={i} className="preview_panel_block">
  //                             <div className="preview_panel_section">
  //                                 <p className="title">{oh.formatDateAsString(item.date, 'YYYY-MM-DD HH:mm')}</p>
  //                                 <p className="data">{item.type}</p>
  //                             </div>
  //                         </div>
  //                     );
  //                 })
  //                 */
  //             }
  //         </div>
  //     );
  // }
  renderDocPanelInformation(doc) {
    let last_log = doc.change_log[doc.change_log.length - 1];

    let description = '';
    if (!doc.structure_version && doc.description.hasOwnProperty('blocks')) {
      description = doc.description.blocks[0].text;
    } else if (doc.structure_version === 2) {
      //In V2 the description is a TextParagraph.
      description = doc.description.text;
    }

    return (
      <div
        className={`preview_panel ${
          this.state.open_panel === Panels.INFORMATION ? 'open' : ''
        }`}
        onClick={() => this.setState({ open_panel: Panels.INFORMATION })}
      >
        <div className="preview_panel_title">
          <p>{oh.translate('information')}</p>
        </div>
        <div className="preview_panel_icon">
          <IconInformation />
        </div>

        <div className="preview_panel_block">
          <div className="preview_panel_section">
            <p className="title">{oh.translate('doc_name')}</p>
            <p className="data">{doc.name}</p>
          </div>
          <div className="preview_panel_section">
            <p className="title">{oh.translate('doc_type')}</p>
            <p className="data">{oh.translate(doc.settings.type_id)}</p>
          </div>
          <div className="preview_panel_section">
            <p className="title">{oh.translate('created_by')}</p>
            <p className="data">{doc.created_by.full_name}</p>
          </div>
          <div className="preview_panel_section">
            <p className="title">{oh.translate('created')}</p>
            <p className="data">
              {oh.formatDateAsString(last_log.date, 'YYYY-MM-DD')}
            </p>
          </div>
          <div className="preview_panel_section">
            <p className="title">{oh.translate('last_edit')}</p>
            <p className="data">
              {oh.formatDateAsString(last_log.date, 'YYYY-MM-DD') +
                '(' +
                last_log.user.full_name +
                ')'}
            </p>
          </div>
        </div>
        <div className="preview_panel_block">
          <div className="preview_panel_section">
            <p className="title">{oh.translate('description')}</p>
            <p className="data">{description}</p>
          </div>
        </div>
        {doc.routes || doc.stops ? (
          <div className="preview_panel_block">
            {doc.routes ? (
              <div className="preview_panel_section">
                <p className="title">{oh.translate('bus_routes')}</p>
                <p className="data">
                  {doc.routes.map((route, i) => {
                    return <span key={i}>{route}</span>;
                  })}
                </p>
              </div>
            ) : null}
            {doc.stops ? (
              <div className="preview_panel_section">
                <p className="title">{oh.translate('bus_stops')}</p>
                <p className="data">
                  {doc.stops.map((stop, i) => {
                    return <span key={i}>{stop}</span>;
                  })}
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  renderEditButton(doc) {
    if (doc.archived || !doc.structure_version || doc.structure_version !== 2) {
      return null;
    }

    let my_id = this.props.store.users.my_id;

    const document_being_worked_on_by_somebody_else =
      this.props.store.open.by_id.hasOwnProperty(doc.id) &&
      this.props.store.open.by_id[doc.id].users.some((x) => x.user_id !== my_id)
        ? true
        : false;

    if (document_being_worked_on_by_somebody_else) {
      const name_of_user = this.props.store.open.by_id[doc.id].users[0].name;

      return (
        <button
          disabled={true}
          className="preview_panel_action"
          onClick={(e) => {
            e.stopPropagation();
            this.openEdit(doc);
          }}
        >
          <div className="action_icon">
            <IconBusy />
          </div>
          <div className="action_label">
            <p className="action_label_title">
              {oh.translate('edit')} ({oh.translate('busy')})
            </p>
            <p className="action_label_subtitle">
              <span>
                {name_of_user} {oh.translate('working_on_document')}
              </span>
            </p>
          </div>
        </button>
      );
    }

    return (
      <button
        className="preview_panel_action"
        onClick={(e) => {
          e.stopPropagation();
          this.openEdit(doc);
        }}
      >
        <div className="action_icon">
          <IconEdit />
        </div>
        <div className="action_label">
          <p className="action_label_title">{oh.translate('edit')}</p>
          <p className="action_label_subtitle">
            {oh.translate('edit_subtitle')}
          </p>
        </div>
      </button>
    );
  }

  renderFooterButtons(doc) {
    let my_id = this.props.store.users.my_id;
    if (doc.archived) {
      return null;
    }
    return (
      <div className="preview_panel_block_bottom">
        <div className="preview_panel_section">
          <Button
            disabled={
              this.props.store.open.by_id.hasOwnProperty(doc.id) &&
              this.props.store.open.by_id[doc.id].users.some(
                (x) => x.user_id !== my_id,
              )
            }
            block
            filled
            red
            label={oh.translate('archive')}
            onClick={() => {
              this.triggerArchiveDoc(doc);
            }}
          />
        </div>
        <div className="preview_panel_section">
          <Button
            disabled={
              this.props.store.open.by_id.hasOwnProperty(doc.id) &&
              this.props.store.open.by_id[doc.id].users.some(
                (x) => x.user_id !== my_id,
              )
            }
            block
            filled
            red
            label={oh.translate('remove')}
            onClick={() => {
              this.triggerDeleteDoc(doc);
            }}
          />
        </div>
      </div>
    );
  }

  renderDocPanelActions(doc) {
    return (
      <div
        className={
          'preview_panel' +
          (this.state.open_panel === Panels.ACTIONS ? ' open' : '')
        }
        onClick={() => this.setState({ open_panel: Panels.ACTIONS })}
      >
        <div className="preview_panel_title">
          <p>{oh.translate('actions')}</p>
        </div>
        <div className="preview_panel_icon small">
          <IconActions />
        </div>
        <div className="preview_panel_actions">
          {this.renderEditButton(doc)}
          <button
            className="preview_panel_action"
            onClick={(e) => {
              e.stopPropagation();
              this.triggerCloneDoc(doc);
            }}
          >
            <div className="action_icon">
              <IconClone />
            </div>
            <div className="action_label">
              <p className="action_label_title">{oh.translate('to_clone')}</p>
              <p className="action_label_subtitle">
                {oh.translate('to_clone_subtitle')}
              </p>
            </div>
          </button>
          <button
            className="preview_panel_action"
            onClick={(e) => {
              e.stopPropagation();
              this.triggerExportDoc(doc);
            }}
          >
            <div className="action_icon">
              <IconExport />
            </div>
            <div className="action_label">
              <p className="action_label_title">{oh.translate('export')} PDF</p>
              <p className="action_label_subtitle">
                {oh.translate('export_subtitle')}
              </p>
            </div>
          </button>
          <button
            className="preview_panel_action"
            onClick={(e) => {
              e.stopPropagation();
              this.triggerExportDoc(doc, true);
            }}
          >
            <div className="action_icon">
              <IconExport />
            </div>
            <div className="action_label">
              <p className="action_label_title">{oh.translate('export')} PNG</p>
              <p className="action_label_subtitle">
                {oh.translate('export_subtitle')}
              </p>
            </div>
          </button>
        </div>

        {this.renderFooterButtons(doc)}
      </div>
    );
  }

  renderPanels(doc) {
    return (
      <div className="preview_panel_container">
        {/* { this.renderDocPanelHistory(doc) } */}
        {this.renderDocPanelInformation(doc)}
        {this.renderDocPanelActions(doc)}
      </div>
    );
  }

  render() {
    let doc = this.props.store_ext.getDoc(this.props.match.params.doc_id);
    if (!doc) {
      return (
        <Container>
          <div className="preview_actions">
            <div className="preview_action" onClick={() => this.goBack(doc)}>
              <IconBack />
            </div>
          </div>
          <span style={{ color: '#fff', fontStyle: 'italic' }}>
            {oh.translate('document_does_not_exist')}
          </span>
        </Container>
      );
    }

    return (
      <Container className={`${doc.archived === true ? 'archived_doc' : ''}`}>
        {this.renderPreview(doc)}
      </Container>
    );
  }
}

export default withRouter(withStore(PromptHOC(PreviewView)));
