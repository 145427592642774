import React from 'react';
import styled from 'styled-components';

/*
    grid_type   : [labels, icons, colors]
    options     : [{presentation, value}]
    value       : option.value
*/

const Container = styled.div`
  text-align: left;
  p,
  .bool_block,
  .bool_input {
    vertical-align: middle;
    display: inline-block;
  }
  p {
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  .grid_block {
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.border};
    padding: 4px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 4px 4px;
  }
`;

const GridItem = styled.div`
  border: 4px solid transparent;
  box-shadow: 0 2px 3px rgba(38, 38, 38, 0.12);
  background-color: ${(props) => props.presentation};
  cursor: pointer;
  border-radius: 4px;
  padding: 12px 0;
  height: 24px;
  text-align: center;
  font-size: 12px;
  &:hover {
    border-color: #000;
  }
  &.active {
    border-color: #000;
  }
`;

class ColorGridSelect extends React.Component {
  render() {
    let req = this.props;

    let options = [];
    let buff_options = [];
    if (req.options) {
      buff_options = [];
      if (typeof req.options[0] === 'string') {
        req.options.forEach((option) => {
          buff_options.push({
            presentation: option,
            value: option,
          });
        });
        options = buff_options;
      } else {
        options = this.props.options;
      }
    }

    return (
      <Container className="user_input bool_input">
        {req.label ? <p>{req.label}</p> : null}
        <div className="grid_block">
          {options.map((item, i) => {
            return (
              <GridItem
                presentation={item.presentation}
                key={i}
                className={`grid_item ${
                  this.props.value === item.value ? 'active' : ''
                }`}
                onClick={() => {
                  this.props.onChange(item.value);
                }}
              />
            );
          })}
        </div>
      </Container>
    );
  }
}

export default ColorGridSelect;
