import React from 'react';
import { withRouter } from 'react-router';
import styled, { keyframes } from 'styled-components';
import withStore from 'with-store';
//const IconWait = require('react-icons/lib/md/more-horiz');
//const IconDone = require('react-icons/lib/md/check');

const fadeIn = keyframes`
            from {
              opacity: 0;
              transform: translate3d(0, 10%, 0);
            }
            to {
              opacity: 1;
              transform: none;
            }
        `;

const sk_rotate = keyframes`
            100% {
                transform: rotate(360deg)
            }
        `;

const sk_bounce = keyframes`
            0%, 100% {
                transform: scale(0.0);
            } 50% {
                transform: scale(1.0);
            }
        `;

const anim_small = keyframes`
            0% {
                transform: translateY(-200px);
            } 100% {
                transform: none;
            }
        `;

const anim_small_slide_away = keyframes`
            0% {
                transform: none;
            } 100% {
                transform: translateY(-200px);
            }
        `;

const anim_rota = keyframes`
            0%   { transform: rotate(0deg); }
            50% { transform: rotate(500deg); }
            100%   { transform: rotate(720deg); }
        `;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.fade};
  color: #fff;
  z-index: 10;
  text-align: center;
  .block {
    animation: ${fadeIn} 0.5s 0s both;
    margin: 14% 20%;
    font-size: 32px;
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
  }
  .spinner {
    margin: 100px auto;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    animation: ${sk_rotate} 2s infinite linear;
  }

  .dot1,
  .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    animation: ${sk_bounce} 2s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
  }
`;

const SmallSpinner = styled.div`
  .small_spinner_container_gfx {
    animation: ${anim_small} 0.6s;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 200px;
    border-radius: 0 0 14px 14px;
    color: #fff;
    background-color: ${(props) => props.theme.colors.dark[0]};
    &.hide {
      animation: ${anim_small_slide_away} 0.6s 0.4s both;
      .wrapper {
        .pie {
          opacity: 0;
        }
      }
    }
    .wrapper {
      width: 32px;
      height: 32px;
      position: relative;
      background-color: ${(props) => props.theme.colors.dark[0]};
      margin: 16px auto;
      .pie {
        transition: opacity 0.3s;
        width: 50%;
        height: 100%;
        position: absolute;
        border: 3px solid rgba(255, 255, 255, 0.8);
        border-right: none;
        &.brand {
          border: 3px solid ${(props) => props.theme.colors.dark[3]};
          border-right: none;
          animation-delay: 0.18s;
        }
      }
      .spinner {
        border-radius: 125px 0 0 125px;
        z-index: 200;
        animation: ${anim_rota} 1s infinite;
        transform-origin: right;
      }
    }
  }
`;

class Spinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_small_spinner: false,
      small_spinner_slide_away: false,
    };

    this.slide_away_timeout = null;
  }

  render() {
    if (
      this.props.store_ext.showSmallSpinner() &&
      !this.state.show_small_spinner
    ) {
      this.setState({
        show_small_spinner: true,
      });
    }
    if (
      !this.props.store_ext.showSmallSpinner() &&
      this.state.show_small_spinner &&
      !this.state.small_spinner_slide_away
    ) {
      this.setState({
        small_spinner_slide_away: true,
      });
      this.slide_away_timeout = setTimeout(() => {
        this.setState({
          show_small_spinner: false,
          small_spinner_slide_away: false,
        });
      }, 1000);
    }
    if (this.state.show_small_spinner) {
      return (
        <SmallSpinner>
          <div
            className={`small_spinner_container_gfx ${
              this.state.small_spinner_slide_away ? 'hide' : ''
            }`}
          >
            <div className="wrapper">
              <div className="spinner pie brand" />
              <div className="spinner pie" />
            </div>
          </div>
        </SmallSpinner>
      );
    }

    if (this.props.store_ext.showSmallSpinner()) {
      return (
        <Container>
          <div className="block">
            <div>
              <div className="spinner">
                <div className="dot1" />
                <div className="dot2" />
              </div>
            </div>
            <p>{this.props.text}</p>
          </div>
        </Container>
      );
    }

    return null;
  }
}

export default withRouter(withStore(Spinner));
