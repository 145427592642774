import * as types from './docs_reducer';

export function addDocument(document_data, state) {
  return {
    type: types.SET_DOCUMENT,
    payload: document_data,
  };
}
export function setDocument(document_data, state) {
  return {
    type: types.SET_DOCUMENT,
    payload: document_data,
  };
}

export function setCurrentDocId(doc_id, state) {
  return {
    type: types.APPEND_DOCUMENT_STATE,
    payload: {
      current_doc_id: doc_id,
    },
  };
}
export function clearCurrentDocId(state) {
  return {
    type: types.APPEND_DOCUMENT_STATE,
    payload: {
      current_doc_id: null,
    },
  };
}

export function setDocsStateByArr(doc_arr, fetched = false, state) {
  let by_id = {};
  doc_arr.forEach((doc) => {
    by_id[doc.id] = doc;
  });
  return {
    type: types.SET_DOCUMENT_STATE,
    payload: Object.assign({}, state, {
      by_id: by_id,
      all: [].concat(doc_arr),
      fetched: fetched,
    }),
  };
}

export function deleteDoc(doc_id, state) {
  if (state.by_id.hasOwnProperty(doc_id) === false) {
    throw new Error('Doc id does not exist in state when deleting doc.');
  }
  let updated_all = [].concat(state.all);
  let updated_by_id = Object.assign({}, state.by_id);
  let doc = updated_by_id[doc_id];
  let index = updated_all.indexOf(doc);

  updated_all.splice(index, 1);
  delete updated_by_id[doc_id];

  return {
    type: types.SET_DOCUMENT_STATE,
    payload: Object.assign({}, state, {
      by_id: updated_by_id,
      all: updated_all,
    }),
  };
}
