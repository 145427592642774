import React from 'react';
import styled from 'styled-components';

/**
interface Props {
  url: string;
}
 */

const Container = styled.div`
  margin-top: 4px;
  margin-bottom: 10px;
  width: 100%;
  img {
    margin-left: 5%;
    width: 90%;
  }
`;

const FaqImage = ({ url }) => {
  return (
    <Container>
      <a href={url} target="blank">
        <img src={url} alt="" />
      </a>
    </Container>
  );
};

export default FaqImage;
