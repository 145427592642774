import React from 'react';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';
import { ItemTypes } from '../../DnDItemTypes';

const Container = styled.p`
  display: inline-block;
  cursor: pointer;
  margin: ${(props) => (props.isOver ? '12px 0px !important' : '16px 8px')};
  padding: ${(props) => (props.isOver ? '22px 50px !important' : '6px 8px')};
  opacity: ${(props) => (props.isOver ? '1 !important' : '1')};
  border: ${(props) =>
    props.isOver ? '4px solid ' + props.theme.colors.brand[1] : 'none'};
  border-radius: 8px;
  background-color: ${(props) =>
    props.isOver ? props.theme.colors.brand[2] : 'transparent'};
  color: ${(props) => (props.isOver ? '#fff' : props.theme.colors.text)};
  font-size: ${(props) => (props.isOver ? '18px' : '18px')};
  font-weight: bold;
  transition: all 0.3s;
  &:hover {
    border-color: ${(props) => props.theme.colors.brand[1]};
    background-color: ${(props) => props.theme.colors.brand[1]};
    color: #fff;
  }

  &.current {
    cursor: default;
    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
      background-color: transparent;
      color: ${(props) => props.theme.colors.text};
    }
  }
  &.root {
    margin-left: 0;
    font-weight: bold;
    padding-left: 0;
    &:hover {
      color: ${(props) => props.theme.colors.text};
      opacity: 0.6;
      background-color: transparent;
    }
  }
`;

class Crumble extends React.Component {
  render() {
    const { connectDropTarget, isOver } = this.props;
    return connectDropTarget(
      <span>
        <Container
          className={`${this.props.className}`}
          isOver={isOver}
          onClick={() => (this.props.onClick ? this.props.onClick() : null)}
        >
          {this.props.label}
        </Container>
      </span>,
    );
  }
}

const folderTarget = {
  canDrop(props) {
    return true;
  },
  drop(props, monitor) {
    if (monitor.getItem().type === 'document') {
      props.moveDocToThisFolder(monitor.getItem().item);
    } else if (monitor.getItem().type === 'folder') {
      props.moveFolderToThisFolder(monitor.getItem().item, props.folder.id);
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget(ItemTypes.DOCUMENT, folderTarget, collect)(Crumble);
