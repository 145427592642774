import React from 'react';
import { ThemeProvider, injectGlobal } from 'styled-components';
import AllbinaryTheme, { reset_css } from '../../themes/allbinary.js';

class StyledBase extends React.Component {
  constructor(props) {
    super(props);

    this.theme = AllbinaryTheme;

    injectGlobal`
            ${reset_css.reset}
        `;
  }

  render() {
    return (
      <ThemeProvider theme={this.theme}>{this.props.children}</ThemeProvider>
    );
  }
}

export default StyledBase;
