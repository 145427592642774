import * as store_helpers from './store_helpers';

export const SET_DOCUMENT = 'SET_DOCUMENT';
export const APPEND_DOCUMENT = 'APPEND_DOCUMENT';
export const SET_DOCUMENT_STATE = 'SET_DOCUMENT_STATE';
export const APPEND_DOCUMENT_STATE = 'APPEND_DOCUMENT_STATE';
export const DOCUMENT_STATE_NO_CHANGE = 'DOCUMENT_STATE_NO_CHANGE';

let default_state = {
  fetched: false,
  all: [],
  by_id: {},
  undo: [],
  undo_offset: 0,
  syncing: false,
  current_doc_id: null,
};

export default function documentReducer(state = default_state, action) {
  switch (action.type) {
    case SET_DOCUMENT_STATE: {
      return Object.assign({}, action.payload);
    }
    case APPEND_DOCUMENT_STATE: {
      return Object.assign({}, state, action.payload);
    }
    case SET_DOCUMENT: {
      return store_helpers.SetItem(action.payload, state);
    }
    default: {
      return state;
    }
  }
}
