import React from 'react';
import FileFolderDropdown from './FileFolderDropdown';
import withStore from 'with-store';
import oh from 'output-helpers';
import { DropTarget, DragSource } from 'react-dnd';
import { ItemTypes } from '../../DnDItemTypes';
const IconArchive = require('react-icons/lib/md/archive');

class Folder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let dropdown_options = [
      {
        label: oh.translate('edit_details'),
        fn: () => {
          if (this.props.editMetaCB) {
            this.props.editMetaCB();
          }
        },
      },
    ];

    let ndocs_in_folder = withStore
      .getState()
      .docs.all.filter((x) => x.folder_id === this.props.folder.id).length;
    let nfolders_in_folder = withStore
      .getState()
      .folders.all.filter((x) => x.parent_id === this.props.folder.id).length;

    if (ndocs_in_folder + nfolders_in_folder === 0) {
      dropdown_options.push({
        label: oh.translate('remove'),
        fn: () => {
          if (this.props.deleteFolderCB) {
            this.props.deleteFolderCB();
          }
        },
      });
    }
    /* FIXME: no archiving of folders in v1
            {
                label: oh.translate('archive'),
                fn: () => {
                    if (this.props.archiveFolderCB) {
                        this.props.archiveFolderCB();
                    }
                }
            }
            */

    const {
      connectDropTarget,
      isOver,
      connectDragSource,
      isDragging,
    } = this.props;

    const component = (
      <this.props.containerDiv
        isDragging={isDragging}
        isOver={isOver}
        archive={this.props.archive}
        size={this.props.size}
        centered={this.props.centered}
        dark_bg={this.props.dark_bg}
        draggable
        className={`doc_preview ${isOver ? 'is_over' : ''}`}
        onClick={() => (this.props.onClick ? this.props.onClick() : null)}
        style={this.props.style}
        onMouseEnter={() => {
          this.props.onMouseEnter();
        }}
        onMouseLeave={() => {
          this.props.onMouseLeave();
        }}
      >
        <div className="folder">
          <div className="folder_icon">
            <IconArchive />
          </div>
          <div className="folder_docs_count">
            <p>{this.props.docs_count}</p>
          </div>
        </div>
        <div className="folder_bg_container">
          <div className="folder_tab"></div>
          <div className="folder_bg"></div>
        </div>
        <p className="folder_name">{this.props.folder.name}</p>
        {this.props.folder.is_archive ? null : (
          <FileFolderDropdown
            styledDiv={this.props.fileFolderDropDownDiv}
            size={this.props.size}
            dropdown_options={dropdown_options}
          />
        )}
      </this.props.containerDiv>
    );

    return (
      <div>
        {connectDropTarget
          ? connectDropTarget(
              <div className="folder_container">{component}</div>,
            )
          : connectDragSource(
              <div className="folder_container">{component}</div>,
            )}
      </div>
    );
  }
}

const folderTarget = {
  canDrop(props) {
    return true;
  },

  drop(props, monitor) {
    if (monitor.getItem().type === 'document') {
      if (props.archive) {
        props.archiveDocCB(monitor.getItem().item);
      } else {
        props.moveDocToThisFolder(monitor.getItem().item);
      }
    } else if (monitor.getItem().type === 'folder') {
      if (props.archive) {
        props.moveFolderToThisFolder(monitor.getItem().item, props.folder.id);
      } else {
        props.moveFolderToThisFolder(monitor.getItem().item, props.folder.id);
      }
    }
  },
};

const folderSource = {
  beginDrag(props) {
    return { type: 'folder', item: props.folder };
  },
};

function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

// DROP
export default DropTarget(ItemTypes.DOCUMENT, folderTarget, collect)(Folder);
export const FolderDragSource = DragSource(
  ItemTypes.DOCUMENT,
  folderSource,
  dragCollect,
)(Folder);
