import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router';
import oh from 'output-helpers';
import withStore from 'with-store';
import * as doc_helpers from '../../helpers/document_helpers';
import api_helpers from '../../helpers/api_helpers';
import PromptHOC from '../base/prompt_hoc/PromptHOC';
import DocPreview from '../base/DocPreview';
import Button from '../base/Button';
import Scroller from '../base/Scroller';
import EditMapView from './EditMapView';
import Dropdown from '../base/Dropdown';
import * as doc_definitions from '../../doc_definitions';
import all_styles from '../../style_templates';
import EditText from '../base/edit_parts/EditText';
import FAQ from '../base/FAQ';
// const IconUndo = require('react-icons/lib/md/undo');
// const IconRedo = require('react-icons/lib/md/redo');
const IconBack = require('react-icons/lib/md/arrow-back');
// const IconCenter = require('react-icons/lib/md/filter-center-focus');
const IconAdd = require('react-icons/lib/md/add');
const IconRemove = require('react-icons/lib/md/remove');

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: none;
    }
`;

const fade_right = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fade_down = keyframes`
    from {
        opacity: 0;
        transform: translateY(80%);
        z-index: 3;
    }
    to {
        opacity: 1;
        transform: none;
    }
`;

const bounce_back = keyframes`
    0% {
        transform: translateX(0%);
    }
    20% {
        transform: translateX(0%);
    }
    80% {
        transform: translateX(-80%);
    }
    100% {
        transform: translateX(-80%);
    }
`;

const fadeInLeft = keyframes`
    from {
      opacity: 0;
      transform: translateX(-10%);
    }
    to {
      opacity: 1;
      transform: none;
    }
`;

const footer_height = 80;

const Container = styled.div`
  animation: ${fadeIn} 0.25s 0s both;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.dark[2]};
  overflow: hidden;

  .preview_panels {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .doc_preview_container {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.5s, height 0.5s;
  }

  .doc_preview {
    cursor: default !important;
    transition: width 0.5s, height 0.5s;
  }

  .preview_actions {
    margin: ${(props) =>
      props.theme.sizes.general.top_padding +
      'px ' +
      props.theme.sizes.general.side_padding +
      'px'};
    display: inline-block;
    z-index: 10;
    position: relative;

    .preview_action {
      animation: ${fadeInLeft} 0.25s 0.25s both;
      cursor: pointer;
      display: inline-block;
      border-radius: 200px;
      background-color: ${(props) => props.theme.colors.dark[1]};
      color: #fff;
      padding: 12px;
      transition: all ${(props) => props.theme.sizes.general.transition_time}s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  .preview_doc_scale {
    position: fixed;
    left: 45%;
    top: 200px;
    .preview_doc_scale_bigger,
    .preview_doc_scale_smaller {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      background-color: ${(props) => props.theme.colors.dark[1]};
      border: 1px solid ${(props) => props.theme.colors.dark[0]};
      &:hover {
        background-color: ${(props) => props.theme.colors.dark[0]};
      }
      svg {
        width: 28px;
        height: 28px;
      }
    }
    .preview_doc_scale_bigger {
      border-radius: 2px 2px 0 0;
      border-bottom: none;
    }
    .preview_doc_scale_smaller {
      border-radius: 0 0 2px 2px;
    }
  }

  .live_edit_panel_content {
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.background};
    position: relative;
    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.2);
    overflow: auto;
    padding-bottom: 100px;
    .live_edit_panel_content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: calc(100% - ${footer_height}px);
      overflow: auto;
    }
    .live_edit_footer {
      background-color: #fff;
      height: ${footer_height}px;
      padding: 22px 28px;
      border-top: 1px solid ${(props) => props.theme.colors.border};
      position: fixed;
      width: 50%;
      bottom: 0;
      right: 0;
      z-index: 1;
      .live_edit_footer_left,
      .live_edit_footer_right {
        display: inline-block;
        width: 50%;
        white-space: nowrap;
        vertical-align: top;
        overflow: hidden;
      }
      .live_edit_footer_right {
        text-align: right;
        .button {
          margin-left: 20px;
        }
      }
      .round_action_button {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: ${(props) => props.theme.colors.border};
        transition: all 0.1s;
        &:first-child {
          margin-right: 20px;
        }
        &:hover {
          box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
          background-color: ${(props) => props.theme.colors.dark[1]};
          color: #fff;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
        svg {
          margin: 8px;
          width: 24px;
          height: 24px;
          display: table-cell;
          vertical-align: middle;
        }
      }
      .action_message {
        pointer-events: none;
        display: inline-block;
        height: 40px;
        margin-left: 16px;
        font-size: 14px;
        opacity: 0.6;
        width: 100%;
        overflow: hidden;
        position: relative;
        p {
          user-select: none;
          animation: ${fade_right} 0.2s;
          height: 40px;
          vertical-align: middle;
          display: table-cell;

          &:hover {
            animation: ${bounce_back} 2s linear infinite;
            animation-direction: alternate;
          }
        }
      }
    }
    .preview_panel {
      border-left: 1px solid ${(props) => props.theme.colors.dark[0]};
      cursor: pointer;
      &:not(.open) {
        &:hover {
          background-color: ${(props) => props.theme.colors.dark[0]};
        }
      }
      &.open {
        overflow: auto;
        cursor: default;
        background-color: ${(props) => props.theme.colors.dark[1]};
        .preview_panel_title {
          user-select: none;
          background-color: ${(props) => props.theme.colors.dark[0]};
          margin-top: 0;
          text-align: left;
          padding: ${(props) => props.theme.sizes.general.top_padding}px;
          transition: background-color
            ${(props) => props.theme.sizes.general.transition_time}s
            ${(props) => props.theme.sizes.general.transition_time}s;
          p {
            transform: rotate(0deg);
          }
        }
        .preview_panel_block {
          height: auto;
          visibility: visible;
          opacity: 1;
          transition: all
            ${(props) => props.theme.sizes.general.transition_time}s
            ${(props) => props.theme.sizes.general.transition_time}s;
        }
      }
      .preview_panel_title {
        background-color: transparent;
        margin-top: 56px;
        text-align: right;
        transition: background-color 0s;
        p {
          user-select: none;
          transform: rotate(-90deg);
          transition: transform
            ${(props) => props.theme.sizes.general.transition_time}s;
        }
      }
      .preview_panel_block {
        height: 0;
        padding: 12px ${(props) => props.theme.sizes.general.top_padding}px 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0s;
        border-bottom: 1px solid ${(props) => props.theme.colors.dark[0]};
        .preview_panel_section {
          margin-bottom: 12px;
          .title {
            opacity: 0.7;
            font-size: 14px;
            margin-bottom: 8px;
          }
          .data {
            font-size: 16px;
            font-weight: bold;
            span {
              padding: 1px 2px;
              border-radius: 3px;
              background-color: rgb(207, 207, 207);
              color: ${(props) => props.theme.colors.dark[0]};
              margin: 4px;
              display: inline-block;
            }
          }
        }
      }
    }
    .live_edit_row {
      display: table;
      width: 100%;
    }
    .live_edit_section {
      animation: ${fade_down} 0.5s backwards;
      z-index: 2;
      background-color: #fff;
      padding: 22px 28px;
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
      &.half_width {
        vertical-align: top;
        display: table-cell;
        width: 50%;
        border-right: 1px solid ${(props) => props.theme.colors.border};
        &:last-child {
          border-right: 0;
        }
      }
      .live_edit_section_label {
        color: ${(props) => props.theme.colors.dark[1]};
        font-size: 14px;
        margin-bottom: 12px;
        font-weight: bold;
      }
      .live_edit_section_data {
        input,
        .DraftEditor-root {
          border: 1px solid ${(props) => props.theme.colors.border};
          border-radius: ${(props) =>
            props.theme.sizes.general.input_radius + 'px'};
          font-size: 16px;
          padding: ${(props) => props.theme.sizes.general.input_padding};
          width: 100%;
          transition: all
            ${(props) => props.theme.sizes.general.transition_time}s;
          &:hover,
          &:focus {
            border-color: ${(props) => props.theme.colors.brand[2]};
          }
        }
      }
    }
  }
`;

class EditView extends React.Component {
  constructor(props) {
    super(props);

    let doc = this.props.store_ext.getDoc(this.props.match.params.doc_id);
    if (!doc) {
      this.state = {};
    } else {
      this.state = {
        open_panel: false,
        preview_scale: 0.4,
        css_style: {
          width: 1240 * 0.4 + 'px',
          height: 1754 * 0.4 + 'px',
        },
        action_message: '',
        render_edit_map: null,
        undo: {
          offset: 0,
          items: [
            Object.keys(doc.settings).reduce((a, c) => {
              a[c] = { from: doc.settings[c], to: doc.settings[c] };
              return a;
            }, {}),
          ],
        },
      };
    }

    this.open_timeout = null;
    this.open_syncing = false;

    this.saving = false;
    this.need_additional_save = false;
    this.need_full_save = false;
  }

  componentDidMount() {
    let doc = this.props.store_ext.getDoc(this.props.match.params.doc_id);
    if (!doc) {
      return;
    }
    this.current_doc = doc;

    let my_id = this.props.store.users.my_id;
    if (
      this.props.store.open.by_id.hasOwnProperty(doc.id) &&
      this.props.store.open.by_id[doc.id].users.some((x) => x.user_id !== my_id)
    ) {
      this.props.history.push('/preview/' + doc.id + '/' + doc.folder_id);
      return;
    }

    this.requestPreview(doc);

    let openSync = () => {
      if (this.open_syncing) {
        return;
      }

      this.open_syncing = true;
      api_helpers.openDocument(doc).finally(() => {
        this.open_syncing = false;
      });

      this.open_timeout = setTimeout(() => {
        return openSync();
      }, 20000);
    };

    openSync();

    if (this.props.match.params.map !== undefined) {
      this.setState({
        render_edit_map: 'map_0',
      });
    }
  }

  componentWillUnmount() {
    if (this.open_timeout) {
      clearTimeout(this.open_timeout);
      this.open_timeout = null;
    }
    if (this.current_doc) {
      api_helpers.closeDocument(this.current_doc);
    }
  }

  renderScaleControl(doc) {
    let size = this.props.store_ext.getStyle(doc.settings.style_id);
    const doc_height = size.height;
    const doc_width = size.width;

    return (
      <div className="preview_doc_scale">
        <div
          className="preview_doc_scale_bigger"
          onClick={(e) => {
            e.stopPropagation();
            const new_scale =
              this.state.preview_scale < 1.0
                ? (10 * this.state.preview_scale + 1) / 10
                : this.state.preview_scale; // floating point fuckery
            this.setState({
              preview_scale: new_scale,
              css_style: {
                width: doc_width * new_scale + 'px',
                height: doc_height * new_scale + 'px',
              },
            });
          }}
        >
          <IconAdd />
        </div>
        <div
          className="preview_doc_scale_smaller"
          onClick={(e) => {
            e.stopPropagation();
            const new_scale =
              this.state.preview_scale > 0.3
                ? (10 * this.state.preview_scale - 1) / 10
                : this.state.preview_scale; // floating point fuckery
            this.setState({
              preview_scale: new_scale,
              css_style: {
                width: doc_width * new_scale + 'px',
                height: doc_height * new_scale + 'px',
              },
            });
          }}
        >
          <IconRemove />
        </div>
      </div>
    );
  }

  cancelEdit(doc) {
    if (!doc) {
      return;
    }
    let existing_doc = this.props.store.docs.by_id[doc.id];
    let altered_doc = Object.assign({}, existing_doc, {
      settings: Object.assign({}, existing_doc.settings),
      tmp_settings: Object.assign({}, existing_doc.tmp_settings),
    });
    if (altered_doc.timemachine_log.length > 0) {
      let prev_changes =
        altered_doc.timemachine_log[altered_doc.timemachine_log.length - 1];
      prev_changes = JSON.parse(prev_changes.settings);
      altered_doc.settings = prev_changes;
      altered_doc.tmp_settings = {};
      altered_doc = doc_helpers.unpackDocumentSettings(altered_doc);
    } else {
      //Noone has ever committed any change in settings to this document, reset to default.
      altered_doc.settings = doc_definitions.getDefaultSettings(
        this.props.store.config,
      );
      altered_doc.tmp_settings = {};
      altered_doc = doc_helpers.unpackDocumentSettings(altered_doc);
    }
    this.props.store_actions.spinner.add();
    api_helpers.saveDocument(altered_doc).then((updated_doc) => {
      this.props.store_actions.docs.setDocument(updated_doc);
      this.props.store_actions.spinner.remove();
      this.props.history.push('/preview/' + doc.id + '/' + doc.folder_id);
    });
  }

  fullSaveAndExit() {
    let doc = this.current_doc;
    doc_helpers.createTimemachineAndSaveToServer(doc).then((saved_doc) => {
      api_helpers
        .updateThumbnail(doc.id)
        .then((base64_thumbnail) => {
          this.props.store_actions.image.setThumbnail({
            id: doc.id,
            base64_data: base64_thumbnail,
          });
        })
        .catch((err) => {
          console.error('Error updating thumbnail:');
          console.error(err);
        });
      this.props.store_actions.spinner.remove();
      this.props.store_actions.docs.setDocument(saved_doc);
      this.props.history.push('/preview/' + doc.id + '/' + doc.folder_id);
    });
  }

  confirmSettings(doc) {
    if (!doc) {
      return;
    }
    if (this.state.undo.items.length < 2) {
      return;
    }
    if (this.want_full_save === true) {
      return;
    }
    this.props.store_actions.spinner.add();
    this.want_full_save = true;
    if (this.saving !== true) {
      // save, and exit, immediately. otherwise, we'll save and exit once the current save is done
      this.fullSaveAndExit();
    } else {
      this.need_additional_save = true;
    }
  }

  requestPreview(doc) {
    this.props.store_actions.spinner.addPreview();
    api_helpers.fetchPreviewTmp(doc.id).then((png) => {
      this.props.store_actions.spinner.removePreview();
      this.props.store_actions.image.setPreview(png);
      let size = this.props.store_ext.getStyle(doc.settings.style_id);
      const doc_height = size.height;
      const doc_width = size.width;
      this.setState({
        css_style: {
          width: doc_width * this.state.preview_scale + 'px',
          height: doc_height * this.state.preview_scale + 'px',
        },
      });
    });
  }

  saveTmpSettings() {
    if (this.saving) {
      this.need_additional_save = true;
      return;
    }
    this.need_additional_save = false;
    this.saving = true;

    if (this.want_full_save) {
      this.fullSaveAndExit();
      return;
    }
    api_helpers.saveDocument(this.current_doc).then((updated_doc) => {
      let haxx_doc = Object.assign({}, this.current_doc, {
        last_modified: updated_doc.last_modified,
      });
      this.current_doc = haxx_doc;
      this.props.store_actions.docs.setDocument(haxx_doc);
      if (this.state.render_edit_map) {
        this.saving = false;
        if (this.need_additional_save) {
          this.saveTmpSettings();
        }
      } else {
        this.props.store_actions.spinner.addPreview();
        return api_helpers.fetchPreviewTmp(haxx_doc.id).then((png) => {
          this.props.store_actions.spinner.removePreview();
          this.props.store_actions.image.setPreview(png);
          this.saving = false;
          if (this.need_additional_save) {
            this.saveTmpSettings();
          }
        });
      }
    });
  }

  undo() {
    if (this.state.undo.offset < 1) {
      return;
    }

    let commit = this.state.undo.items[this.state.undo.offset];
    let updates = {};

    Object.keys(commit).forEach((prop_name) => {
      let prop = commit[prop_name];
      updates[prop_name] = prop.from;
    });

    let new_undo = {
      offset: this.state.undo.offset - 1,
      items: this.state.undo.items,
    };

    console.log('[undo] UNDO: switching to this:', new_undo);
    this.setState({
      undo: new_undo,
    });

    this.applySettings(this.current_doc, updates, false);
  }

  redo() {
    if (this.state.undo.offset >= this.state.undo.items.length - 1) {
      return;
    }

    let commit = this.state.undo.items[this.state.undo.offset + 1];
    let updates = {};

    Object.keys(commit).forEach((prop_name) => {
      let prop = commit[prop_name];
      updates[prop_name] = prop.to;
    });

    let new_undo = {
      offset: this.state.undo.offset + 1,
      items: this.state.undo.items,
    };

    console.log('[undo] REDO: switching to this:', new_undo);
    this.setState({
      undo: new_undo,
    });

    this.applySettings(this.current_doc, updates, false);
  }

  applySettings(doc, updates, create_undo_commit = true) {
    if (this.want_full_save) {
      return;
    }
    if (Object.keys(updates).length === 0) {
      return;
    }
    if (create_undo_commit) {
      let undo_commit = {};
      let undo_method = null;
      let props_to_change = Object.keys(updates);

      props_to_change.forEach((prop) => {
        undo_commit[prop] = { from: doc.tmp_settings[prop], to: updates[prop] };
        if (
          undo_method &&
          doc_definitions.props.settings[prop].undo_behavior !== undo_method
        ) {
          //If there is an undo method and the prop's defined behavior does not match,
          //we play it safe and record a change.
          undo_method = 'every';
        } else if (!undo_method) {
          undo_method = doc_definitions.props.settings[prop].undo_behavior;
        }
      });

      if (undo_method === 'on_prop_change') {
        if (this.state.undo.items.length >= 1) {
          let previous_undo_props = Object.keys(
            this.state.undo.items[this.state.undo.items.length - 1],
          );
          let same_props = previous_undo_props.every((key) =>
            props_to_change.includes(key),
          );
          if (same_props) {
            // manipulate the previous commit, since we're still editing the same property
            undo_commit = Object.assign(
              {},
              this.state.undo.items[this.state.undo.offset],
            );
            props_to_change.forEach((prop) => {
              undo_commit[prop].to = updates[prop];
            });
            //console.log("[undo] manipulated prior commit (" + (this.state.undo.offset) + "):", undo_commit);
            this.setState({
              undo: {
                offset: this.state.undo.offset,
                items: this.state.undo.items
                  .slice(0, this.state.undo.offset)
                  .concat(undo_commit),
              },
            });

            // don't create an additional undo commit if we haven't changed anything
            undo_commit = null;
          }
        }
      }

      if (undo_commit) {
        // console.log("[undo] created commit (" + (this.state.undo.offset + 1) + "):", undo_commit);
        if (
          this.state.undo.offset <
          this.props.store.config.maximum_undo_steps - 1
        ) {
          let new_undo = {
            offset: this.state.undo.offset + 1,
            items: this.state.undo.items
              .slice(0, this.state.undo.offset + 1)
              .concat(undo_commit),
          };
          this.setState({
            undo: new_undo,
          });
        } else {
          let new_undo = {
            offset: this.state.undo.offset,
            items: this.state.undo.items
              .slice(1, this.state.undo.offset + 1)
              .concat(undo_commit),
          };
          this.setState({
            undo: new_undo,
          });
        }
      }
    }

    let updated_doc = Object.assign({}, doc, {
      tmp_settings: Object.assign({}, doc.tmp_settings, updates),
      saved: false,
      last_change: new Date(),
    });
    this.props.store_actions.docs.setDocument(updated_doc);
    this.current_doc = updated_doc;

    this.saveTmpSettings();
  }

  renderDocParts(parts, settings, doc) {
    let indexes = {
      map: -1,
      text: -1,
    };
    return parts.map((item, i) => {
      switch (item.type) {
        case 'OrganizationProfile': {
          return null;
        }
        case 'Text': {
          indexes.text++;
          const key = 'text_' + indexes.text;
          return (
            <div
              style={{
                position: 'relative',
                zIndex: 9999 - i,
              }}
              key={key}
            >
              <EditText
                label={oh.translate('text')}
                paragraphs={settings[key]}
                style_id={settings.style_id}
                onChange={(new_texts) => {
                  let updated_props = {};
                  updated_props[key] = new_texts;
                  this.applySettings(this.current_doc, updated_props);
                }}
              />
            </div>
          );
        }
        case 'Map': {
          indexes.map++;
          return (
            <div
              key={i}
              className="live_edit_row"
              style={{
                animationDelay: i * 0.15 + 's',
                position: 'relative',
                zIndex: 9999 - i,
              }}
            >
              <div className="live_edit_section half_width">
                <div className="live_edit_section_label">
                  {oh.translate('map')}
                </div>
                <div className="live_edit_section_data">
                  <Button
                    filled
                    block
                    label={oh.translate('edit_map')}
                    onClick={() => {
                      this.setState({
                        render_edit_map: 'map_' + indexes.map,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="live_edit_section half_width">
                <div className="live_edit_section_label">
                  {oh.translate('legend_position')}
                </div>
                <div className="live_edit_section_data">
                  <Dropdown
                    block
                    empty_message={oh.translate('no_types')}
                    selected={
                      settings['map_' + indexes.map].legend_position ||
                      'bottom_left'
                    }
                    options={[
                      {
                        id: 'top_left',
                        name: oh.translate('top_left'),
                      },
                      {
                        id: 'top_right',
                        name: oh.translate('top_right'),
                      },
                      {
                        id: 'bottom_left',
                        name: oh.translate('bottom_left'),
                      },
                      {
                        id: 'bottom_right',
                        name: oh.translate('bottom_right'),
                      },
                    ]}
                    selectedOption={(position) => {
                      let map_update = {};
                      map_update['map_' + indexes.map] = Object.assign(
                        {},
                        settings['map_' + indexes.map],
                        {
                          legend_position: position,
                        },
                      );
                      this.applySettings(doc, map_update);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }
        default: {
          throw new Error('Missing component for ' + item.type);
        }
      }
    });
  }

  renderFooter(doc) {
    return (
      <div className="live_edit_footer">
        <div className="live_edit_footer_left">
          {/* TODO: UNDO: This is temporarily disabled.

                    <div
                        className={"round_action_button " + (this.state.undo.offset > 0 ? "" : "disabled")}
                        onMouseEnter={() => this.setState({action_message: 'undo'})}
                        onMouseLeave={() => this.setState({action_message: ''})}
                        onClick={() => {
                            if (this.state.undo.offset > 0) {
                                this.undo();
                            }
                        }}
                    >
                        <IconUndo />
                    </div>
                    <div
                        className={"round_action_button " + (this.state.undo.offset < (this.state.undo.items.length - 1) ? "" : "disabled")}
                        onMouseEnter={() => this.setState({action_message: 'redo'})}
                        onMouseLeave={() => this.setState({action_message: ''})}
                        onClick={() => {
                            if (this.state.undo.offset < this.state.undo.items.length - 1) {
                                this.redo();
                            }
                        }}
                    >
                        <IconRedo />
                    </div>
                    <div className="action_message">
                        { this.state.action_message.length > 0 ? <p>{oh.translate(this.state.action_message)}</p> : null}
                    </div> */}
        </div>
        <div className="live_edit_footer_right">
          <Button
            filled
            dark
            onMouseEnter={() =>
              this.setState({ action_message: 'abort_edits' })
            }
            onMouseLeave={() => this.setState({ action_message: '' })}
            label={oh.translate('cancel')}
            onClick={() => this.cancelEdit(doc)}
          />
          <Button
            filled
            light
            disabled={
              this.state.undo.items.length < 2 || this.want_full_save === true
                ? true
                : false
            }
            onMouseEnter={() => this.setState({ action_message: 'push_edits' })}
            onMouseLeave={() => this.setState({ action_message: '' })}
            label={oh.translate('save')}
            onClick={() => this.confirmSettings(doc)}
          />
        </div>
      </div>
    );
  }

  render() {
    let doc = this.props.store_ext.getDoc(this.props.match.params.doc_id);
    //Display nothing if document missing or version 1 of structure.
    if (!doc || !doc.structure_version || doc.structure_version === 1) {
      return (
        <Container>
          <div className="preview_actions">
            <div
              className="preview_action"
              onClick={() => this.props.history.goBack()}
            >
              <IconBack />
            </div>
          </div>
          <span style={{ color: '#fff', fontStyle: 'italic' }}>
            {oh.translate('document_does_not_exist')}
          </span>
        </Container>
      );
    }

    let settings = Object.assign({}, doc.settings, doc.tmp_settings);

    for (let i = 0; i < 5; i++) {
      let map_key = 'map_' + i;
      if (doc.tmp_settings.hasOwnProperty(map_key)) {
        settings[map_key] = Object.assign(
          {},
          doc.settings[map_key],
          doc.tmp_settings[map_key],
        );
      }
      let text_key = 'text_' + i;
      if (doc.tmp_settings.hasOwnProperty(text_key)) {
        settings[text_key] = doc.tmp_settings[text_key].map((p) =>
          Object.assign({}, p),
        );
      }
    }
    let doc_type_definition = doc_definitions.types[settings.type_id];
    let type_options = this.props.store_ext
      .getAvailableDocTypes()
      .filter((t) => doc_definitions.validDocTypes.includes(t))
      .map((t) => {
        return {
          id: doc_definitions.types[t].id,
          name: oh.translate(t),
        };
      });
    let style_options = this.props.store.styles.all.filter((style) =>
      style.types.includes(settings.type_id),
    );

    return this.state.render_edit_map ? (
      <EditMapView
        settings={settings[this.state.render_edit_map]}
        style_id={settings.style_id}
        closeView={() => {
          this.setState({ render_edit_map: null });
          this.requestPreview(doc);
        }}
        updateSettings={(value) => {
          let updated_props = {};
          updated_props[this.state.render_edit_map] = value;
          this.applySettings(doc, updated_props);
        }}
      />
    ) : (
      <Container>
        <div
          className={`preview_panels ${
            this.state.open_panel ? 'open_' + this.state.open_panel : ''
          }`}
        >
          <Scroller className="preview_doc_render">
            <DocPreview
              thumbnail={false}
              centered
              doc={doc}
              size="large"
              name={doc.name}
              dark_bg
              css_style={this.state.css_style}
              style_id={
                doc.tmp_settings.hasOwnProperty('style_id')
                  ? doc.tmp_settings.style_id
                  : doc.settings.style_id
              }
            />
            {this.renderScaleControl(doc)}
          </Scroller>
          <div className="live_edit_panel_content">
            <div
              className="live_edit_row"
              style={{ zIndex: 9999, position: 'relative' }}
            >
              <div className="live_edit_section half_width">
                <div className="live_edit_section_label">
                  {oh.translate('doc_type')}
                </div>
                <div className="live_edit_section_data">
                  <Dropdown
                    block
                    empty_message={oh.translate('no_types')}
                    selected={settings.type_id}
                    options={type_options}
                    selectedOption={(type_id) => {
                      let my_org = window.sso.getJWT().getClaim('org');
                      if (my_org === 'allbinary') {
                        //If user is allbinary get the org we are developing for from the service_name.
                        const svc_parts = this.props.store.config.service_name.split(
                          '_',
                        );
                        my_org = svc_parts.length > 1 ? svc_parts[1] : '';
                      }
                      let style_id = undefined;
                      if (
                        this.props.store.config
                          .default_bulletin_style_by_type &&
                        this.props.store.config.default_bulletin_style_by_type[
                          type_id
                        ]
                      ) {
                        style_id = this.props.store.config
                          .default_bulletin_style_by_type[type_id];
                      } else if (all_styles[my_org]) {
                        const templates = all_styles[my_org].templates;
                        templates.some((t) => {
                          if (t.types.includes(type_id)) {
                            style_id = t.id;
                            return true;
                          }
                          return false;
                        });
                      } else {
                        throw new Error(
                          'Could not find a suitable style to apply to default doc type.',
                        );
                      }
                      this.applySettings(doc, {
                        type_id,
                        style_id,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="live_edit_section half_width">
                <div className="live_edit_section_label">
                  {oh.translate('doc_style')}
                </div>
                <div className="live_edit_section_data">
                  <Dropdown
                    block
                    empty_message={oh.translate('no_styles')}
                    selected={settings.style_id}
                    options={style_options}
                    selectedOption={(item_id) => {
                      this.applySettings(doc, { style_id: item_id });
                    }}
                  />
                </div>
              </div>
            </div>
            {this.renderDocParts(doc_type_definition.parts, settings, doc)}
            {this.renderFooter(doc)}
          </div>
        </div>
        <FAQ app_section="edit" />
      </Container>
    );
  }
}

export default withRouter(withStore(PromptHOC(EditView)));
