import './fonts_jlt.css';
import './fonts_hlt.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import withConfig from 'with-config';
import ER from 'error-reporting';
import oh from 'output-helpers';
import withStore from 'with-store';

import default_config from './default_config';
import translations from './translations';
import states from './store/states';
import store_extensions from './store/general_extensions';
import update_check from './helpers/update_check';

import RequiredDataCheck from './components/RequiredDataCheck';
import LoginCheckView from './components/views/LoginCheckView';
import StyledBase from './components/base/StyledBase';
import MainView from './components/views/MainView';
import PreviewView from './components/views/PreviewView';
import EditView from './components/views/EditView';
import RenderView from './components/views/RenderView';
import RenderTmpView from './components/views/RenderTmpView';
import Spinner from './components/base/Spinner';
import BlockingSpinner from './components/base/BlockingSpinner';

withStore.addStates(states);
withStore.addExtensions(store_extensions);

window.getState = () => {
  return withStore.getState();
};
window.extensions = withStore.extensions;
window.store = withStore;

oh.setConfig({
  lang: default_config.default_language,
});
oh.addDictionary(translations);
withConfig.addStore(withStore);
withConfig.setDefault(default_config);
withConfig.get().then((config) => {
  let error_report_config = {
    header: 'ANSLAG: ',
    slack_webhook: config.slack.webhook_uri,
    disable_slack_posting:
      !config.send_errors_to_slack_in_dev && !config.production,
  };

  ER.setConfig(error_report_config);
  update_check.updateCheck(config.update_check_timer);
});

let LoginCheckViewWithER = ER.withErrorReporting(
  LoginCheckView,
  ER.ErrorAlert,
  () => {
    let prefix = 'User not logged in.';

    if (window.sso && window.sso.isLoggedIn && window.sso.isLoggedIn()) {
      let token = window.sso.getJWT();
      let token_issued = new Date(token.getClaim('iat') * 1000);
      prefix =
        'User ' +
        token.getClaim('uid') +
        '/' +
        token.getClaim('username') +
        ' iat@' +
        token_issued.toISOString();
    }

    let status = ER.getStatus();
    let err = ER.getError();
    let label = oh.translate('sending');
    let body = [];
    if (err.display_text) {
      body.concat(err.display_text);
    }
    if (status === 'sent') {
      label = oh.translate('sent');
      body.concat(oh.translate('error_report_sent'));
    } else if (status === 'failed') {
      label = oh.translate('failed');
      body = [
        oh.translate('error_report_failed'),
        oh.translate('please_contact_us'),
      ];
    } else {
      body.concat(oh.translate('sending_error_report'));
    }

    return {
      prefix: prefix,
      custom_error_props: {
        title: oh.translate('error_has_occured'),
        label: label,
        body: body,
        action_label: err.action_label || oh.translate('go_to_start'),
        actionCB: () => {
          err.actionCB ? err.actionCB() : (window.location = '/');
        },
      },
    };
  },
);

LoginCheckViewWithER = withConfig(LoginCheckViewWithER);

function renderNormal() {
  if (window.location.pathname.startsWith('/render')) {
    return null;
  }
  return (
    <RequiredDataCheck>
      <div>
        <Route exact path="/" component={MainView} />
        <Route exact path="/folder/:folder_id" component={MainView} />
        <Route
          exact
          path="/preview/:doc_id/:origin_folder_id"
          component={PreviewView}
        />
        <Route exact path="/edit/:doc_id" component={EditView} />
        <Route exact path="/edit/:doc_id/:map" component={EditView} />
      </div>
    </RequiredDataCheck>
  );
}

ReactDOM.render(
  <Router>
    <StyledBase>
      <LoginCheckViewWithER>
        <Route exact path="/render/:doc_id" component={RenderView} />
        <Route exact path="/render_tmp/:doc_id" component={RenderTmpView} />
        {renderNormal()}
        <Spinner />
        <BlockingSpinner />
      </LoginCheckViewWithER>
    </StyledBase>
  </Router>,
  document.getElementById('root'),
);
