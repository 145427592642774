import React from 'react';
import styled from 'styled-components';
const IconTemp = require('react-icons/lib/md/pin-drop');

/*
    grid_type   : [labels, icons, colors]
    options     : [{presentation, value}]
    value       : option.value
*/

const Container = styled.div`
  text-align: left;
  p,
  .bool_block,
  .bool_input {
    vertical-align: middle;
    display: inline-block;
  }
  p {
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  .grid_block {
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.border};
    padding: 4px;
    display: grid;
    grid-template-columns: ${(props) =>
      props.gridType === 'colors' || props.gridType === 'icons'
        ? 'repeat(6, 1fr)'
        : 'repeat(4, 1fr)'};
    grid-gap: 4px 4px;
  }
`;

const GridItem = styled.div`
  box-shadow: 0 2px 3px rgba(38, 38, 38, 0.12);
  background-color: ${(props) =>
    props.gridType === 'colors' ? props.presentation : '#fff'};
  cursor: pointer;
  border-radius: 4px;
  padding: ${(props) => (props.gridType === 'icons' ? '6px 0' : '12px 0')};
  height: ${(props) =>
    props.gridType === 'colors' || props.gridType === 'icons'
      ? '32px'
      : 'unset'};
  text-align: center;
  font-size: 12px;
  &:hover {
    background-color: ${(props) =>
      props.gridType === 'colors'
        ? props.presentation
        : props.theme.colors.dark[1]};
    color: #fff;
  }
  &.active {
    background-color: ${(props) =>
      props.gridType === 'colors'
        ? props.presentation
        : props.theme.colors.dark[1]};
    color: #fff;
    font-weight: bold;
    &:hover {
      opacity: 1;
    }
  }
  span {
    display: ${(props) =>
      props.gridType === 'colors' || props.gridType === 'icons'
        ? 'none'
        : 'inline-block'};
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

class GridInput extends React.Component {
  render() {
    let req = this.props.request;

    let options = [];
    let buff_options = [];
    if (req.options) {
      buff_options = [];
      if (typeof req.options[0] === 'string') {
        req.options.forEach((option) => {
          buff_options.push({
            presentation: option,
            value: option,
          });
        });
        options = buff_options;
      } else {
        options = this.props.options;
      }
    }

    //let selected_name = options.filter(obj => obj.id === this.props.selected);
    //if (selected_name.length > 0 && selected_name[0].name) {
    //    selected_name = selected_name[0].name;
    //} else {
    //selected_name = oh.translate('incompatable_option');
    //}

    return (
      <Container gridType={req.grid_type} className="user_input bool_input">
        {req.label ? <p>{req.label}</p> : null}
        <div className="grid_block">
          {options.map((item, i) => {
            return (
              <GridItem
                presentation={item.presentation}
                gridType={req.grid_type}
                key={i}
                className={`grid_item ${
                  this.props.value === item.value ? 'active' : ''
                }`}
                onClick={() => {
                  this.props.onChange(item.value);
                }}
              >
                {req.grid_type === 'icons' ? (
                  <IconTemp />
                ) : (
                  <span>{item.presentation}</span>
                )}
              </GridItem>
            );
          })}
        </div>
      </Container>
    );
  }
}

export default GridInput;
