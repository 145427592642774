import React from 'react';
import oh from 'output-helpers';
import Input from '../Input';
import Button from '../Button';
import RouteGridSelect from '../../base/RouteGridSelect';
//import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
const IconClose = require('react-icons/lib/md/close');

const SelectedRoutes = ({ selected_routes = [], removeSelectedRoute }) => {
  if (selected_routes.length < 1) {
    return null;
  }
  return (
    <div className="setting">
      <p className="setting_label">{oh.translate('selected_routes')}</p>
      <RouteGridSelect
        options={selected_routes}
        onClick={(e) => {
          removeSelectedRoute && removeSelectedRoute(e);
        }}
      />
    </div>
  );
};

class MapSettingsPanelText extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const {
      selected_routes,
      removeSelectedRoute,
      addPassingRoute,
      other_routes,
      onClose,
      onTextChange,
      onDelete,
      text,
    } = this.props;
    //if (!this.props.selected_item) {
    //    return null;
    //}

    return (
      <div className="floating_item_settings_container">
        <div className={`floating_item_settings text`}>
          <div className="item_title">
            <p>
              {oh.translate('options_for')} {oh.translate('text')}
            </p>
            <div
              className="item_close"
              onClick={() => {
                onClose();
              }}
            >
              <IconClose />
            </div>
          </div>
          <div className="item_settings">
            <div className="setting">
              <p className="setting_label">{oh.translate('text')}</p>
              <Input
                type="textarea"
                value={text}
                onChange={(e) => {
                  onTextChange(e.target.value);
                }}
              />
            </div>
            <SelectedRoutes
              selected_routes={selected_routes}
              removeSelectedRoute={removeSelectedRoute}
            />
            <div className="setting">
              <p className="setting_label">{oh.translate('other_routes')}</p>
              <RouteGridSelect
                options={other_routes}
                onClick={(e) => {
                  addPassingRoute(e);
                }}
              />
            </div>
            <div className="setting_divider"></div>
            <div className="setting">
              <Button
                block
                filled
                red
                label={oh.translate('remove')}
                onClick={() => {
                  onDelete();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapSettingsPanelText;
