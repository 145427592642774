import withStore from 'with-store';
import Notifications from '../notifications';

import * as document_helpers from './document_helpers';

export function clearAllListeners() {
  Notifications.clearEventListeners();
}

export function registerAllListeners() {
  Notifications.addEventListener(this, 'bulletin_created', (event) => {
    let bulletin = event.data;
    //Only keep documents we can actuall show, i.e
    //documents which have type- and styledefinitions for.
    const available_doc_types = withStore.extensions.getAvailableDocTypes();
    const available_style_ids = withStore.extensions.getAvailableStyleIds();
    if (
      !available_doc_types.includes(bulletin.data.settings.type_id) ||
      !available_style_ids.includes(bulletin.data.settings.style_id)
    ) {
      return;
    }
    withStore.actions.docs.setDocument(
      document_helpers.unpackDocumentFromServer(bulletin),
    );
  });
  Notifications.addEventListener(this, 'bulletin_updated', (event) => {
    let bulletin = event.data;
    //documents which have type- and styledefinitions for.
    const available_doc_types = withStore.extensions.getAvailableDocTypes();
    const available_style_ids = withStore.extensions.getAvailableStyleIds();
    if (
      !available_doc_types.includes(bulletin.data.settings.type_id) ||
      !available_style_ids.includes(bulletin.data.settings.style_id)
    ) {
      return;
    }
    withStore.actions.docs.setDocument(
      document_helpers.unpackDocumentFromServer(bulletin),
    );
  });
  Notifications.addEventListener(this, 'bulletin_deleted', (event) => {
    let bulletin = event.data;
    //documents which have type- and styledefinitions for.
    const available_doc_types = withStore.extensions.getAvailableDocTypes();
    const available_style_ids = withStore.extensions.getAvailableStyleIds();
    if (
      !available_doc_types.includes(bulletin.data.settings.type_id) ||
      !available_style_ids.includes(bulletin.data.settings.style_id)
    ) {
      return;
    }
    withStore.actions.docs.deleteDoc(bulletin.id);
  });

  Notifications.addEventListener(this, 'folder_created', (event) => {
    let folder = event.data;
    withStore.actions.folders.setFolder(
      document_helpers.unpackFolderFromServer(folder),
    );
  });
  Notifications.addEventListener(this, 'folder_updated', (event) => {
    let folder = event.data;
    withStore.actions.folders.setFolder(
      document_helpers.unpackFolderFromServer(folder),
    );
  });
  Notifications.addEventListener(this, 'folder_deleted', (event) => {
    let folder = event.data;
    withStore.actions.folders.deleteFolder(
      document_helpers.unpackFolderFromServer(folder),
    );
  });

  Notifications.addEventListener(this, 'open_updated', (event) => {
    withStore.actions.open.updateOpen(event.data);
  });
  Notifications.addEventListener(this, 'open_deleted', (event) => {
    withStore.actions.open.deleteOpen(event.data);
  });

  Notifications.addEventListener(this, 'thumbnail_updated', (event) => {
    let bulletin_id = event.data.bulletin_id;
    let base64_thumbnail = event.data.thumbnail;

    withStore.actions.image.setThumbnail({
      id: bulletin_id,
      base64_data: base64_thumbnail,
    });
  });
}
