export const GTFS_STATE_ASSIGN_STATE = 'GTFS_STATE_ASSIGN_STATE';

let default_state = {
  fetched: false,
  selected_table_period: null,
  table_periods: [],
  stops: {},
  routes: [],
  shapes_by_route: {},
  passing_routes_by_stop: {},
  alias_by_route_short_name: {},
  route_short_names: [],
};

export default function userReducer(state = default_state, action) {
  switch (action.type) {
    case GTFS_STATE_ASSIGN_STATE: {
      return Object.assign({}, state, action.payload);
    }
    default: {
      return state;
    }
  }
}
