import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  width: ${(props) => (props.block ? '100%' : 'unset')};
  cursor: pointer;
  & .innerContainer {
    border: 1px solid #ced9ee;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
    & .button {
      border-left: 1px solid #ced9ee;
      padding: 8px;
      &:hover {
        & svg {
          fill: #5d92f1;
        }
      }
    }
    & .button.selected {
      background-color: #1463ee;
      & svg {
        fill: #ffffff;
      }
    }
    & .button:first-child {
      border-left: none;
    }
  }
`;

const TextEditButtonGroup = ({ selected, options, selectedOption }) => {
  return (
    <Container>
      <div className="innerContainer">
        {options.map((o) => (
          <div
            className={`button ${selected === o.id ? 'selected' : ''}`}
            key={o.id}
            onClick={() => selectedOption(o.id)}
          >
            <o.icon />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default TextEditButtonGroup;
