import React from 'react';
import styled from 'styled-components';

/**
interface Props {
  question: string;
  answer; string;
}
 */

const Container = styled.div`
  font-size: 16px;
  border-left: 4px solid #1563ee;
  padding: 4px 0 4px 12px;
  margin: 12px 0;
  display: inline-block;
  .question {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .answer {
    white-space: pre-line;
  }
`;

const FaqText = ({ question, answer }) => {
  return (
    <Container>
      <h6 className="question">{question}</h6>
      <p className="answer">{answer}</p>
    </Container>
  );
};

export default FaqText;
