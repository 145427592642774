import React from 'react';
import withStore from 'with-store';
import styled from 'styled-components';
import draftToHtml from 'draftjs-to-html';
class Text extends React.Component {
  constructor(props) {
    super();
    this.container = styled.div`
      ${props.store_ext.getStyles(props.style_id, 'rich text1')['RichText']}
    `;
  }

  componentDidUpdate(prev_props) {
    if (prev_props.style_id !== this.props.style_id) {
      this.container = styled.div`
        ${this.props.store_ext.getStyles(this.props.style_id, 'rich text2')[
          'RichText'
        ]}
      `;
      this.forceUpdate();
    }
  }

  render() {
    let html = draftToHtml(this.props.text);
    return (
      <this.container
        className={'type_' + this.props.text_type}
        dangerouslySetInnerHTML={{ __html: html }}
      ></this.container>
    );
  }
}

export default withStore(Text);
