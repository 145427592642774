import axios from 'axios';

let current_version = null;
let check_timer = null;

function getLatestVersion() {
  let url =
    window.location.protocol +
    '//' +
    window.location.hostname +
    ':' +
    window.location.port +
    '/version.json';
  return axios({
    url: url,
    method: 'GET',
  })
    .then((res) => {
      if (res.data.hasOwnProperty('version')) {
        return res.data.version;
      }
      return null;
    })
    .catch((err) => {
      return null;
    });
}

function checkLatestVersion() {
  getLatestVersion().then((latest_version) => {
    if (!current_version) {
      current_version = latest_version;
    } else if (latest_version && current_version !== latest_version) {
      window.location.reload(true);
    }
  });
}

function updateCheck(check_frequency) {
  checkLatestVersion();

  if (check_timer) {
    clearTimeout(check_timer);
  }

  check_timer = setTimeout(() => {
    updateCheck(check_frequency);
  }, check_frequency);
}

export default {
  updateCheck: updateCheck,
};
