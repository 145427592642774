export const SET_STYLES = 'SET_STYLES';

const default_state = {
  by_id: {},
  all: [],
  default_styles: {},
  org_default_styles: {},
};

export default (state = default_state, action) => {
  switch (action.type) {
    case SET_STYLES: {
      let by_id = {};
      action.payload.styles_arr.forEach((style) => {
        by_id[style.id] = style;
      });
      return Object.assign({}, state, {
        by_id: by_id,
        all: action.payload.styles_arr,
        default_styles: action.payload.default_styles,
        org_default_styles: action.payload.org_default_styles,
      });
    }

    default: {
      return state;
    }
  }
};
