import React from 'react';
import { withRouter } from 'react-router';
import styled, { keyframes } from 'styled-components';
import withStore from 'with-store';

const fadeIn = keyframes`
            from {
              opacity: 0;
              transform: translate3d(0, 10%, 0);
            }
            to {
              opacity: 1;
              transform: none;
            }
        `;

const sk_rotate = keyframes`
            100% {
                transform: rotate(360deg)
            }
        `;

const sk_bounce = keyframes`
            0%, 100% {
                transform: scale(0.0);
            } 50% {
                transform: scale(1.0);
            }
        `;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.fade};
  color: #fff;
  z-index: 10;
  text-align: center;
  .block {
    animation: ${fadeIn} 0.5s 0s both;
    margin: 14% 20%;
    font-size: 32px;
    text-shadow: 0 4px 2px rgba(0, 0, 0, 0.2);
  }
  .spinner {
    margin: 100px auto;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    animation: ${sk_rotate} 2s infinite linear;
  }

  .dot1,
  .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    animation: ${sk_bounce} 2s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
  }
`;

class BlockingSpinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_small_spinner: false,
      small_spinner_slide_away: false,
    };

    this.slide_away_timeout = null;
  }

  render() {
    if (this.props.store_ext.showSpinner()) {
      return (
        <Container>
          <div className="block">
            <div>
              <div className="spinner">
                <div className="dot1" />
                <div className="dot2" />
              </div>
            </div>
            <p>{this.props.text}</p>
          </div>
        </Container>
      );
    }

    return null;
  }
}

export default withRouter(withStore(BlockingSpinner));
