import * as store_helpers from './store_helpers';

export const FOLDERS_STATE_SET = 'FOLDERS_STATE_SET';
export const FOLDERS_STATE_SET_FOLDER = 'FOLDERS_STATE_SET_FOLDER';
export const FOLDERS_STATE_DELETE_FOLDER = 'FOLDERS_STATE_DELETE_FOLDER';

let default_state = {
  fetched: false,
  all: [],
  by_id: {},
  root_id: null,
  archive_id: null,
};

export default function userReducer(state = default_state, action) {
  switch (action.type) {
    case FOLDERS_STATE_SET: {
      return Object.assign({}, state, action.payload);
    }
    case FOLDERS_STATE_SET_FOLDER: {
      return store_helpers.SetItem(action.payload, state);
    }
    case FOLDERS_STATE_DELETE_FOLDER: {
      return store_helpers.DeleteItem({ id: action.payload }, state);
    }
    default: {
      return state;
    }
  }
}
