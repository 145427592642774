import React from 'react';
import { DragDropContext } from 'react-dnd';
import * as doc_helpers from '../../helpers/document_helpers';
import HTML5Backend from 'react-dnd-html5-backend';
import oh from 'output-helpers';
import styled, { keyframes } from 'styled-components';
import withStore from 'with-store';
import PromptHOC from '../base/prompt_hoc/PromptHOC';
import DocThumbnail from '../base/DocThumbnail';
import Crumble from '../base/Crumble';
import Folder, { FolderDragSource } from '../base/Folder';
import api_helpers from '../../helpers/api_helpers';

const anim_fade_in = keyframes`
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
        `;

const anim_rotate_doc = keyframes`
            from {
                transform: rotateY(0) rotateX(10deg) translateZ(-1em) scale(0.15);

            }
            to {
                transform: rotateY(-90deg) scale(0.15);
            }
        `;

const anim_rotate = keyframes`
            from {
                transform: rotateY(90deg) rotateX(10deg) translateZ(1em);
            }
            to {
                transform: rotateY(0deg);
            }
        `;

const FolderContainer = styled.div`
  cursor: pointer;
  position: relative;

  opacity: ${(props) => (props.isDragging ? '0' : 1)};

  ${(props) =>
    props.archive
      ? `
                .folder_icon{
                    display: block !important;
                }
            `
      : ''};

  &.is_over {
    .folder {
      box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.15);
      transform: skewX(-4deg);
    }
    .folder_bg_container {
      transform: skewX(4deg);
    }
    .folder_name {
      opacity: 0.9;
    }
  }

  &:hover {
    .folder {
      box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.15);
      transform: skewX(-2deg);
    }
    .folder_bg_container {
      transform: skewX(2deg);
    }
    .folder_name {
      opacity: 0.9;
    }
  }

  .folder_icon {
    display: none;
    font-size: 64px;
    margin: auto;
    text-align: center;
    height: 100%;
    padding-top: 40px;
    opacity: 0.3;
  }
  .folder_docs_count {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    padding: 3px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    z-index: 2;
    left: 10px;
    bottom: 10px;
    color: #fff;
    width: 26px;
    text-align: center;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .folder_tab {
    height: ${(props) =>
      props.theme.sizes.doc[props.size].height * 0.08 + 'px'};
    width: ${(props) => props.theme.sizes.doc[props.size].width * 0.3 + 'px'};
    background-image: linear-gradient(
      -180deg,
      ${(props) => props.theme.colors.brand[2]} 0%,
      ${(props) => props.theme.colors.brand[1]} 100%
    );
    border-radius: ${(props) =>
        props.theme.sizes.doc.small.border_radius + 'px'}
      ${(props) => props.theme.sizes.doc.small.border_radius + 'px'} 0 0;
  }
  .folder_bg_container {
    transform-origin: bottom;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: all ${(props) => props.theme.sizes.general.transition_time}s;
    .folder_bg {
      background-image: linear-gradient(
        -180deg,
        ${(props) => props.theme.colors.brand[1]} 0%,
        ${(props) => props.theme.colors.brand[0]} 100%
      );
      width: ${(props) => props.theme.sizes.folder.width + 'px'};
      height: ${(props) =>
        props.theme.sizes.doc[props.size].height * 0.92 + 'px'};
      border-radius: 0 4px 4px 4px;
      position: relative;
    }
  }
  .folder {
    display: ${(props) => (props.centered ? 'inline-block' : 'block')};
    top: ${(props) => props.theme.sizes.doc[props.size].height * 0.08 + 'px'};
    position: relative;
    background-image: linear-gradient(
      -180deg,
      ${(props) => props.theme.colors.brand[2]} 0%,
      ${(props) => props.theme.colors.brand[1]} 100%
    );
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    width: ${(props) => props.theme.sizes.folder.width + 'px'};
    height: ${(props) =>
      props.theme.sizes.doc[props.size].height * 0.92 + 'px'};
    border-radius: 0 4px 4px 4px;
    transition: all ${(props) => props.theme.sizes.general.transition_time}s;
    transform-origin: bottom;
    z-index: 2;
  }
  .folder_name {
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    color: ${(props) => (props.dark_bg ? '#fff' : props.theme.colors.text)};
    margin-top: 14px;
    font-weight: 700;
    opacity: 0.6;
    width: ${(props) => props.theme.sizes.folder.width + 'px'};
    text-align: center;
    font-size: 12px;
    p {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

const FileFolderDropdownContainer = styled.div`
  position: absolute;
  top: 0;
  vertical-align: top;
  width: ${(props) => props.theme.sizes.doc[props.size].width + 'px'};
  height: ${(props) => props.theme.sizes.doc[props.size].height + 'px'};
  z-index: 2;
  &:hover {
    .filde_dropdown_icon {
      animation: ${anim_fade_in} 0.2s both;
      display: table;
    }
  }
  &.dropdown_active {
    .doc_render {
      animation: ${anim_rotate_doc} 0.2s;
    }
  }
  .filde_dropdown_icon {
    z-index: 1;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    display: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 24px;
    color: #fff;
    position: absolute;
    right: -12px;
    top: 20px;
    transition: all 0.2s;
    background-color: ${(props) => props.theme.colors.brand[1]};
    &.active {
      display: block;
      z-index: 20;
      margin-top: 2px;
    }
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      background-color: ${(props) => props.theme.colors.brand[2]};
    }
    svg {
      margin: auto;
      display: table-cell;
      vertical-align: middle;
      width: 22px;
      height: 30px;
    }
  }
  .file_dropdown {
    transform-origin: left;
    z-index: 11;
    display: none;
    position: absolute;
    width: ${(props) => props.theme.sizes.doc[props.size].width + 'px'};
    height: ${(props) => props.theme.sizes.doc[props.size].height + 'px'};
    left: 0;
    top: 0;
    color: #fff;
    background-color: ${(props) => props.theme.colors.dark[0]};
    border-radius: 2px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    &.active {
      display: block;
      animation: ${anim_rotate} 0.25s 0.2s both;
    }
    ul {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      overflow: hidden;
      li {
        cursor: pointer;
        transition: all 0.2s;
        border-bottom: 1px solid #1a1a1a;
        &:hover {
          background-color: ${(props) => props.theme.colors.brand[2]};
          color: #fff;
          border-color: transparent;
        }
        &:first-child {
          border-top: 1px solid #1a1a1a;
        }
        &:last-child {
          border-radius: 0 0 2px 2px;
          border-bottom: none;
        }
        .create_label {
          padding: 8px 10px;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }
  .doc_dropdown_blur {
    display: none;
    &.active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
`;

class FileSystem extends React.Component {
  constructor(props) {
    super(props);
    let hover_index = [];
    this.props.folder_content.children.forEach(() => {
      hover_index.push(false);
    });

    this.state = {
      hover_index: hover_index,
    };
  }

  resetHoverIndex() {
    let hover_index = [];
    this.props.folder_content.children.forEach(() => {
      hover_index.push(false);
    });

    this.setState({
      hover_index: hover_index,
    });
  }

  triggerCloneDoc(doc) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('confirm_create_a_doc_copy'),
      title: oh.translate('are_you_sure'),
      class: 'clone',
    };
    let cloneCallback = (values) => {
      this.props.store_actions.spinner.add();
      //TODO: Add input modal for name of new document.
      doc_helpers
        .cloneDoc(doc.id, doc.name + ' (' + oh.translate('clone', false) + ')')
        .then((doc_from_server) => {
          this.props.store_actions.docs.addDocument(doc_from_server);
          this.props.store_actions.spinner.remove();
          this.props.navigate('/folder/' + doc_from_server.folder_id);
        });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      cloneCallback,
    );
  }

  triggerArchiveFolder(folder) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('archived_items_cant_be_changed'),
      title: 'Not yet implemented.',
      class: 'archive',
    };
    let archiveCallback = () => {};
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      archiveCallback,
    );
  }

  triggerDeleteDoc(doc) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('confirming_will_delete'),
      title: oh.translate('are_you_sure'),
      class: 'delete',
    };
    let deleteCallback = (values) => {
      this.props.store_actions.spinner.add();
      doc_helpers.deleteDocument(doc.id, doc.last_modified).then(() => {
        this.props.store_actions.docs.deleteDoc(doc.id);
        this.setState({
          selected_doc: null,
        });
        this.props.store_actions.spinner.remove();
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      deleteCallback,
    );
  }

  triggerArchiveDoc(doc) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('archived_items_cant_be_changed'),
      title: oh.translate('are_you_sure'),
      class: 'archive',
    };
    let archiveCallback = (values) => {
      this.props.store_actions.spinner.add();
      doc_helpers.archiveDocument(doc.id).then((archive_doc) => {
        this.props.store_actions.docs.setDocument(archive_doc);
        this.props.store_actions.spinner.remove();
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      archiveCallback,
    );
  }

  triggerMoveFolder(folder, new_parent_id) {
    this.props.store_actions.spinner.add();
    let altered_folder = Object.assign(
      {},
      this.props.store.folders.by_id[folder.id],
    );
    altered_folder.parent_id = new_parent_id;
    api_helpers
      .saveFolder(altered_folder)
      .then((updated_folder) => {
        this.props.store_actions.folders.setFolder(updated_folder);
      })
      .finally(() => {
        this.props.store_actions.spinner.remove();
      });
  }

  triggerEditMetaFolder(folder) {
    let inputs = [
      {
        type: 'text',
        label: oh.translate('name'),
        default_value: folder.name,
      },
    ];
    let prompt_props = {
      message: oh.translate('edit_meta_information'),
      title: oh.translate('edit'),
    };
    let editMetaCallback = (values) => {
      if (!values[0]) {
        return;
      }
      let name = values[0].trim();
      if (name.length < 1) {
        return;
      }

      let altered_folder = Object.assign(
        {},
        this.props.store.folders.by_id[folder.id],
      );
      altered_folder.name = name;

      this.props.store_actions.spinner.addSmall();
      api_helpers
        .saveFolder(altered_folder)
        .then((updated_folder) => {
          this.props.store_actions.folders.setFolder(updated_folder);
        })
        .finally(() => {
          this.props.store_actions.spinner.removeSmall();
        });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      editMetaCallback,
    );
  }

  triggerEditMetaDoc(doc) {
    let inputs = [
      {
        type: 'text',
        label: oh.translate('name'),
        default_value: doc.name,
      },
    ];
    let prompt_props = {
      message: oh.translate('edit_meta_information'),
      title: oh.translate('edit'),
    };
    let editMetaCallback = (values) => {
      if (!values[0]) {
        return;
      }
      let name = values[0].trim();
      if (name.length < 1) {
        return;
      }

      let altered_doc = Object.assign({}, this.props.store.docs.by_id[doc.id]);
      altered_doc.name = name;

      this.props.store_actions.spinner.addSmall();
      api_helpers
        .saveDocument(altered_doc)
        .then((updated_doc) => {
          this.props.store_actions.docs.setDocument(updated_doc);
        })
        .finally(() => {
          this.props.store_actions.spinner.removeSmall();
        });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      editMetaCallback,
    );
  }

  triggerDeleteFolder(folder) {
    let inputs = [];
    let prompt_props = {
      message: oh.translate('confirming_will_delete'),
      title: oh.translate('are_you_sure'),
      class: 'delete',
    };
    let deleteCallback = (values) => {
      this.props.store_actions.spinner.add();
      let current_folder_id = this.props.current_folder_id;
      doc_helpers.deleteFolder(folder.id, folder.last_modified).then(() => {
        this.props.store_actions.folders.deleteFolder(folder.id);
        if (current_folder_id === folder.id.toString()) {
          //If currently in the folder being deleted move to parent.
          let current_folder = this.props.store.folders.by_id[
            current_folder_id
          ];
          this.props.navigate('/folders/' + current_folder.parent_id);
        }
        this.props.store_actions.spinner.remove();
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      deleteCallback,
    );
  }

  generateCrumbs(folder_content) {
    return (
      <div className="main_directory_path">
        <Crumble
          moveDocToThisFolder={(doc) => {
            if (doc.archived) {
              // FIXME: explain to user why this didn't work? not allowed to drag things out of archive
              return;
            }

            this.props.store_actions.spinner.add();
            let altered_doc = Object.assign(
              {},
              this.props.store.docs.by_id[doc.id],
            );
            altered_doc.folder_id = this.props.store.folders.root_id;

            api_helpers
              .saveDocument(altered_doc)
              .then((updated_doc) => {
                this.props.store_actions.docs.setDocument(updated_doc);
              })
              .finally(() => {
                this.props.store_actions.spinner.remove();
              });
          }}
          moveFolderToThisFolder={(folder, parent_id) => {
            if (folder.is_archive || folder.is_root) {
              return;
            }

            this.props.store_actions.spinner.add();
            let altered_folder = Object.assign(
              {},
              this.props.store.folders.by_id[folder.id],
            );
            altered_folder.parent_id = parent_id;

            api_helpers
              .saveFolder(altered_folder)
              .then((updated_folder) => {
                this.props.store_actions.folders.setFolder(updated_folder);
              })
              .finally(() => {
                this.props.store_actions.spinner.remove();
              });
          }}
          folder={
            this.props.store.folders.by_id[this.props.store.folders.root_id]
          }
          className="root"
          onClick={() => {
            this.props.navigate('/');
          }}
          label={
            this.props.first_crumb_label
              ? this.props.first_crumb_label
              : oh.translate('start') + ' /'
          }
        />

        {!folder_content.is_root
          ? this.getFolderParents(folder_content.parent_id)
          : null}
        {!folder_content.is_root ? (
          <Crumble
            className="current"
            folder={folder_content}
            moveDocToThisFolder={() => {
              // let inputs = [];
              // let prompt_props = {
              //     message: oh.translate('file_already_in_folder'),
              //     title: oh.translate('not_possible'),
              //     class: "delete",
              //     remove_cancel: true
              // };
              // this.props.UserPrompt.prompt({
              //     inputs: inputs,
              //     props: prompt_props
              // });
            }}
            moveFolderToThisFolder={(folder, parent_id) => {}}
            onClick={() => {
              console.log('no possiblo');
            }}
            label={folder_content.name}
          />
        ) : null}
      </div>
    );
  }

  getFolderParents(parent_id) {
    // Todo: verify it works when folders can be inside of folders
    let crumbs = [];
    let cur = this.props.store.folders.by_id[parent_id];
    while (!cur.is_root) {
      crumbs.unshift(cur);
      cur = this.props.store.folders.by_id[cur.parent_id];
    }

    /*
        let crumbs = [];
        let looking_for_parents = true;
        let next_parent_id = parent_id;
        while (looking_for_parents) {
            //            let parent_object = this.props.store_ext.getFoldersAndDocs().by_id[next_parent_id];
            let parent_object = this.props.store_ext.getFolderContents(next_parent_id);
            if (parent_object.is_root) {
                looking_for_parents = false;
                break;
            }
            crumbs.push(parent_object);
            next_parent_id = parent_object.parent_id;
        }
        crumbs.reverse();
        */

    return crumbs.map((crumb, i) => {
      return (
        <Crumble
          moveDocToThisFolder={(doc) => {
            if (doc.archived) {
              // FIXME: explain to user why this didn't work? not allowed to drag things out of archive
              return;
            }

            this.props.store_actions.spinner.add();
            let altered_doc = Object.assign(
              {},
              this.props.store.docs.by_id[doc.id],
            );
            altered_doc.folder_id = crumb.id;

            api_helpers
              .saveDocument(altered_doc)
              .then((updated_doc) => {
                this.props.store_actions.docs.setDocument(updated_doc);
              })
              .finally(() => {
                this.props.store_actions.spinner.remove();
              });
          }}
          moveFolderToThisFolder={(folder, parent_id) => {
            if (folder.is_archive || folder.is_root) {
              return;
            }

            this.props.store_actions.spinner.add();
            let altered_folder = Object.assign(
              {},
              this.props.store.folders.by_id[folder.id],
            );
            altered_folder.parent_id = parent_id;

            api_helpers
              .saveFolder(altered_folder)
              .then((updated_folder) => {
                this.props.store_actions.folders.setFolder(updated_folder);
              })
              .finally(() => {
                this.props.store_actions.spinner.remove();
              });
          }}
          folder={crumb}
          className=""
          key={i}
          onClick={() => {
            this.props.navigate('/folder/' + crumb.id);
          }}
          label={crumb.name + ' /'}
        />
      );
    });
  }

  renderFolderDrag(folder, i) {
    return (
      <FolderDragSource
        containerDiv={FolderContainer}
        fileFolderDropDownDiv={FileFolderDropdownContainer}
        folder={folder}
        onMouseEnter={() => {}}
        onMouseLeave={() => {
          this.resetHoverIndex();
        }}
        archive={folder.is_archive ? true : false}
        centered
        key={i}
        size="small"
        docs_count={
          this.props.store.folders.all.filter((x) => x.parent_id === folder.id)
            .length +
          this.props.store.docs.all.filter((x) => x.folder_id === folder.id)
            .length
        }
        editMetaCB={() => {
          this.triggerEditMetaFolder(folder);
        }}
        deleteFolderCB={() => {
          this.triggerDeleteFolder(folder);
        }}
        archiveFolderCB={() => {
          // FIXME: no archiving of folders in v1
          // this.triggerArchiveFolder(folder);
        }}
        onClick={() => {
          this.props.navigate('/folder/' + folder.id);
        }}
        archiveDocCB={(doc) => {
          // FIXME: why is there an archive doc callback on a folder? doesn't seem to matter if this code is commented out
          // this.triggerArchiveDoc(doc);
        }}
        moveDocToThisFolder={(doc) => {
          this.props.store_actions.spinner.add();
          let altered_doc = Object.assign(
            {},
            this.props.store.docs.by_id[doc.id],
          );
          altered_doc.folder_id = folder.id;

          api_helpers
            .saveDocument(altered_doc)
            .then((updated_doc) => {
              this.props.store_actions.docs.setDocument(updated_doc);
            })
            .finally(() => {
              this.props.store_actions.spinner.remove();
            });
        }}
        moveFolderToThisFolder={(folder, new_parent_id) => {
          let parent = this.props.store.folders.by_id[new_parent_id];
          if (parent.is_archive) {
            return; // FIXME: explain why? no moving folders into archive
          }
          this.triggerMoveFolder(folder, new_parent_id); // temp function
        }}
      />
    );
  }

  renderFolderDrop(folder, i) {
    return (
      <Folder
        containerDiv={FolderContainer}
        fileFolderDropDownDiv={FileFolderDropdownContainer}
        folder={folder}
        onMouseEnter={() => {
          let hover_index = this.state.hover_index;
          hover_index[i] = true;
          this.setState({
            hover_index: hover_index,
          });
        }}
        onMouseLeave={() => {
          this.resetHoverIndex();
        }}
        archive={folder.is_archive ? true : false}
        centered
        key={i}
        size="small"
        docs_count={
          this.props.store.folders.all.filter((x) => x.parent_id === folder.id)
            .length +
          this.props.store.docs.all.filter((x) => x.folder_id === folder.id)
            .length
        }
        editMetaCB={() => {
          this.triggerEditMetaFolder(folder);
        }}
        deleteFolderCB={() => {
          this.triggerDeleteFolder(folder);
        }}
        archiveFolderCB={() => {
          // FIXME: no archiving of folders in v1
          // this.triggerArchiveFolder(folder);
        }}
        onClick={() => {
          this.props.navigate('/folder/' + folder.id);
        }}
        archiveDocCB={(doc) => {
          // FIXME: why is there an archive doc callback on a folder? doesn't seem to matter if this code is commented out
          // this.triggerArchiveDoc(doc);
        }}
        moveDocToThisFolder={(doc) => {
          this.props.store_actions.spinner.add();
          let altered_doc = Object.assign(
            {},
            this.props.store.docs.by_id[doc.id],
          );
          altered_doc.folder_id = folder.id;

          api_helpers
            .saveDocument(altered_doc)
            .then((updated_doc) => {
              this.props.store_actions.docs.setDocument(updated_doc);
            })
            .finally(() => {
              this.props.store_actions.spinner.remove();
            });
        }}
        moveFolderToThisFolder={(folder, new_parent_id) => {
          let parent = this.props.store.folders.by_id[new_parent_id];
          if (parent.is_archive) {
            return; // FIXME: explain why? no moving folders into archive
          }
          this.triggerMoveFolder(folder, new_parent_id); // temp function
        }}
      />
    );
  }
  render() {
    return (
      <div>
        {this.generateCrumbs(this.props.folder_content)}
        <div className="main_file_system">
          {this.props.folder_content.children.map((folder, i) => {
            if (this.state.hover_index[i] === true) {
              return this.renderFolderDrag(folder, i);
            }
            return this.renderFolderDrop(folder, i);
          })}
          {this.props.folder_content.docs.length > 0 ? (
            this.props.folder_content.docs.map((doc, i) => {
              return (
                <DocThumbnail
                  fileFolderDropDownDiv={FileFolderDropdownContainer}
                  thumbnail={true}
                  preview={true}
                  name={doc.name}
                  static={this.props.static}
                  doc={doc}
                  key={i}
                  size="small"
                  editMetaCB={() => {
                    this.triggerEditMetaDoc(doc);
                  }}
                  deleteDocCB={() => {
                    this.triggerDeleteDoc(doc);
                  }}
                  archiveDocCB={() => {
                    this.triggerArchiveDoc(doc);
                  }}
                  cloneDocCB={() => {
                    this.triggerCloneDoc(doc);
                  }}
                  onClick={() => {
                    this.props.navigate(
                      '/preview/' + doc.id + '/' + doc.folder_id,
                    );
                  }}
                />
              );
            })
          ) : this.props.folder_content.docs.length < 1 &&
            this.props.folder_content.children.length < 1 ? (
            <div className="main_symbol">
              <div className="image empty_folder" />
              <div className="label">
                <p className="title">{oh.translate('empty_folder')}</p>
                <p className="subtitle">
                  {oh.translate('empty_folder_create')}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default withStore(DragDropContext(HTML5Backend)(PromptHOC(FileSystem)));
