import withStore from 'with-store';
import api_helpers from '../helpers/api_helpers';
import simplify from '../components/base/map_module/simplify';
import moment from 'moment';

function createShapeFromStopLinkSequence(
  shapes_by_stop_link_id,
  stop_link_sequence,
) {
  let points = stop_link_sequence
    .map((x) => {
      let pts = shapes_by_stop_link_id[x];
      return pts.map((y) => [y.shape_pt_lat, y.shape_pt_lon]);
    })
    .reduce((arr, curr) => {
      return arr.concat(curr);
    }, []);

  let ret = simplify(points, 0.00005, true);
  return ret;
}

export function reload(table_period_id = null) {
  return api_helpers.fetchTablePeriods().then((table_periods) => {
    const current_date = moment();
    table_periods = table_periods.filter((t) => {
      const start_diff = current_date.diff(t.date_start, 'year');
      return (
        current_date.diff(t.date_end, 'year') === 0 &&
        (start_diff === 0 || start_diff === 1)
      );
    });

    let now = new Date().toISOString();
    let selected_table_period = null;

    if (table_period_id === null) {
      selected_table_period = table_periods.find(
        (x) => now >= x.date_start && now < x.date_end,
      );
      table_period_id = selected_table_period.id;
    } else {
      selected_table_period = table_periods.find(
        (x) => x.id === table_period_id,
      );
    }

    return Promise.all([
      selected_table_period,
      table_periods,
      api_helpers.fetchDistinctStops(),
      api_helpers.fetchRoutes(table_period_id),
      api_helpers.fetchStopLinks(table_period_id),
      api_helpers.fetchStopLinkShapes(table_period_id),
      api_helpers.fetchAllRouteDirectionStops(table_period_id),
    ])
      .then(
        ([
          selected_table_period,
          table_periods,
          stops,
          routes,
          stop_links,
          stop_link_shapes,
          route_direction_stops,
        ]) => {
          // console.log("[gtfs] got everything else");
          const valid_period_ids = table_periods.map((t) => t.id);
          stops = stops.filter((s) =>
            valid_period_ids.includes(s.max_table_period_id),
          );

          const stops_by_id = stops.reduce((acc, curr) => {
            acc[curr.stop_id] = curr;
            return acc;
          }, {});

          let stop_links_by_route = stop_links.reduce((arr, curr) => {
            if (curr.routes) {
              curr.routes.split(',').forEach((route_id) => {
                if (arr.hasOwnProperty(route_id) === false) {
                  arr[route_id] = [];
                }
                arr[route_id].push(curr.stop_link_id);
              });
            }
            return arr;
          }, {});

          let alias_by_route_short_name = routes.reduce((arr, curr) => {
            if (curr.route_alias) {
              arr[curr.route_short_name] = curr.route_alias;
            }
            return arr;
          }, {});
          let route_short_names = routes.reduce((arr, curr) => {
            if (arr.includes(curr.route_short_name) === false) {
              arr.push(curr.route_short_name);
            }
            return arr;
          }, []);

          let shapes_by_stop_link_id = stop_link_shapes.reduce((arr, curr) => {
            if (arr.hasOwnProperty(curr.shape_id) === false) {
              arr[curr.shape_id] = [];
            }
            arr[curr.shape_id].push(curr);
            return arr;
          }, {});

          let passing_routes_by_stop = route_direction_stops.reduce(
            (arr, curr) => {
              if (arr.hasOwnProperty(curr.stop_id) === false) {
                arr[curr.stop_id] = new Set();
              }
              arr[curr.stop_id].add(curr.route_short_name);
              return arr;
            },
            {},
          );
          Object.keys(passing_routes_by_stop).forEach((key) => {
            passing_routes_by_stop[key] = Array.from(
              passing_routes_by_stop[key],
            );
          });

          let shapes_by_route = routes.reduce((arr, curr) => {
            let rt_dir_stops = route_direction_stops
              .filter(
                (x) =>
                  x.route_short_name === curr.route_short_name &&
                  x.direction_id === curr.direction_id,
              )
              .sort((a, b) => {
                return a.stop_sequence - b.stop_sequence;
              });

            let available_stop_links = stop_links_by_route[curr.route_id];

            let stop_link_sequence = [];
            for (let i = 0; i < rt_dir_stops.length - 1; i++) {
              let from = rt_dir_stops[i].stop_id;
              let to = rt_dir_stops[i + 1].stop_id;
              let stop_link_id = from + ':' + to;

              if (available_stop_links.includes(stop_link_id)) {
                stop_link_sequence.push(stop_link_id);
              }
            }

            arr[curr.route_id] = createShapeFromStopLinkSequence(
              shapes_by_stop_link_id,
              stop_link_sequence,
            );
            return arr;
          }, {});

          return {
            fetched: true,
            selected_table_period: selected_table_period,
            table_periods: table_periods,
            stops: stops_by_id,
            routes: routes,
            shapes_by_route: shapes_by_route,
            passing_routes_by_stop: passing_routes_by_stop,
            alias_by_route_short_name: alias_by_route_short_name,
            route_short_names: route_short_names,
          };
        },
      )
      .then((new_state) => {
        withStore.actions.gtfs.set(new_state);
        return true;
      });
  });
}

window.gtfs_reload = reload;
