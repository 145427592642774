import { a4_portrait } from './standard';
import { hlt, vl, jlt, samtrafiken } from './organizations';

export default {
  default: {
    MapLegend: `
    padding: 6px 8px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    border-radius: 8px;
    position: absolute;
    z-index: 1;
    &.bottom_left {
      bottom: 20px;
      left: 12px;
    }
    &.bottom_right {
      bottom: 20px;
      right: 12px;
    }
    &.top_left {
      top: 20px;
      left: 12px;
    }
    &.top_right {
      top: 20px;
      right: 12px;
    }
    ul {
      li {
        cursor: pointer;
        margin-bottom: 2px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        &.edit {
          margin: 4px 0;
        }
        .legend_item_icon,
        .legend_item_label {
          display: inline-block;
          vertical-align: middle;
        }
        .legend_item_icon {
        }
        .legend_item_label {
          padding-left: 10px;
          font-weight: bold;
          flex: 1;
        }
      }
    }
    .placeholder {
      width: 32px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    `,
    document_specification: `
      background-color: #fff;
      width: ${a4_portrait.width}px;
      height: ${a4_portrait.height}px;
      text-align: left;
    `,
    OrganizationProfile: `
      position: relative;
      .layer_1 {
          z-index: 1;
          display: none;
      }
      .layer_2 {
          z-index: 1;
          display: none;
      }
      .layer_3 {
          z-index: 1;
          display: none;
      }
    `,
    Map: `
      background-color: #c5dab5;
      height: 500px;
      position: relative;
      &.fullscreen_true{
          height: 500px;
      }
      div.inner {
          height: 500px;
          width: 100%;
      }
    `,
    RichText: `
      color: #000;
      margin: auto;
      padding: 4px;
      z-index: 100;
      &.type_title {
        z-index: 99999999;
        position: relative;
        font-size: 64px;
        display: inline-block;
        margin-bottom: 20px;
        width: 100%;
        margin: 0;
        padding: 28px;
      }
      &.type_body {
        font-size: 42px;
        margin: 16px;
        h1{
            font-size: 52px;
        }
        h2{
            font-size: 84px;
        }
        h3{
            font-size: 74px;
        }
        h4{
            font-size: 64px;
        }
        span{
            font-size: inherit;
        }
      }
    `,
  },
  hlt,
  vl,
  jlt,
  samtrafiken,
};
