import * as docs_actions from './docs_actions';
import docs_reducer from './docs_reducer';
import * as folders_actions from './folders_actions';
import folders_reducer from './folders_reducer';
import * as styles_actions from './styles_actions';
import styles_reducer from './styles_reducer';
import * as spinner_actions from './spinner_actions';
import spinner_reducer from './spinner_reducer';
import * as image_actions from './image_actions';
import image_reducer from './image_reducer';
import * as users_actions from './users_actions';
import users_reducer from './users_reducer';
import * as gtfs_actions from './gtfs_actions';
import gtfs_reducer from './gtfs_reducer';
import * as open_actions from './open_actions';
import open_reducer from './open_reducer';

export default {
  docs: { actions: { ...docs_actions }, reducer: docs_reducer },
  styles: { actions: { ...styles_actions }, reducer: styles_reducer },
  folders: { actions: { ...folders_actions }, reducer: folders_reducer },
  spinner: { actions: { ...spinner_actions }, reducer: spinner_reducer },
  image: { actions: { ...image_actions }, reducer: image_reducer },
  users: { actions: { ...users_actions }, reducer: users_reducer },
  open: { actions: { ...open_actions }, reducer: open_reducer },
  gtfs: { actions: { ...gtfs_actions }, reducer: gtfs_reducer },
};
