/**
interface FAQImg {
  type: "image";
  url: string;
}
interface FAQText {
  type: "text";
  question: string;
  answer; string;
}
type FAQData = {
  title: string;
  subtitle: string;
  content: (FAQImage | FAQText)[];
 */

export default {
  title: 'Vanliga frågor',
  subtitle: 'Karta',
  content: [
    {
      type: 'text',
      question: 'Hur redigerar jag jag kartan?',
      answer:
        'Tryck på knappen “Redigera kartan” i redigerarvyn så kommer man till kartredigering (helfönster). ',
    },
    {
      type: 'text',
      question: 'Hur söker jag upp en busslinje?',
      answer:
        'Tryck på knappen “Linje” uppe till höger, sök upp busslinje i sökfältet uppe till vänster. Klicka på önskad linje i sökresultatet i dropdown. Om du vill centrera kartan till linjens position tryck ctrl+click (option+click för Mac) på önskad linje i dropdown. \nObservera: det kan finnas flera olika varianter av samma busslinje, beroende på hur linjen är angiven i Rebus. ',
    },
    {
      type: 'text',
      question: 'Hur ritar jag en egen linje?',
      answer:
        'Tryck på knappen “Linje” upp till höger. Rita en linje med hjälp av musen. Vi varje vänsterklick med musen så kan man ändra riktning på linjen. Avsluta med dubbelklick alternativt “Färdigställ” i menyn till höger.  ',
    },
    {
      type: 'text',
      question: 'Hur ändrar jag utseende/färg på en linje?',
      answer:
        'Klicka på linjen - när redigeringsmenyn “Val för linje” syns till höger går det att ändra utseende på linjen. I “Linjestil” går att välja “Heldragen”, “Streckad” etc. i “Linjebredd” kan man ställa tjockleken på linjen, och med hjälp av “Färg”-knapparna kan man ändra färg på linjen.',
    },
    {
      type: 'text',
      question: 'Hur ändrar jag linjedragningen på en befintlig linje?',
      answer:
        'Klicka på linjen. Genom att dra i de vita cirklarna som syns på linjen kan man ändra linjens dragning. Genom att dra i de gråare cirklarna på linjen kan man skapa fler cirklar på en linje (obegränsat många) - dvs man kan göra mjukare svängar etc. ',
    },
    {
      type: 'text',
      question: 'Hur klipper jag i en linje?',
      answer:
        'Man kan klippa ut en bit av en linje genom att klicka på linjen. Där de vita cirklarna finns på linjen är det möjligt att klippa. (Man kan skapa fler/tätare vita cirklar på linjen genom att dra i de grå cirklarna). Välj “Klipp linje”. Placera ut saxen på önskat ställe där du vill klippa linjen (välj 2 ställen för att klippa ut en bit, välj endast ett ställe för att dela linjen i 2 delar), tryck på “Färdigställ”. Nu är linjen klippt och kan du klicka på den utklippta linjebiten, ändra utseende, flytta på den eller radera den.',
    },
    {
      type: 'text',
      question: 'Hur visar jag förklaringar för linje i legenden?',
      answer:
        'För samtliga ikoner och linjer kan man välja om de skall synas i legenden eller ej. Det gör jag genom att i menyn “Val för linje” bocka för “Visas i legenden”. Texten som skall synas i legenden går att ändra här.',
    },
    {
      type: 'text',
      question: 'Hur letar jag upp en hållplats?',
      answer:
        'Genom att klicka på knappen “Hållplats” uppe till höger lyser regionens samtliga hållplatser upp i kartan. Välj önskad hållplats genom att klicka på på kartan alternativt  sök upp hållplats med namn i sökfönstret uppe till vänster. Välj önskad hållplats i Dropdown. Om du vill centrera kartan till hållplatsens position tryck ctrl+click (option+click för Mac) på önskad hållplats i dropdown. När menyn “Val för Kartmarkör” syns till höger kan man ändra utseendet på hållplatsmarkören: “Typ av hållplats” kan vara “Avstängd hållplats”, “Tillfällig hållplats” etc. ',
    },
    {
      type: 'text',
      question:
        'Hur visar jag vilka passerande linjer  som drabbas om en hållplats är avstängd?',
      answer:
        'Välj “Hållplats” i menyn uppe till höger, klicka på en position på kartan alternativt sök upp hållplatsen i sökrutan uppe till vänster. När menyn “Val för kartmarkör” syns till höger, syns även “Passerande linjer” för aktuell hållplats : detta är de linjer som trafikerar hållplatsen enligt senaste rebusexporten. Om man  klickar på önskad linje kommer dessa synas i en ruta under hållplatsen och de syns under “Valda linjer”. Alla övriga linjeikoner finns också med under “Andra linjer”, dessa går också att lägga under “Valda linjer”. Vill man ta bort en linjeikon från hållplatsen klickar man på den under “Valda linjer”',
    },
    {
      type: 'text',
      question: 'Hur ändrar jag i textrutan för en hållplats?',
      answer:
        'All text som står under en hållplats kan ändras under “Text”: ändra namn och ta bort helt.',
    },
    {
      type: 'text',
      question:
        'Hur skapar jag andra markörer som inte skall placeras på en hållplats (tillfälliga hållplatser, andra ikoner)?',
      answer:
        'Tryck på “Symbol” uppe till höger. Klicka på önskad plats på kartan. När menyn “Val för Kartmarkör” kommer upp kan du välja typ under “Typ av Markör”, skriva in text, välja linjesymboler samt ange om markören skall synas i legenden eller ej.’',
    },
    {
      type: 'text',
      question: 'Hur visar jag förklaringar för symboler i legenden?',
      answer:
        'För samtliga ikoner och linjer kan man välja om de skall synas i legenden eller ej. Det gör jag genom att i menyn “Val för kartmarkör” bocka för “Visas i legenden”. Texten som skall synas i legenden går att ändra här.',
    },
    {
      type: 'text',
      question: 'Hur tar jag bort en förklaring i legenden?',
      answer:
        'Om du klickar på en rad i legenden kan du radera den från legenden. Du kan även klicka bort bocken “Visas i Legenden” i menyn “Val för Kartmarkör”. \n' +
        'Det går att lägga tillbaka förklaringen. På detta sätt kan man ändra textordningen i legenden. ',
    },
    {
      type: 'text',
      question: 'Hur flyttar jag legenden i kartan?',
      answer:
        'Gå ur kartan genom att trycka på röda krysset nere till höger (ändringarna sparas automatiskt, men man måste tryck på blåa “spara” rutan nere till höger när man lämnar redigeringsläget. Välj “Legendens position”och välj placering. ',
    },
    {
      type: 'text',
      question: 'Hur skapar jag en textruta?',
      answer:
        'Välj “Text” i menyn uppe till höger, placera ut textrutan på valfri plats i kartan. Skriv in text. Den blå pil markeringen (ikonen) syns inte när redigeringsläget för kartan är stängt.',
    },
    {
      type: 'text',
      question: 'Hur sparar jag kartan?',
      answer:
        'Gå ur kartan genom att trycka på röda krysset nere till höger (ändringarna sparas automatiskt, men man måste tryck på blåa “spara” rutan nere till höger när man lämnar redigeringsläget.',
    },
  ],
};
