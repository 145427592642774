import oh from 'output-helpers';
import * as doc_helpers from '../helpers/document_helpers';
import withStore from 'with-store';
import fusejs from 'fuse.js';

let fuse = null;
// //This ref is used to check
// let docs_ref = null;

export default {
  getUser: {
    states: ['users'],
    mnemonic: 'argument_check',
    fn: (states, user_id = null) => {
      let users = states.users;
      user_id = user_id || users.my_id;
      if (users.by_id.hasOwnProperty(user_id) === false) {
        throw new Error('Tried to access user who is not in state.');
      }
      return users.by_id[user_id];
    },
  },
  getUserCreatedDocuments: {
    states: ['docs', 'users', 'image'],
    mnemonic: 'argument_check',
    fn: (states, user_id = null) => {
      let docs = states.docs;
      let docs_by_user = [];
      user_id = user_id || states.users.my_id;
      docs.all.forEach((doc) => {
        if (doc.creator_id === user_id) {
          docs_by_user.push(
            doc_helpers.resolve(doc, states.users, states.image),
          );
        }
      });
      return docs_by_user;
    },
  },
  getDoc: {
    states: ['docs', 'users', 'image'],
    mnemonic: 'argument_check',
    fn: (states, doc_id = null) => {
      let docs = states.docs;
      doc_id = doc_id || docs.current_doc_id;
      if (docs.by_id.hasOwnProperty(doc_id) === false) {
        console.warn("Tried to getDoc() document that doesn't exist in state.");
        return null;
      }
      return doc_helpers.resolve(
        docs.by_id[doc_id],
        states.users,
        states.image,
      );
    },
  },
  getRenderDoc: {
    states: ['docs'],
    mnemonic: 'argument_check',
    fn: (states, doc_id = null) => {
      let docs = states.docs;
      doc_id = doc_id || docs.current_doc_id;
      if (docs.by_id.hasOwnProperty(doc_id) === false) {
        console.warn(
          "Tried to getRenderDoc() document that doesn't exist in state.",
        );
        return null;
      }
      return docs.by_id[doc_id];
    },
  },
  getStyle: {
    states: ['styles'],
    mnemonic: 'argument_check',
    fn: (states, style_id) => {
      return states.styles.by_id[style_id];
    },
  },
  getStyles: {
    states: ['styles'],
    mnemonic: 'argument_check',
    fn: ({ styles }, style_id, src) => {
      if (!styles.by_id.hasOwnProperty(style_id)) {
        throw new Error("Missing styles for '" + style_id + "'.");
      }
      let style = styles.by_id[style_id].styles;
      let combined_styles = {};
      [
        'document_specification',
        'OrganizationProfile',
        'Map',
        'RichText',
        'Text',
      ].forEach((part_id) => {
        let base = styles.default_styles[part_id] || '';
        let org_base = styles.org_default_styles[part_id] || '';
        let specific = '';
        if (style) {
          specific = style[part_id] || '';
        }

        combined_styles[part_id] = base + ' ' + org_base + ' ' + specific;
      });
      return combined_styles;
    },
  },
  getMapLegendStyles: {
    states: ['styles'],
    mnemonic: 'argument_check',
    fn: ({ styles }, style_id, src) => {
      if (!styles.by_id.hasOwnProperty(style_id)) {
        throw new Error("Missing styles for '" + style_id + "'.");
      }
      let style = styles.by_id[style_id].styles;
      let legend_default = styles.default_styles.MapLegend || '';
      let specific = '';
      if (style) {
        specific = style.MapLegend || '';
      }

      return {
        combined: legend_default + ' ' + specific,
        default: legend_default,
      };
    },
  },
  getTextStyles: {
    states: ['styles'],
    mnemonic: 'always',
    fn: ({ styles }, style_id) => {
      if (!styles.org_default_styles.Text) {
        throw new Error('Missing Text style definition.');
      }

      let style = styles.by_id[style_id].styles;
      let org_base = styles.org_default_styles['Text'] || '';
      let specific = '';
      if (style) {
        specific = style.Text || '';
      }
      return org_base + ' ' + specific;
    },
  },
  getAvailableDocTypes: {
    states: ['styles'],
    mnemonic: 'always',
    fn: ({ styles }) => {
      return [...new Set(styles.all.flatMap((x) => x.types))];
    },
  },
  getAvailableStyleIds: {
    states: ['styles'],
    mnemonic: 'always',
    fn: ({ styles }) => {
      return Object.keys(styles.by_id);
    },
  },
  showSpinner: {
    states: ['spinner'],
    mnemonic: 'never',
    fn: (states) => {
      return states.spinner.count > 0;
    },
  },
  showSmallSpinner: {
    states: ['spinner'],
    mnemonic: 'never',
    fn: (states) => {
      return states.spinner.small_count > 0;
    },
  },
  showPreviewSpinner: {
    states: ['spinner'],
    mnemonic: 'never',
    fn: (states) => {
      return states.spinner.preview_count > 0;
    },
  },
  getFolderContents: {
    states: ['docs', 'folders', 'users', 'image'],
    mnemonic: 'argument_check',
    fn: (states, folder_id) => {
      let docs = states.docs;
      let folders = states.folders;
      let folder = folders.by_id[folder_id];

      let child_folders = folders.all
        .filter((x) => x.parent_id === folder_id)
        .sort((a, b) => {
          if (a.is_archive) {
            return -1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 1;
        });

      let child_docs = docs.all
        .filter((x) => x.folder_id === folder_id)
        .map((x) => {
          return doc_helpers.resolve(x, states.users, states.image);
        })
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          return 1;
        });

      return Object.assign({}, folder, {
        children: child_folders,
        logs: folder.logs ? folder.logs.slice() : [],
        docs: child_docs,
      });
    },
  },
  searchDocs: {
    states: ['docs', 'folders', 'users', 'image'],
    mnemonic: 'argument_check',
    resetCB: () => {
      fuse = null;
    },
    fn: (states, search_str, search_archived = false) => {
      search_str = search_str.trim();
      if (search_str.trim().length < 2) {
        return [];
      }
      if (!fuse) {
        let folders = search_archived
          ? states.docs.all
          : states.docs.all.filter((x) => x.archived === false);
        folders = folders.map((doc) => {
          return doc_helpers.resolve(doc, states.users, states.image);
        });
        const search_options = {
          shouldSort: true,
          tokenize: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: withStore.getState().config
            .max_search_string_length,
          minMatchCharLength: 2,
          keys: ['name', 'created_by.first_name', 'created_by.last_name'],
        };
        fuse = new fusejs(folders, search_options);
      }
      return fuse.search(search_str).map((r) => r.item);
    },
  },
  getFoldersAndDocs: {
    states: ['docs', 'folders', 'users', 'image'],
    mnemonic: 'always',
    fn: (states) => {
      let docs = states.docs;
      let folders = states.folders;
      let root_id = folders.root_id;
      let archive_id = folders.archive_id;

      if (root_id === null || archive_id === null) {
        throw new Error('Missing root or archive folder!');
      }

      let temp_children = {};
      let temp_docs_inside = {};
      let by_id = {};
      let all = [];

      folders.all.forEach((folder) => {
        let children = temp_children.hasOwnProperty(folder.id)
          ? temp_children[folder.id]
          : [];
        let docs_inside = temp_docs_inside.hasOwnProperty(folder.id)
          ? temp_docs_inside[folder.id]
          : [];
        let temp_folder = Object.assign({}, folder, {
          children: children,
          docs: docs_inside,
        });
        if (by_id.hasOwnProperty(temp_folder.parent_id)) {
          by_id[temp_folder.parent_id].children.push(temp_folder);
        } else {
          if (temp_children.hasOwnProperty(temp_folder.parent_id) === false) {
            temp_children[temp_folder.parent_id] = [];
          }
          temp_children[temp_folder.parent_id].push(temp_folder);
        }
        by_id[temp_folder.id] = temp_folder;
        all.push(folder);
      });
      docs.all.forEach((doc) => {
        let presented_doc = doc_helpers.resolve(
          doc,
          states.users,
          states.image,
        );
        if (by_id.hasOwnProperty(doc.folder_id)) {
          by_id[doc.folder_id].docs.push(presented_doc);
        } else {
          if (temp_docs_inside.hasOwnProperty(doc.folder_id) === false) {
            temp_docs_inside[doc.folder_id] = [];
          }
          temp_docs_inside[doc.folder_id].push(presented_doc);
        }
      });
      return {
        tree: by_id[root_id],
        all: all,
        by_id: by_id,
      };
    },
  },
  getShapeForRoute: {
    states: ['gtfs'],
    mnemonic: 'never',
    fn: (states, route_id) => {
      if (states.gtfs.shapes_by_route.hasOwnProperty(route_id) === false) {
        throw new Error('No known shape for route: ' + route_id);
      }
      return states.gtfs.shapes_by_route[route_id];
    },
  },
  getPassingRoutesForStop: {
    states: ['gtfs'],
    mnemonic: 'never',
    fn: (states, stop_id) => {
      return states.gtfs.passing_routes_by_stop.hasOwnProperty(stop_id)
        ? states.gtfs.passing_routes_by_stop[stop_id]
        : [];
    },
  },
  getLabelForRouteShortName: {
    states: ['gtfs'],
    mnemonic: 'never',
    fn: (states, route_short_name) => {
      return states.gtfs.alias_by_route_short_name.hasOwnProperty(
        route_short_name,
      )
        ? states.gtfs.alias_by_route_short_name[route_short_name]
        : route_short_name;
    },
  },

  getSearchOptionsForBusStops: {
    states: ['gtfs'],
    mnemonic: 'always',
    fn: (states) => {
      let stops = states.gtfs.stops;
      return Object.keys(stops).map((stop_id) => {
        let stop = stops[stop_id];
        return {
          title: stop.stop_name + ' ' + stop.stop_code,
          subtitle: stop_id,
          id: stop_id,
          type: 'bus_stop',
        };
      });
    },
  },
  getSearchOptionsForBusRoutes: {
    states: ['gtfs'],
    mnemonic: 'always',
    fn: (states) => {
      return states.gtfs.routes.map((route) => {
        let route_name = route.route_alias
          ? route.route_alias
          : oh.translate('bus_route') + ' ' + route.route_short_name;
        return {
          title: route_name + ': ' + route.route_long_name,
          subtitle:
            oh.translate('route_variant') + ' ' + route.route_id.split('-')[1],
          id: route.route_id,
          type: 'route',
        };
      });
    },
  },
};
