import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: left;
  p {
    color: ${(props) => props.theme.colors.dark[1]};
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  input {
    border: 2px solid ${(props) => props.theme.colors.gray[2]};
    border-radius: ${(props) => props.theme.sizes.general.input_radius + 'px'};
    font-size: 16px;
    padding: ${(props) => props.theme.sizes.general.input_padding};
    width: 100%;
    transition: all ${(props) => props.theme.sizes.general.transition_time}s;
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.colors.brand[2]};
    }
  }
`;

class TextInput extends React.Component {
  render() {
    let req = this.props.request;
    return (
      <Container className="user_input text_input">
        {req.label ? <p>{req.label}</p> : null}
        <input
          autoFocus={true}
          type="text"
          value={this.props.value}
          onChange={(e) => this.props.onChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.props.modalConfirm();
            }
          }}
        />
      </Container>
    );
  }
}

export default TextInput;
