import React from 'react';
import withStore from 'with-store';
import Notifications from '../notifications';
import { withRouter } from 'react-router';

import api_helpers from '../helpers/api_helpers';
import * as gtfs_helpers from '../helpers/gtfs_helpers';

class RequiredDataCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requirements_met: false,
      doc_fetch_initiated: false,
      user_fetch_initiated: false,
      folders_fetch_initiated: false,
      open_fetch_initiated: false,
      gtfs_fetch_initiated: false,
    };
    props.store_actions.spinner.add();
  }

  static getDerivedStateFromProps(next_props, current_state) {
    let next_state = Object.assign({}, current_state, {
      requirements_met: true,
    });

    // if (next_props.store.dataset.fetched === false) {
    //     next_state.requirements_met = false;
    //     if (next_state.dataset_fetch_initiated === false) {
    //         next_state.dataset_fetch_initiated = true;
    //         api_helpers.fetchDatasets().then((dataset_arr) => {
    //             withStore.actions.dataset.setDatasetStateByArr(dataset_arr);
    //         });
    //     }
    // } else {
    //     next_state.dataset_fetch_initiated = false;
    // }

    if (next_props.store.docs.fetched === false) {
      next_state.requirements_met = false;
      if (next_state.doc_fetch_initiated === false) {
        next_state.doc_fetch_initiated = true;
        api_helpers.fetchDocuments().then((docs) => {
          withStore.actions.docs.setDocsStateByArr(docs, true);
        });
      }
    } else {
      next_state.doc_fetch_initiated = false;
    }

    if (next_props.store.folders.fetched === false) {
      next_state.requirements_met = false;
      if (next_state.folders_fetch_initiated === false) {
        next_state.folders_fetch_initiated = true;
        api_helpers.fetchFolders().then((folders) => {
          withStore.actions.folders.setFoldersStateByArr(folders, true);
        });
      }
    } else {
      next_state.folders_fetch_initiated = false;
    }

    if (next_props.store.users.fetched === false) {
      next_state.requirements_met = false;
      if (next_state.user_fetch_initiated === false) {
        next_state.user_fetch_initiated = true;
        api_helpers.fetchAllUsers().then((users) => {
          withStore.actions.users.setUserStateByArr(users, true);
        });
      }
    } else {
      next_state.user_fetch_initiated = false;
    }

    if (next_props.store.open.fetched === false) {
      next_state.requirements_met = false;
      if (next_state.open_fetch_initiated === false) {
        next_state.open_fetch_initiated = true;
        api_helpers.fetchOpen().then((open) => {
          withStore.actions.open.setOpen(open, true);
        });
      }
    } else {
      next_state.open_fetch_initiated = false;
    }

    if (next_props.store.gtfs.fetched === false) {
      next_state.requirements_met = false;
      if (next_state.gtfs_fetch_initiated === false) {
        next_state.gtfs_fetch_initiated = true;
        gtfs_helpers.reload();
      }
    } else {
      next_state.table_periods_fetch_initiated = false;
    }

    if (
      current_state.requirements_met === false &&
      next_state.requirements_met === true
    ) {
      next_props.store_actions.spinner.remove();
      Notifications.unpause();
    }
    return next_state;
  }

  // componentDidUpdate(prev_props) {
  //     let url_doc_id = this.props.match.params.doc_id; //This is always a string.
  //     //Note: The state has to be explicity cleared,
  //     //does not clear when parameter is removed from url.
  //     if (!url_doc_id) { return; }
  //     url_doc_id = parseInt(url_doc_id, 10);
  //     if (!this.props.store.docs.current_doc_id && url_doc_id) {
  //         this.props.store_actions.docs.setCurrentDoc(url_doc_id);
  //     } else if (prev_props.store.docs.current_doc_id !== url_doc_id) {
  //         this.props.store_actions.docs.setCurrentDoc(url_doc_id);
  //     }
  // }

  render() {
    if (this.state.requirements_met === false) {
      return null;
    }
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(withStore(RequiredDataCheck));
