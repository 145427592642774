import React from 'react';
import ReactDOM from 'react-dom';
import proj4 from 'proj4';

import ScriptCache from './ScriptCache';
import ScissorIcon from './marker_scissors.svg';
import ScissorHoverIcon from './marker_scissors_hover.svg';

const DEFAULT_POLYLINE_OPTIONS = {
  visible: true,
};
const DEFAULT_POLYGON_OPTIONS = {
  visible: true,
};
const DEFAULT_MARKER_OPTIONS = {
  visible: true,
};
const DEFAULT_DIRECTIONS_OPTIONS = {
  visible: true,
  hideRouteList: true,
  suppressInfoWindows: true,
  preserveViewport: true,
};
const CUTTING_SNAP_DISTANCE = 200;
const Z_INDEX_HIGHLIGHTS = 8999; //The highlight-objects z-index.
const Z_INDEX_HIGHLIGHTED = 9000; //The original objects z-index during highlighten.
const Z_INDEX_SCISSORS = 9001;
const Z_INDEX_SCISSORS_HOVER = 9002;
const EARTH_RADIUS = 6378137;
const PROJECTIONS = {
  gmaps:
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0.0 +k=1.0 +units=m +nadgrids=@null +wktext +no_defs +over',
  rt90:
    '+proj=tmerc +lat_0=0 +lon_0=15.80827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs',
  sweref99:
    '+proj=tmerc +lat_0=0 +lon_0=15.80628452944445 +k=1.00000561024 +x_0=1500064.274 +y_0=-667.711 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
};
proj4.defs('GMAPS', PROJECTIONS.gmaps);
proj4.defs('RT90', PROJECTIONS.rt90);
proj4.defs('SWEREF99', PROJECTIONS.sweref99);

export default class MapBase extends React.Component {
  constructor() {
    super();

    this.map = null;
    this.initialized = false;

    this.map_objects = {
      directions: {},
      marker: {},
      polygon: {},
      polyline: {},
    };
    this.highlight_objects = {
      marker: {},
      polygon: {},
      polyline: {},
    };
    this.cutting_objects = {};

    this.do_after_init = [];
    this.do_on_drag_end = [];
    this.do_on_drag_start = [];
    this.do_on_idle = [];

    this.overlay = null;
    this.cutting = {
      enabled: false,
      id: null,
      indexes: null,
    };
    this.cancel_drawing = false;

    this.helpers = {
      rt90: {
        pointsAroundCircle: makePointsAroundCircleRT90,
        makeRect: makeRectRT90,
        arrayRT90ToWGS84: (rt90arr) => {
          return convert('RT90', 'WGS84', rt90arr);
        },
        arrayRT90ToLatLngObj: (rt90arr) => {
          return arrayToLatLngObject(convert('RT90', 'WGS84', rt90arr), true);
        },
        movePointsByCoord: movePointsByCoord,
      },
      arrToLatLngObj: arrayToLatLngObject,
      latLngArrayToArrayOfArrays: latLngArrayToArrayOfArrays,
      convert: convert,
      haversineDistance: haversineDistance,
      MVCArrayToArrayOfArrays: MVCArrayToArrayOfArrays,
      MVCArrayToObjArray: MVCArrayToObjArray,
    };
  }

  componentDidMount() {
    this.script_cache = ScriptCache({
      google:
        'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,places,drawing&key=' +
        this.props.api_key,
    });
    const refs = this.refs;
    if (this.props.id) {
      if (window.hasOwnProperty('allbin_gmaps')) {
        window.allbin_gmaps[this.props.id] = this;
      }
    }
    this.script_cache.google.onLoad((err, tag) => {
      function CanvasProjectionOverlay() {}
      CanvasProjectionOverlay.prototype = new window.google.maps.OverlayView();
      CanvasProjectionOverlay.prototype.constructor = CanvasProjectionOverlay;
      CanvasProjectionOverlay.prototype.onAdd = function () {};
      CanvasProjectionOverlay.prototype.draw = function () {};
      CanvasProjectionOverlay.prototype.onRemove = function () {};

      const mapRef = refs.map;
      this.html_element = ReactDOM.findDOMNode(mapRef);

      let center = this.props.center || this.props.defaultCenter;
      if (!center) {
        console.error(
          "Could not create map: Requires either 'center' or 'defaultCenter' prop.",
        );
        return;
      }
      let zoom =
        typeof this.props.zoom !== 'undefined'
          ? this.props.zoom
          : typeof this.props.defaultZoom !== 'undefined'
          ? this.props.defaultZoom
          : null;
      if (!zoom) {
        console.error(
          "Could not create map: Requires either 'zoom' or 'defaultZoom' prop.",
        );
        return;
      }
      let defaults = this.props.defaultOptions || {};
      let mapConfig = Object.assign({}, defaults, {
        center: new window.google.maps.LatLng(center.lat, center.lng),
        zoom: zoom,
        gestureHandling: 'greedy',
        styles: this.props.styles || {},
      });
      const maps = window.google.maps;

      this.map = new maps.Map(this.html_element, mapConfig);
      this.services = {
        geocoderService: new window.google.maps.Geocoder(),
        directionsService: new window.google.maps.DirectionsService(),
      };
      if (window.google.maps.drawing) {
        this.services.drawing = window.google.maps.drawing;
        this.services.drawingManager = new window.google.maps.drawing.DrawingManager(
          {
            // drawingMode: window.google.maps.drawing.OverlayType.POLYLINE,
            // drawingControl: true,
            // polylineOptions: {
            //     strokeColor: "#ff00ff",
            //     strokeWidth: 0,
            //     visible: true,
            //     editable: true,
            //     selectable: true
            // }
            drawingMode: null,
            drawingControl: false,
            drawingControlOptions: {
              drawingModes: [],
            },
          },
        );
        this.services.drawingManager.setMap(this.map);
      }

      this.overlay = new CanvasProjectionOverlay();
      this.overlay.setMap(this.map);

      this.setupMapEvents(this.map);

      window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.doAfterInit();
      });
    });
  }

  // componentDidUpdate(prev_props) {
  //     let new_map_opts = {};
  //     if (this.props.styles !== prev_props.styles) {
  //         //Styles have updated.
  //         Object.assign(new_map_opts, { styles: this.props.styles });
  //     }
  //     if (this.props.center !== prev_props.center) {
  //         this.map.setCenter(this.props.center);
  //     }
  //     if (this.props.zoom !== prev_props.zoom) {
  //         this.map.setCenter(this.props.zoom);
  //     }

  //     if (Object.keys(new_map_opts).length > 0) {
  //         this.map.setOptions(Object.assign(
  //             {},
  //             prev_props.styles,
  //             new_map_opts
  //         ));
  //     }
  // }

  doAfterInit() {
    this.initialized = true;
    this.do_after_init.forEach((cb) => {
      cb();
    });

    if (this.props.initializedCB) {
      //Tell parent we are initialized if the parent has asked for it.
      this.props.initializedCB(true);
    }
  }

  setCenter(latLng) {
    return new Promise((resolve, reject) => {
      if (!this.initialized) {
        this.do_after_init.push(() => {
          this.setCenter(latLng)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
        return;
      }
      this.map.setCenter(latLng);
      resolve();
      return;
    });
  }

  fitToBoundsArray(arr_of_arrays, invert) {
    return fitToBoundsOfArray(this, arr_of_arrays, invert);
  }
  fitToBoundsObjectArray(arr_of_objects) {
    return fitToBoundsOfObjectArray(this, arr_of_objects);
  }

  fromLatLngToPixel(latLng) {
    var topRight = this.map
      .getProjection()
      .fromLatLngToPoint(this.map.getBounds().getNorthEast());
    var bottomLeft = this.map
      .getProjection()
      .fromLatLngToPoint(this.map.getBounds().getSouthWest());
    var scale = Math.pow(2, this.map.getZoom());
    var worldPoint = this.map.getProjection().fromLatLngToPoint(latLng);
    return new window.google.maps.Point(
      (worldPoint.x - bottomLeft.x) * scale,
      (worldPoint.y - topRight.y) * scale,
    );
  }

  toPixel(lat_lng_input) {
    let node_rect = this.html_element.getBoundingClientRect();
    let lat_lng;
    if (lat_lng_input instanceof window.google.maps.LatLng) {
      lat_lng = lat_lng_input;
    } else {
      lat_lng = new window.google.maps.LatLng(lat_lng_input);
    }
    let pixel_obj = this.fromLatLngToPixel(lat_lng);
    return {
      screen: [pixel_obj.x + node_rect.left, pixel_obj.y + node_rect.top],
      container: [pixel_obj.x, pixel_obj.y],
      container_size: [node_rect.width, node_rect.height],
      is_visible:
        pixel_obj.x >= 0 &&
        pixel_obj.y >= 0 &&
        pixel_obj.x <= node_rect.width &&
        pixel_obj.y <= node_rect.height,
    };
  }

  setZoom(zoom_level) {
    return new Promise((resolve, reject) => {
      if (!this.initialized) {
        this.do_after_init.push(() => {
          this.setZoom(zoom_level)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
        return;
      }
      this.map.setZoom(zoom_level);
      resolve();
      return;
    });
  }

  setPolyline(id, options, hover_options = null, highlight_options = null) {
    return setMapObject(
      this,
      'polyline',
      id,
      options,
      hover_options,
      highlight_options,
    );
  }
  unsetPolyline(id) {
    return unsetMapObject(this, 'polyline', id);
  }
  clearPolylines() {
    let promise_arr = [];
    Object.keys(this.map_objects.polyline).forEach((id) => {
      promise_arr.push(unsetMapObject(this, 'polyline', id));
    });
    return Promise.all(promise_arr);
  }

  setPolygon(id, options, hover_options = null, highlight_options = null) {
    return setMapObject(
      this,
      'polygon',
      id,
      options,
      hover_options,
      highlight_options,
    );
  }
  unsetPolygon(id) {
    return unsetMapObject(this, 'polygon', id);
  }
  clearPolygons() {
    let promise_arr = [];
    Object.keys(this.map_objects.polygon).forEach((id) => {
      promise_arr.push(unsetMapObject(this, 'polygon', id));
    });
    return Promise.all(promise_arr);
  }

  setDirections(id, options, hover_options = null) {
    return setMapObject(this, 'directions', id, options, hover_options);
  }
  unsetDirections(id) {
    return unsetMapObject(this, 'directions', id);
  }
  clearDirections() {
    let promise_arr = [];
    Object.keys(this.map_objects.directions).forEach((id) => {
      promise_arr.push(unsetMapObject(this, 'directions', id));
    });
    return Promise.all(promise_arr);
  }

  setMarker(id, options, hover_options = null, highlight_options = null) {
    return setMapObject(
      this,
      'marker',
      id,
      options,
      hover_options,
      highlight_options,
    );
  }
  unsetMarker(id) {
    return unsetMapObject(this, 'marker', id);
  }
  clearMarkers() {
    let promise_arr = [];
    Object.keys(this.map_objects.marker).forEach((id) => {
      promise_arr.push(unsetMapObject(this, 'marker', id));
    });
    return Promise.all(promise_arr);
  }

  registerDragEndCB(cb) {
    //Is actually triggered by Idle, not DragEnd!
    this.do_on_drag_end.push(cb);
  }
  unregisterDragEndCB(cb) {
    let index = this.do_on_drag_end.indexOf(cb);
    if (index > -1) {
      this.do_on_drag_end.splice(index, 1);
    }
  }
  registerDragStartCB(cb) {
    this.do_on_drag_start.push(cb);
  }
  unregisterDragStartCB(cb) {
    let index = this.do_on_drag_start.indexOf(cb);
    if (index > -1) {
      this.do_on_drag_start.splice(index, 1);
    }
  }
  registerIdleCB(cb) {
    this.do_on_idle.push(cb);
  }
  unregisterIdleCB(cb) {
    let index = this.do_on_idle.indexOf(cb);
    if (index > -1) {
      this.do_on_idle.splice(index, 1);
    }
  }
  setupMapEvents(map) {
    map.addListener('center_changed', () => {
      if (this.props.onCenterChanged) {
        this.props.onCenterChanged();
      }
    });
    map.addListener('bounds_changed', () => {
      if (this.props.onBoundsChanged) {
        this.props.onBoundsChanged();
      }
    });
    map.addListener('click', (mouse_event) => {
      if (this.cutting.enabled) {
        this.cuttingClick(mouse_event);
      }
      if (this.props.onClick && !this.cutting.enabled) {
        this.props.onClick(mouse_event);
      }
    });
    map.addListener('dblclick', (mouse_event) => {
      if (this.props.onDoubleClick && !this.cutting.enabled) {
        this.props.onDoubleClick(mouse_event);
      }
    });
    map.addListener('drag', () => {
      if (this.props.onDrag && !this.cutting.enabled) {
        this.props.onDrag();
      }
    });
    map.addListener('dragend', () => {
      if (this.props.onDragEnd && !this.cutting.enabled) {
        this.props.onDragEnd();
      }
    });
    map.addListener('dragstart', () => {
      this.do_on_drag_start.forEach((cb) => {
        if (!this.cutting.enabled) {
          cb();
        }
      });
      if (this.props.onDragStart && !this.cutting.enabled) {
        this.props.onDragStart();
      }
    });
    map.addListener('heading_changed', () => {
      if (this.props.onHeadingChanged) {
        this.props.onHeadingChanged();
      }
    });
    map.addListener('idle', () => {
      if (!this.cutting.enabled) {
        this.do_on_drag_end.forEach((cb) => {
          cb();
        });
        this.do_on_idle.forEach((cb) => {
          cb();
        });
      }
      if (this.props.onIdle && !this.cutting.enabled && this.initialized) {
        this.props.onIdle();
      }
    });
    map.addListener('maptypeid_changed', () => {
      if (this.props.onMapTypeIdChanged) {
        this.props.onMapTypeIdChanged();
      }
    });
    map.addListener('mousemove', (mouse_event) => {
      if (this.cutting.enabled) {
        this.cuttingPositionUpdate(mouse_event);
      }
      if (this.props.onMouseMove) {
        this.props.onMouseMove(mouse_event);
      }
    });
    map.addListener('mouseout', (mouse_event) => {
      if (this.props.onMouseOut) {
        this.props.onMouseOut(mouse_event);
      }
    });
    map.addListener('mouseover', (mouse_event) => {
      if (this.props.onMouseOver) {
        this.props.onMouseOver(mouse_event);
      }
    });
    map.addListener('projection_changed', () => {
      if (this.props.onProjectionChanged) {
        this.props.onProjectionChanged();
      }
    });
    map.addListener('reize', () => {
      if (this.props.onResize) {
        this.props.onResize();
      }
    });
    map.addListener('rightclick', (mouse_event) => {
      if (this.props.onRightClick && !this.cutting.enabled) {
        this.props.onRightClick(mouse_event);
      }
    });
    map.addListener('tilesloaded', () => {
      if (this.props.onTilesLoaded) {
        this.props.onTilesLoaded();
      }
    });
    map.addListener('tilt_changed', () => {
      if (this.props.onTiltChanged) {
        this.props.onTiltChanged();
      }
    });
    map.addListener('zoom_changed', () => {
      if (this.props.onZoomChanged) {
        this.props.onZoomChanged();
      }
    });
  }

  setDrawingMode(type, opts, cb = null) {
    let mode = null;
    if (!this.services.drawing) {
      console.error(
        'MAP: Drawing library not available! Add it to google maps api request url.',
      );
      return;
    }
    if (this.services.drawing.OverlayType.hasOwnProperty(type.toUpperCase())) {
      mode = this.services.drawing.OverlayType[type.toUpperCase()];
    } else {
      throw new Error('MAP: Invalid drawing mode type:', type);
    }
    let drawing_opts = Object.assign({}, opts, { drawingMode: mode });
    this.services.drawingManager.setOptions(drawing_opts);
    console.log('MAP: Drawing mode started for:', type + '.');
    this.cancel_drawing = false;

    if (this.drawing_completed_listener) {
      this.drawing_completed_listener.remove();
    }
    this.drawing_completed_listener = window.google.maps.event.addListenerOnce(
      this.services.drawingManager,
      'overlaycomplete',
      (e) => {
        // console.log("overlay complete", cb, this.cancel_drawing);
        e.overlay.setMap(null);
        drawing_opts.drawingMode = null;
        this.services.drawingManager.setOptions(drawing_opts);
        if (!cb || this.cancel_drawing) {
          return;
        }
        if (type === 'polyline' || type === 'polygon') {
          let path = MVCArrayToArrayOfArrays(e.overlay.getPath());
          cb(path, e.overlay);
        } else if (type === 'marker') {
          let pos = e.overlay.getPosition();
          cb([pos.lat(), pos.lng()], e.overlay);
        } else {
          cb(null, e.overlay);
        }
        this.cancel_drawing = false;
        this.drawing_completed_listener = null;
      },
    );
  }
  completeDrawingMode() {
    if (this.services.drawing) {
      this.services.drawingManager.setOptions({ drawingMode: null });
    }
    if (this.drawing_completed_listener) {
      this.drawing_completed_listener.remove();
      this.drawing_completed_listener = null;
    }
  }
  cancelDrawingMode(src) {
    // console.log("cancel drawing mode:", src);
    if (this.services.drawing && this.drawing_completed_listener) {
      this.cancel_drawing = true;
      this.services.drawingManager.setOptions({ drawingMode: null });
    }
  }

  setCuttingMode(polyline_id, cb = null) {
    if (this.map_objects.polyline.hasOwnProperty(polyline_id) === false) {
      console.error(
        'MAP: Cannot set cutting mode, provided object id not on map: ',
        polyline_id,
      );
      return;
    }
    if (!cb) {
      console.error(
        'MAP: Cannot setCuttingMode without supplying completed callback.',
      );
      return;
    }
    this.cancelDrawingMode('setCuttingMode');
    let polyline = this.map_objects.polyline[polyline_id];
    let opts = {
      clickable: false,
      editable: false,
    };
    polyline.gmaps_obj.setOptions(opts);

    this.cutting = {
      enabled: true,
      id: polyline_id,
      indexes: [],
      arr: polyline.options.path,
    };
    if (!this.cutting_objects.hasOwnProperty('hover_scissors')) {
      let opts = {
        position: this.props.defaultCenter,
        icon: {
          url: ScissorHoverIcon,
        },
        zIndex: Z_INDEX_SCISSORS_HOVER,
        visible: false,
        clickable: false,
        editable: false,
        draggable: false,
      };
      let hover_scissors = {
        gmaps_obj: new window.google.maps.Marker(opts),
        options: opts,
      };
      hover_scissors.gmaps_obj.setMap(this.map);
      this.cutting_objects.hover_scissors = hover_scissors;
    }
    console.log('MAP: Cutting mode started for id: ' + polyline_id);
    this.cutting_completed_listener = (value) => {
      cb(value);
    };
  }
  cuttingPositionUpdate(mouse_event) {
    if (!this.cutting.enabled) {
      //If we are not in cutting mode ignore this function call.
      return;
    }
    let polyline = this.map_objects.polyline[this.cutting.id];
    let mouse_coord = {
      lat: mouse_event.latLng.lat(),
      lng: mouse_event.latLng.lng(),
    };
    let closest_index = 0;
    let closest_dist = 9999999;
    //Find nearest index and move scissors_hover marker.
    polyline.options.path.forEach((point, i) => {
      let dist = haversineDistance(mouse_coord, point);
      if (dist < closest_dist) {
        closest_index = i;
        closest_dist = dist;
      }
    });
    if (
      closest_dist < CUTTING_SNAP_DISTANCE &&
      closest_index > 0 &&
      closest_index < polyline.options.path.length - 1
    ) {
      this.cutting_objects.hover_scissors.gmaps_obj.setOptions({
        position: polyline.options.path[closest_index],
        visible: true,
      });
    } else {
      this.cutting_objects.hover_scissors.gmaps_obj.setOptions({
        visible: false,
      });
    }
  }
  cuttingClick(mouse_event) {
    let polyline = this.map_objects.polyline[this.cutting.id];
    let mouse_coord = {
      lat: mouse_event.latLng.lat(),
      lng: mouse_event.latLng.lng(),
    };
    let closest_index = 0;
    let closest_dist = 9999999;
    polyline.options.path.forEach((point, i) => {
      let dist = haversineDistance(mouse_coord, point);
      if (dist < closest_dist) {
        closest_index = i;
        closest_dist = dist;
      }
    });
    if (closest_dist > CUTTING_SNAP_DISTANCE) {
      //Pointer is too far away from any point, ignore.
      return;
    }
    if (
      closest_index === 0 ||
      closest_index === polyline.options.path.length - 1
    ) {
      //We are never interested in first or last point.
      return;
    }
    let already_selected_position = this.cutting.indexes.findIndex(
      (value) => closest_index === value,
    );
    if (already_selected_position > -1) {
      //This index has already been selected for cutting, remove it.
      this.cutting.indexes.splice(already_selected_position, 1);
      if (this.cutting_objects.hasOwnProperty('index_' + closest_index)) {
        //We have drawn a marker for this cut, remove it.
        this.cutting_objects['index_' + closest_index].gmaps_obj.setMap(null);
        delete this.cutting_objects['index_' + closest_index];
      }
    } else {
      this.cutting.indexes.push(closest_index);
      let opts = {
        position: polyline.options.path[closest_index],
        icon: {
          url: ScissorIcon,
        },
        zIndex: Z_INDEX_SCISSORS,
        visible: true,
        clickable: false,
        editable: false,
        draggable: false,
      };
      let cut_marker = {
        gmaps_obj: new window.google.maps.Marker(opts),
        options: opts,
      };
      cut_marker.gmaps_obj.setMap(this.map);
      this.cutting_objects['index_' + closest_index] = cut_marker;
    }
  }
  completeCuttingMode() {
    if (!this.cutting || this.cutting.id === null) {
      return;
    }
    let indexes = this.cutting.indexes;
    let polyline = this.map_objects.polyline[this.cutting.id];
    if (!polyline) {
      return;
    }
    this.cutting = {
      enabled: false,
      id: null,
      indexes: null,
    };
    Object.keys(this.cutting_objects).forEach((marker_id) => {
      //Remove all cutting related markers.
      this.cutting_objects[marker_id].gmaps_obj.setMap(null);
      delete this.cutting_objects[marker_id];
    });

    let opts = {
      clickable: true,
      editable: true,
    };
    polyline.gmaps_obj.setOptions(opts);
    if (!indexes || indexes.length === 0) {
      //We made no selections, just return.
      this.cutting_completed_listener(null);
    }

    let path = polyline.options.path;
    indexes.sort();
    //Add last index so that the remaining points form a segment as well.
    indexes.push(path.length - 1);
    let resulting_segments = [];
    let prev_index = 0;
    indexes.forEach((index) => {
      let segment = path.slice(prev_index, index);
      //Copy last point as well.
      segment.push(path[index]);
      resulting_segments.push(segment);
      prev_index = index;
    });
    this.cutting_completed_listener(resulting_segments);
  }
  cancelCuttingMode() {
    let polyline = this.map_objects.polyline[this.cutting.id];
    this.cutting = {
      enabled: false,
      id: null,
      indexes: null,
    };
    if (polyline) {
      let opts = {
        clickable: true,
        editable: true,
      };
      polyline.gmaps_obj.setOptions(opts);
    }
    Object.keys(this.cutting_objects).forEach((marker_id) => {
      //Remove all cutting related markers.
      this.cutting_objects[marker_id].gmaps_obj.setMap(null);
      delete this.cutting_objects[marker_id];
    });
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <div
          ref="map"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
          }}
        />
      </div>
    );
  }
}

/////////////////////////////////
//INTERNAL MAP HELPER FUNCTIONS
//
//

function fitToBoundsOfArray(map_ref, arr_of_arrays, invert = false) {
  //Takes [[x, y], ...] array.
  return new Promise((resolve, reject) => {
    if (Array.isArray(arr_of_arrays) === false) {
      reject('Input not valid array.');
    } else if (arr_of_arrays.length < 1) {
      reject('Array needs to countain at least one element.');
    }
    if (!map_ref.initialized) {
      map_ref.do_after_init.push(() => {
        fitToBoundsOfArray(map_ref, arr_of_arrays)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
      return;
    }
    let lat_lng_literal = {
      east: -99999999,
      west: 99999999,
      north: 99999999,
      south: -99999999,
    };

    arr_of_arrays.forEach((point) => {
      if (invert) {
        lat_lng_literal.west =
          point[1] < lat_lng_literal.west ? point[1] : lat_lng_literal.west;
        lat_lng_literal.east =
          point[1] > lat_lng_literal.east ? point[1] : lat_lng_literal.east;
        lat_lng_literal.north =
          point[0] < lat_lng_literal.north ? point[0] : lat_lng_literal.north;
        lat_lng_literal.south =
          point[0] > lat_lng_literal.south ? point[0] : lat_lng_literal.south;
        return;
      }
      lat_lng_literal.west =
        point[0] < lat_lng_literal.west ? point[0] : lat_lng_literal.west;
      lat_lng_literal.east =
        point[0] > lat_lng_literal.east ? point[0] : lat_lng_literal.east;
      lat_lng_literal.north =
        point[1] < lat_lng_literal.north ? point[1] : lat_lng_literal.north;
      lat_lng_literal.south =
        point[1] > lat_lng_literal.south ? point[1] : lat_lng_literal.south;
    });

    map_ref.map.fitBounds(lat_lng_literal);
    resolve();
  });
}
function fitToBoundsOfObjectArray(map_ref, arr_of_objects) {
  //Takes [{ lat: ?, lng: ? }, ...] array.
  return new Promise((resolve, reject) => {
    if (Array.isArray(arr_of_objects) === false) {
      reject('Input not valid array.');
    } else if (arr_of_objects.length < 1) {
      reject('Array needs to countain at least one element.');
    }
    if (!map_ref.initialized) {
      map_ref.do_after_init.push(() => {
        fitToBoundsOfObjectArray(map_ref, arr_of_objects)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
      return;
    }
    let lat_lng_literal = {
      east: -99999999,
      west: 99999999,
      north: 99999999,
      south: -99999999,
    };

    arr_of_objects.forEach((point) => {
      lat_lng_literal.west =
        point.lng < lat_lng_literal.west ? point.lng : lat_lng_literal.west;
      lat_lng_literal.east =
        point.lng > lat_lng_literal.east ? point.lng : lat_lng_literal.east;
      lat_lng_literal.north =
        point.lat < lat_lng_literal.north ? point.lat : lat_lng_literal.north;
      lat_lng_literal.south =
        point.lat > lat_lng_literal.south ? point.lat : lat_lng_literal.south;
    });

    map_ref.map.fitBounds(lat_lng_literal);
    resolve();
  });
}

function setMapObject(
  map_ref,
  type,
  id,
  options,
  hover_options = null,
  highlight_options = null,
) {
  return new Promise((resolve, reject) => {
    if (!map_ref.initialized) {
      map_ref.do_after_init.push(() => {
        setMapObject(
          map_ref,
          type,
          id,
          options,
          hover_options,
          highlight_options,
        )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
      return;
    }

    if (map_ref.map_objects[type].hasOwnProperty(id)) {
      //This ID has already been drawn.
      let map_obj = map_ref.map_objects[type][id];
      map_obj.highlight_options = highlight_options;
      let opts;
      if (map_obj.hovered && hover_options) {
        opts = Object.assign({}, map_obj.options, options, hover_options);
      } else {
        opts = Object.assign({}, map_obj.options, options);
      }
      map_obj.hover_options = hover_options;
      map_obj.gmaps_obj.setOptions(opts);
      map_obj.options = options;
      if (map_obj.highlighted && highlight_options) {
        //The highlight has been called on this object AND we do have highlight options.
        map_obj.highlight();
      }
      if (type === 'polygon' || type === 'polyline') {
        let path_events = ['set_at', 'remove_at', 'insert_at'];
        path_events.forEach((event_type) => {
          map_obj.gmaps_obj.getPath().addListener(event_type, (e) => {
            return mapObjectEventCB(map_ref, map_obj, event_type, e);
          });
        });
      }
      resolve(map_obj);
      return;
    }

    let map_obj;
    let events = [];
    let path_events = [];
    switch (type) {
      case 'directions': {
        let opts = Object.assign({}, DEFAULT_DIRECTIONS_OPTIONS, options);
        hover_options = null; //Directions cannot have hover event.
        highlight_options = null; //Directions cannot have highlight.
        map_obj = {
          gmaps_obj: new window.google.maps.DirectionsRenderer(opts),
          options: opts,
        };
        break;
      }
      case 'marker': {
        let opts = Object.assign({}, DEFAULT_MARKER_OPTIONS, options);
        map_obj = {
          gmaps_obj: new window.google.maps.Marker(opts),
          options: opts,
        };
        events = [
          'click',
          'mouseover',
          'mouseout',
          'mousedown',
          'mouseup',
          'dragstart',
          'drag',
          'dragend',
          'dblclick',
          'rightclick',
        ];
        break;
      }
      case 'polygon': {
        let opts = Object.assign({}, DEFAULT_POLYGON_OPTIONS, options);
        map_obj = {
          gmaps_obj: new window.google.maps.Polygon(opts),
          options: opts,
        };
        events = [
          'click',
          'dblclick',
          'dragstart',
          'drag',
          'dragend',
          'mouseover',
          'mouseout',
          'mousedown',
          'mouseup',
          'mousemove',
          'rightclick',
        ];
        path_events = ['set_at', 'remove_at', 'insert_at'];
        break;
      }
      case 'polyline': {
        let opts = Object.assign({}, DEFAULT_POLYLINE_OPTIONS, options);
        map_obj = {
          gmaps_obj: new window.google.maps.Polyline(opts),
          options: opts,
        };
        events = [
          'click',
          'dblclick',
          'dragstart',
          'drag',
          'dragend',
          'mouseover',
          'mouseout',
          'mousedown',
          'mouseup',
          'mousemove',
          'rightclick',
        ];
        path_events = ['set_at', 'remove_at', 'insert_at'];
        break;
      }
      default: {
        reject(new Error('Invalid map object type.'));
      }
    }
    map_obj.hover_options = hover_options;
    map_obj.hovered = false;
    map_obj.highlight_options = highlight_options;
    map_obj.highlighted = false;
    map_obj.type = type;
    map_obj._cbs = {};

    events.forEach((event_type) => {
      map_obj.gmaps_obj.addListener(event_type, (e) => {
        return mapObjectEventCB(map_ref, map_obj, event_type, e);
      });
    });
    path_events.forEach((event_type) => {
      map_obj.gmaps_obj.getPath().addListener(event_type, (e) => {
        return mapObjectEventCB(map_ref, map_obj, event_type, e);
      });
    });

    map_obj.registerEventCB = (event_type, cb) => {
      if (type === 'directions') {
        console.error('Directions renderer does not support events.');
      }
      map_obj._cbs[event_type] = cb;
    };
    map_obj.unregisterEventCB = (event_type) => {
      if (map_obj._cbs.hasOwnProperty(event_type)) {
        delete map_obj._cbs[event_type];
      }
    };

    map_obj.hover = () => {
      if (!map_obj.hover_options) {
        return;
      }
      let all_opts = Object.assign({}, map_obj.options, map_obj.hover_options);
      let opts = {};
      let whitelisted_opts = [
        'zIndex',
        'strokeColor',
        'strokeWeight',
        'fillColor',
        'fillOpacity',
        'icon',
        'icons',
        'label',
      ];
      whitelisted_opts.forEach((opt) => {
        if (all_opts.hasOwnProperty(opt)) {
          opts[opt] = all_opts[opt];
        }
      });
      map_obj.gmaps_obj.setOptions(opts);
      map_obj.hovered = true;
    };
    map_obj.unhover = () => {
      let all_opts = Object.assign({}, map_obj.options);
      let opts = {};
      let whitelisted_opts = [
        'zIndex',
        'strokeColor',
        'strokeWeight',
        'fillColor',
        'fillOpacity',
        'icon',
        'icons',
        'label',
      ];
      whitelisted_opts.forEach((opt) => {
        if (all_opts.hasOwnProperty(opt)) {
          opts[opt] = all_opts[opt];
        }
      });
      if (opts.hasOwnProperty('icons') === false) {
        opts.icons = null;
      }
      map_obj.gmaps_obj.setOptions(opts);
      map_obj.hovered = false;
    };
    map_obj.remove = () => {
      return unsetMapObject(map_ref, type, id);
    };
    map_obj.update = (new_options) => {
      return setMapObject(
        map_ref,
        type,
        id,
        new_options,
        map_obj.hover_options,
      );
    };
    map_obj.updateHover = (new_hover_options) => {
      return setMapObject(
        map_ref,
        type,
        id,
        map_obj.options,
        new_hover_options,
      );
    };
    map_obj.hide = () => {
      map_obj.gmaps_obj.setOptions(
        Object.assign({}, map_obj.options, { visible: false }),
      );
      if (map_ref.highlight_objects[type].hasOwnProperty(id)) {
        map_ref.highlight_objects[type][id].gmaps_obj.setOptions({
          visible: false,
        });
      }
    };
    map_obj.show = () => {
      map_obj.gmaps_obj.setOptions(
        Object.assign({}, map_obj.options, { visible: true }),
      );
      if (map_obj.highlighted) {
        map_obj.highlight();
      }
    };

    map_obj.highlight = () => {
      if (!map_obj.highlight_options) {
        if (type === 'directions') {
          console.log(
            'MAP: Map directions do not support highlighting. Use custom functionality.',
          );
          return;
        }
        console.log('MAP: Cannot highlight, no highlight_options specified.');
        return;
      }
      let high_opts = Object.assign(
        {},
        map_obj.options,
        options,
        map_obj.highlight_options,
        { visible: true, clickable: false },
      );
      if (map_ref.highlight_objects[type].hasOwnProperty(id)) {
        let high_obj = map_ref.highlight_objects[type][id];
        high_obj.gmaps_obj.setOptions(high_opts);
      } else {
        console.log('high_opts:', high_opts);
        createHighlightObject(map_ref, type, id, high_opts);
      }
      let opts;
      if (map_obj.hovered && map_obj.hover_options) {
        opts = Object.assign(
          {},
          map_obj.options,
          options,
          map_obj.hover_options,
        );
      } else {
        opts = Object.assign({}, map_obj.options, options);
      }
      map_obj.gmaps_obj.setOptions(
        Object.assign({}, opts, { zIndex: Z_INDEX_HIGHLIGHTED }),
      );
    };
    map_obj.unhighlight = () => {
      if (map_ref.highlight_objects[type].hasOwnProperty(id)) {
        let high_obj = map_ref.highlight_objects[type][id];
        let high_opts = Object.assign(
          {},
          map_obj.options,
          options,
          map_obj.highlight_options,
          { visible: false },
        );
        high_obj.gmaps_obj.setOptions(high_opts);
      }
      let opts;
      if (map_obj.hovered && map_obj.hover_options) {
        opts = Object.assign(
          {},
          map_obj.options,
          options,
          map_obj.hover_options,
        );
      } else {
        opts = Object.assign({}, map_obj.options, options);
      }
      map_obj.gmaps_obj.setOptions(Object.assign({}, opts));
    };

    map_obj.gmaps_obj.setMap(map_ref.map);

    map_ref.map_objects[type][id] = map_obj;
    resolve(map_ref.map_objects[type][id]);
    return;
  });
}
function unsetMapObject(map_ref, type, id) {
  return new Promise((resolve, reject) => {
    if (!map_ref.initialized) {
      map_ref.do_after_init.push(() => {
        map_ref
          .unsetMapObject(id)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
      return;
    }

    if (map_ref.map_objects[type].hasOwnProperty(id)) {
      //This ID has been drawn.

      if (map_ref.cutting.id === id) {
        //This object is currently being cut, it cannot be deleted.
        reject(
          new Error(
            'MAP: Object is currently in cuttingMode; it cannot be removed!',
          ),
        );
        return;
      }

      map_ref.map_objects[type][id].gmaps_obj.setMap(null);
      delete map_ref.map_objects[type][id];
      if (map_ref.highlight_objects[type].hasOwnProperty(id)) {
        //This object also has a highlight object attached to it.
        map_ref.highlight_objects[type][id].gmaps_obj.setMap(null);
        delete map_ref.highlight_objects[type][id];
      }
      resolve(true);
      return;
    }
    reject(new Error('MAP: MapObject does not exist.'));
  });
}

function mapObjectEventCB(map_ref, map_obj, event_type, e) {
  // if (map_obj.type === "polyline" && event_type !== "mouseover" && event_type !== "mouseout" && event_type !== "mousemove") {
  //     console.log("event_type:", event_type);
  // }
  if (map_ref.cutting.enabled) {
    //When the map is in cutting mode no object event callbacks are allowed.
    return true;
  }
  if (event_type === 'mouseover') {
    map_obj.hover();
  }
  if (event_type === 'mouseout') {
    map_obj.unhover();
  }
  if (map_obj.type === 'polyline') {
    if (event_type === 'dragend') {
      map_obj.options.path = MVCArrayToObjArray(map_obj.gmaps_obj.getPath());
    }
    if (event_type === 'set_at') {
      map_obj.options.path = MVCArrayToObjArray(map_obj.gmaps_obj.getPath());
    }
    if (event_type === 'remove_at') {
      map_obj.options.path = MVCArrayToObjArray(map_obj.gmaps_obj.getPath());
    }
    if (event_type === 'insert_at') {
      map_obj.options.path = MVCArrayToObjArray(map_obj.gmaps_obj.getPath());
    }
  }

  if (map_obj._cbs.hasOwnProperty(event_type) && map_obj._cbs[event_type]) {
    map_obj._cbs[event_type](e);
  }
  return true;
}

function createHighlightObject(map_ref, type, id, opts) {
  opts.zIndex = Z_INDEX_HIGHLIGHTS;
  opts.clickable = false;
  let high_obj;
  switch (type) {
    case 'marker': {
      high_obj = {
        gmaps_obj: new window.google.maps.Marker(opts),
        options: opts,
      };
      break;
    }
    case 'polygon': {
      high_obj = {
        gmaps_obj: new window.google.maps.Polygon(opts),
        options: opts,
      };
      break;
    }
    case 'polyline': {
      high_obj = {
        gmaps_obj: new window.google.maps.Polyline(opts),
        options: opts,
      };
      break;
    }
    default: {
      throw new Error('Invalid map object type for highlighting.');
    }
  }
  high_obj.gmaps_obj.setMap(map_ref.map);
  map_ref.highlight_objects[type][id] = high_obj;
}

////////////EXPORTED HELPER FUNCTIONS
//Check Map.helpers for usage.

function convert(fromProj, toProj, points) {
  let newPointArr = [];
  if (typeof points[0] === 'object') {
    points.forEach((point) => {
      newPointArr.push(proj4(fromProj, toProj, point));
    });
  } else {
    newPointArr = proj4(fromProj, toProj, points);
  }
  return newPointArr;
}

function arrayToLatLngObject(arr, invert = false) {
  if (invert) {
    return arr.map((point) => {
      return { lat: point[1], lng: point[0] };
    });
  }
  return arr.map((point) => {
    return { lat: point[0], lng: point[1] };
  });
}
function latLngArrayToArrayOfArrays(arr, invert) {
  if (invert) {
    return arr.map((point) => {
      return [point.lng, point.lat];
    });
  }
  return arr.map((point) => {
    return [point.lat, point.lng];
  });
}

function makePointsAroundCircleRT90(p, r, numberOfPoints = 12) {
  //Returns numberOfPoints around circle at p with r radius.

  let points = [];
  let i;

  for (i = 0; i < numberOfPoints; i += 1) {
    points.push([
      p[0] + r * Math.cos((2 * Math.PI * i) / numberOfPoints),
      p[1] + r * Math.sin((2 * Math.PI * i) / numberOfPoints),
    ]);
  }

  return points;
}

function makeRectRT90(p1, p2, chamfer = { r: 0, points: 0 }) {
  //TODO: Chamfer.
  //p1 and p2 should be opposite corners of the rectangle.
  let points = [];

  points.push([p1[0], p1[1]], [p2[0], p1[1]], [p2[0], p2[1]], [p1[0], p2[1]]);

  if (chamfer.r > 0) {
    let c_points = [];
    //TODO: Add code here.
    return c_points;
  }
  return points;
}

function movePointsByCoord(points_arr, coord) {
  //Adds value of Coord to all points in array.
  return points_arr.map((point) => {
    return [point[0] + coord[0], point[1] + coord[1]];
  });
}

function squared(x) {
  return x * x;
}
function toRad(x) {
  return (x * Math.PI) / 180;
}
function haversineDistance(a, b) {
  const aLat = a.lat;
  const bLat = b.lat;
  const aLng = a.lng;
  const bLng = b.lng;
  const dLat = toRad(bLat - aLat);
  const dLon = toRad(bLng - aLng);

  const f =
    squared(Math.sin(dLat / 2.0)) +
    Math.cos(toRad(aLat)) *
      Math.cos(toRad(bLat)) *
      squared(Math.sin(dLon / 2.0));
  const c = 2 * Math.atan2(Math.sqrt(f), Math.sqrt(1 - f));

  return EARTH_RADIUS * c;
}

function MVCArrayToObjArray(MVCArr) {
  return MVCArr.getArray().map((gmapsLatLng) => {
    return {
      lat: gmapsLatLng.lat(),
      lng: gmapsLatLng.lng(),
    };
  });
}

function MVCArrayToArrayOfArrays(MVCArr) {
  return MVCArr.getArray().map((gmapsLatLng) => {
    return [gmapsLatLng.lat(), gmapsLatLng.lng()];
  });
}

export function fitBoundsWithPadding(gMap, bounds, paddingXY) {
  // taken from: https://stackoverflow.com/questions/10339365/google-maps-api-3-fitbounds-padding-ensure-markers-are-not-obscured-by-overlai
  var projection = gMap.getProjection();
  if (projection) {
    if (!paddingXY) {
      paddingXY = { x: 0, y: 0 };
    }

    let paddings = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };

    if (paddingXY.left) {
      paddings.left = paddingXY.left;
    } else if (paddingXY.x) {
      paddings.left = paddingXY.x;
      paddings.right = paddingXY.x;
    }

    if (paddingXY.right) {
      paddings.right = paddingXY.right;
    }

    if (paddingXY.top) {
      paddings.top = paddingXY.top;
    } else if (paddingXY.y) {
      paddings.top = paddingXY.y;
      paddings.bottom = paddingXY.y;
    }

    if (paddingXY.bottom) {
      paddings.bottom = paddingXY.bottom;
    }

    // copying the bounds object, since we will extend it
    bounds = new window.google.maps.LatLngBounds(
      bounds.getSouthWest(),
      bounds.getNorthEast(),
    );
    gMap.fitBounds(bounds);

    // SW
    let point1 = projection.fromLatLngToPoint(bounds.getSouthWest());
    let point2 = new window.google.maps.Point(
      (typeof paddings.left === 'number' ? paddings.left : 0) /
        Math.pow(2, gMap.getZoom() - 1) || 0,
      (typeof paddings.bottom === 'number' ? paddings.bottom : 0) /
        Math.pow(2, gMap.getZoom() - 1) || 0,
    );

    var newPoint = projection.fromPointToLatLng(
      new window.google.maps.Point(point1.x - point2.x, point1.y + point2.y),
    );

    bounds.extend(newPoint);

    // NE
    point1 = projection.fromLatLngToPoint(bounds.getNorthEast());
    point2 = new window.google.maps.Point(
      (typeof paddings.right === 'number' ? paddings.right : 0) /
        Math.pow(2, gMap.getZoom() - 1) || 0,
      (typeof paddings.top === 'number' ? paddings.top : 0) /
        Math.pow(2, gMap.getZoom() - 1) || 0,
    );
    newPoint = projection.fromPointToLatLng(
      new window.google.maps.Point(point1.x + point2.x, point1.y - point2.y),
    );

    bounds.extend(newPoint);

    gMap.fitBounds(bounds);
  }
}
