/**
interface FAQImg {
  type: "image";
  url: string;
}
interface FAQText {
  type: "text";
  question: string;
  answer; string;
}
type FAQData = {
  title: string;
  subtitle: string;
  content: (FAQImage | FAQText)[];
 */

export default {
  title: 'Vanliga frågor',
  subtitle: 'Redigering',
  content: [
    {
      type: 'text',
      question:
        'Hur ändrar jag ett Anslag från Omledningsanslag till Textanslag?',
      answer:
        'Välj Anslag du vill ändra, välj “redigering” och under fliken “Anslagstyp” väljer du den typ du vill använda: “Helkarta”, “Omledning” eller “Text”. Observer: detta kräver att licensen man använder har tillgång till alla slags anslagstyper. I vissa fall har man endast tillgång till “omledning” t.ex. ',
    },
    {
      type: 'text',
      question: 'Vad är “Anslagsstil” under redigering?',
      answer:
        'Anslagsstil är den layout av de olika anslagen som bestämts av användaren. Det kan vara olika bakgrundsfärger av Omledningsanslag, helkarta med eller utan logotyp, etc.',
    },
    {
      type: 'text',
      question: 'Hur skriver jag en rubrik i Omledningsanslaget/Textanslaget?',
      answer:
        'I översta rutan”Text” skriver du in önskad rubrik. Storleken på texten ändrar du i dropdown. Det går bara att ha en textstorlek per textruta.',
    },
    {
      type: 'text',
      question: 'Hur flyttar jag legendens position i kartan?',
      answer:
        'Legenden kan flyttas till valfritt hörn i kartan, det gör man genom att välja hörn i drop down “Legendens position”',
    },
    {
      type: 'text',
      question: 'Hur sparar jag ett anslag efter redigering?',
      answer:
        'Spara anslaget genom att trycka på knappen “Spara” längst ned i redigeringsläget. Om man glömmer att spara kommer ändå senaste redigeringarna sparas tillfälligt - ändringarna syns inte i förhandsvisningen men när man går in på Anslaget syns de senaste redigeringarna, både när det gäller text och karta. Vill man ångra redigeringarna tryck “Avbryt”.',
    },
    {
      type: 'text',
      question:
        'Varför stämmer inte utseendet på förhandsvisningen av Anslag med redigeringsläget?',
      answer:
        'Detta beror på att anslaget har redigerats men man har inte tryckt “Spara” eller “Avbryt” när man lämnat redigeringsläget. Man kan gå in i redigeringsläget och välja “Spara” för att senaste redigering skall gälla, eller tryck “Avbryt”.',
    },
  ],
};
