import React from 'react';
import styled from 'styled-components';

import file_system_faq from './file_system_faq';
import edit_faq from './edit_faq';
import map_faq from './map_faq';
import FaqImage from './FaqImage';
import FaqText from './FaqText';

/**
interface Props {
  onClose: () => void;
  app_section: "file_system" | "edit" | "map"
}
 */

const faq_data_by_section = {
  file_system: file_system_faq,
  map: map_faq,
  edit: edit_faq,
};

const Container = styled.div`
  z-index: 100000; //Necessary because the Folders in MainView have weird z-index.
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: grid;
  place-items: center;
  .close_btn {
    position: absolute;
    left: 50%;
    margin-left: 276px;
    top: calc(5% - 24px);
    background-color: #eb4d44;
    font-size: 22px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    color: white;
    display: grid;
    place-items: center;
  }
  .close_btn:hover {
    background-color: #ff866b;
  }
  .content {
    width: 600px;
    height: 90vh;
    background-color: white;
    cursor: default;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
      font-size: 20px;
      padding: 24px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .subtitle {
      margin-left: 10px;
      font-size: 18px;
      font-style: italic;
      color: rgb(150, 150, 150);
    }
    .inner_content {
      flex: 1;
      overflow: auto;
      padding: 24px 24px 0 24px;
    }
    .inner_content:after {
      content: '';
      height: 24px;
      width: 100%;
      display: inline-block;
    }
  }
`;

const FaqBase = ({ onClose, app_section }) => {
  const { title, subtitle, content } = faq_data_by_section[app_section];

  return (
    <Container onClick={() => onClose()}>
      <div
        className="content"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="close_btn" onClick={() => onClose()}>
          X
        </div>
        <div className="title">
          {title}
          <span className="subtitle">{subtitle}</span>
        </div>
        <div className="inner_content">
          {content.map((d, i) =>
            d.type === 'image' ? (
              <FaqImage key={i} url={d.url} />
            ) : (
              <FaqText key={i} question={d.question} answer={d.answer} />
            ),
          )}
        </div>
      </div>
    </Container>
  );
};

export default FaqBase;
