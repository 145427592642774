import * as spinner_reducer from './spinner_reducer';

export function add(state) {
  return {
    type: spinner_reducer.ADD_TO_SPINNER,
    payload: 1,
  };
}
export function remove(state) {
  return {
    type: spinner_reducer.ADD_TO_SPINNER,
    payload: -1,
  };
}
export function addSmall(state) {
  return {
    type: spinner_reducer.ADD_TO_SMALL_SPINNER,
    payload: 1,
  };
}
export function removeSmall(state) {
  return {
    type: spinner_reducer.ADD_TO_SMALL_SPINNER,
    payload: -1,
  };
}

export function addPreview(state) {
  return {
    type: spinner_reducer.ADD_TO_PREVIEW_SPINNER,
    payload: 1,
  };
}

export function removePreview(state) {
  return {
    type: spinner_reducer.ADD_TO_PREVIEW_SPINNER,
    payload: -1,
  };
}
