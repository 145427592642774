import React from 'react';
const IconDown = require('react-icons/lib/md/more-horiz');
const IconClose = require('react-icons/lib/md/close');

export default class FileFolderDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      render_dropdown: false,
    };
  }

  render() {
    return (
      <this.props.styledDiv className={`item_options`} size={this.props.size}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ render_dropdown: false });
          }}
          className={`doc_dropdown_blur ${
            this.state.render_dropdown ? 'active' : ''
          }`}
        ></div>
        <div
          className={`file_dropdown ${
            this.state.render_dropdown ? 'active' : ''
          }`}
        >
          <ul>
            {this.props.dropdown_options
              ? this.props.dropdown_options.map((item, i) => {
                  return (
                    <li
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ render_dropdown: false });
                        item.fn();
                      }}
                    >
                      <div className="create_icon"></div>
                      <div className="create_label">{item.label}</div>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
        <div
          className={`filde_dropdown_icon ${
            this.state.render_dropdown ? 'active' : ''
          }`}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ render_dropdown: !this.state.render_dropdown });
          }}
        >
          {this.state.render_dropdown ? <IconClose /> : <IconDown />}
        </div>
      </this.props.styledDiv>
    );
  }
}
