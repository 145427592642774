import React from 'react';
import styled from 'styled-components';
import FileFolderDropdown from './FileFolderDropdown';
import oh from 'output-helpers';
import { DragSource } from 'react-dnd';
import { ItemTypes } from '../../DnDItemTypes';

const Container = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;
  .thumbnail {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    width: ${(props) => props.theme.sizes.doc.small.width + 'px'};
    height: ${(props) => props.theme.sizes.doc.small.height + 'px'};
    opacity: ${(props) => (props.isDragging ? '0' : 1)};
  }
  &:hover {
    .doc_name {
      opacity: 0.9;
    }
  }
  .document_specification {
    position: relative;
  }
  .doc_name {
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    color: ${(props) => (props.dark_bg ? '#fff' : props.theme.colors.text)};
    margin-top: 14px;
    font-weight: 700;
    opacity: 0.6;
    text-align: center;
    font-size: 12px;
    p {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

class DocThumbnail extends React.Component {
  getDocWidth(props) {
    switch (props.size) {
      case 'medium':
        return props.theme.sizes.doc.medium.width + 'px';
      default:
        return props.theme.sizes.doc.small.width + 'px';
    }
  }

  render() {
    const dropdown_options = [
      {
        label: oh.translate('edit_details'),
        fn: () => {
          if (this.props.editMetaCB) {
            this.props.editMetaCB();
          }
        },
      },
      {
        label: oh.translate('remove'),
        fn: () => {
          if (this.props.deleteDocCB) {
            this.props.deleteDocCB();
          }
        },
      },
      {
        label: oh.translate('to_clone'),
        fn: () => {
          if (this.props.cloneDocCB) {
            this.props.cloneDocCB();
          }
        },
      },
      {
        label: oh.translate('archive'),
        fn: () => {
          if (this.props.archiveDocCB) {
            this.props.archiveDocCB();
          }
        },
      },
    ];
    const archived_dropdown_options = [
      //TODO: This should be available for 'admins' only.
      // {
      //     label: oh.translate('remove'),
      //     fn: () => {
      //         if (this.props.deleteDocCB) {
      //             this.props.deleteDocCB();
      //         }
      //     }
      // },
      {
        label: oh.translate('to_clone'),
        fn: () => {
          if (this.props.cloneDocCB) {
            this.props.cloneDocCB();
          }
        },
      },
    ];

    const { connectDragSource, isDragging } = this.props;
    let img_src = 'data:image/png;base64, ' + this.props.doc.tn;
    // let img_src = "data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==";
    return connectDragSource(
      <div className="doc_thumbnail_container">
        <Container
          isDragging={isDragging}
          className={`doc_preview`}
          centered={this.props.centered}
          dark_bg={this.props.dark_bg}
          onClick={() => (this.props.onClick ? this.props.onClick() : null)}
          size={this.props.size}
          style={this.props.css_style}
        >
          <div
            className="thumbnail"
            style={{ backgroundImage: "url('" + img_src + "')" }}
          >
            {this.props.doc.archived ? (
              <FileFolderDropdown
                styledDiv={this.props.fileFolderDropDownDiv}
                size={this.props.size}
                dropdown_options={archived_dropdown_options}
              />
            ) : (
              <FileFolderDropdown
                styledDiv={this.props.fileFolderDropDownDiv}
                size={this.props.size}
                dropdown_options={dropdown_options}
              />
            )}
            <div className="doc_name">
              <p>{this.props.name}</p>
            </div>
          </div>
        </Container>
      </div>,
    );
  }
}

const thumbnailSource = {
  beginDrag(props) {
    return { type: 'document', item: props.doc };
  },
  canDrag(props) {
    return props.static ? false : true;
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export default DragSource(
  ItemTypes.DOCUMENT,
  thumbnailSource,
  collect,
)(DocThumbnail);
