import { a4_portrait } from '../standard';
import Graphic_customer_vl from '../../img/customer_graphics_vl_logo.svg';

let vl_red = 'rgb(199, 1, 40)';

export default {
  default: {
    OrganizationProfile: `
            `,
    RichText: `
                &.type_title {
                }
            `,
    Text:
      `&.vl_default_v2 {
        white-space: pre-wrap;
          & .weight_bold {
            font-weight: bold;
          }
          & .align_left {
            text-align: left;
          }
          & .align_right {
            text-align: right;
          }
          & .align_center {
            text-align: center;
          }
          & .italic {
            font-style: italic;
          }
          & .text_small, & .text_medium, & .text_large {
                position: relative;
                text-align: left;
                margin: 0;
                font-size: 24px;
                padding: 42px;
          }
          & .text_medium {
            font-size: 32px;
          }
          & .text_large {
            font-size: 40px;
          }
          & .title_small, & .title_medium, & .title_large {
            background-color: ` +
      vl_red +
      `;
                            color: #fff;
                            font-size: 168px;
                            font-weight: bold;
                            padding: 0 0 50px;
                            width: 100%;
                            margin: 0;
          }
          & .title_medium {
            font-size: 180px;
          }
          & .title_large {
            font-size: 200px;
          }
          & .subtitle {
            background-color: ` +
      vl_red +
      `;
                            color: #fff;
                            font-size: 48px;
                            padding: 0 0 50px;
                            width: 100%;
                            margin: 0;
          }
      }`,
  },
  templates: [
    {
      id: 'vl_default',
      name: 'VL vit A4',
      types: ['redirect', 'text_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_vl +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            right: 30px;
                            bottom: 30px;
                            width: 400px;
                            height: 400px;
                            z-index: 3;
                        }
                        .layer_2{
                            position: absolute;
                            bottom: 60px;
                            left: 60px;
                            right: 60px;
                            display: block;
                            height: 524px;
                            background-color: #fff;
                            border-radius: 0 0 500px 0;
                            z-index: 1;
                        }
                        .layer_3{
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: block;
                            z-index: 1;
                            border: 60px solid ` +
          vl_red +
          `;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          vl_red +
          `;
                        width: 1240px;
                        height: 1754px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                    `,
        RichText:
          `
                        &.type_title {
                            background-color: ` +
          vl_red +
          `;
                            color: #fff;
                            font-size: 180px;
                            font-weight: bold;
                            padding: 0 0 50px;
                            width: 100%;
                            margin: 0;
                            h2{
                                font-size: 60px;
                                font-weight: bold;
                            }
                        }
                        &.type_body {
                            position: relative;
                            text-align: left;
                            margin: 0;
                            font-size: 46px;
                            padding: 42px;
                        }
                    `,
      },
    },
    {
      id: 'vl_default_v2',
      name: 'VL vit A4',
      types: ['redirect_v2', 'text_only_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        OrganizationProfile:
          `
                        position: unset;
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_vl +
          `);
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            z-index: 1;
                            display: block;
                            position: absolute;
                            right: 30px;
                            bottom: 30px;
                            width: 400px;
                            height: 400px;
                            z-index: 3;
                        }
                        .layer_2{
                            position: absolute;
                            bottom: 60px;
                            left: 60px;
                            right: 60px;
                            display: block;
                            height: 524px;
                            background-color: #fff;
                            border-radius: 0 0 500px 0;
                            z-index: 1;
                        }
                        .layer_3{
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: block;
                            z-index: 1;
                            border: 60px solid ` +
          vl_red +
          `;
                        }
                    `,
        document_specification:
          `
                        background-color: ` +
          vl_red +
          `;
                        width: 1240px;
                        height: 1754px;
                        padding: 60px;
                        position: relative;
                    `,
        Map: `
                        height: 700px;
                        position: relative;
                        div.inner {
                            height: 700px;
                            width: 100%;
                        }
                    `,
      },
    },
  ],
};
