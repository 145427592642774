import React from 'react';
import styled from 'styled-components';

import { Add, Remove, ArrowUp, ArrowDown } from '../../../../img/icons';

/**
interface Props {
  onRemove?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onCreate?: () => void;
}
 */

const Container = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  position: relative;
  width: ${(props) => (props.block ? '100%' : 'unset')};
  & .innerContainer {
    border: 1px solid #ced9ee;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
  }
  & .innerContainer:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Button = styled.div`
  border-left: 1px solid #ced9ee;
  padding: 8px;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    & svg {
      fill: lightgrey;
    }
  `}
  &:hover {
    & svg {
      fill: #5d92f1;
    }
  }
  &.remove {
    background-color: ${(props) => props.theme.colors.red[1]};
    & svg {
      fill: #ffffff;
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.red[3]};
    }
  }
  &:first-child {
    border-left: none;
  }
`;

const ParagraphOrderingControls = ({
  onRemove,
  onMoveUp,
  onMoveDown,
  onCreate,
}) => {
  return (
    <Container>
      <div className="innerContainer">
        <Button
          disabled={!onMoveUp}
          className="button"
          onClick={() => onMoveUp && onMoveUp()}
        >
          <ArrowUp />
        </Button>

        <Button
          disabled={!onMoveDown}
          className="button"
          onClick={() => onMoveDown && onMoveDown()}
        >
          <ArrowDown />
        </Button>
        <Button
          disabled={!onCreate}
          className="button"
          onClick={() => onCreate && onCreate()}
        >
          <Add />
        </Button>
        <Button
          disabled={!onRemove}
          className="button remove"
          onClick={() => onRemove && onRemove()}
        >
          <Remove />
        </Button>
      </div>
    </Container>
  );
};

export default ParagraphOrderingControls;
