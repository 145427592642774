import * as types from './open_reducer';

export function setOpen(open_data, fetched = false, state) {
  return {
    type: types.SET_OPEN,
    payload: {
      fetched: fetched,
      open: Object.keys(open_data).map((bulletin_id) => {
        return {
          id: parseInt(bulletin_id, 10),
          users: open_data[bulletin_id],
        };
      }),
    },
  };
}

export function updateOpen(open_data, state) {
  return {
    type: types.UPDATE_OPEN,
    payload: {
      id: parseInt(open_data.bulletin_id, 10),
      users: open_data.users,
    },
  };
}

export function deleteOpen(open_data, state) {
  return {
    type: types.DELETE_OPEN,
    payload: {
      id: parseInt(open_data.bulletin_id, 10),
      user_id: open_data.users[0].user_id,
    },
  };
}
