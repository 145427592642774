import React from 'react';
import oh from 'output-helpers';
import FileSystem from './FileSystem';

const SearchResultList = ({ results, search_string, navigate }) => {
  if (search_string.length < 2) {
    return (
      <div className="main_file_system">
        {
          <div className="main_symbol">
            <div className="image empty_search"></div>
            <div className="label">
              <p className="title">{oh.translate('too_few_characters')}</p>
              <p className="subtitle">
                {oh.translate('at_least_two_characters_to_search')}
              </p>
            </div>
          </div>
        }
      </div>
    );
  } else if (results.length === 0) {
    return (
      <div className="main_file_system">
        {
          <div className="main_symbol">
            <div className="image empty_search"></div>
            <div className="label">
              <p className="title">
                {oh.translate('empty_result')}: <span>"{search_string}"</span>
              </p>
              <p className="subtitle">
                {oh.translate('empty_result_try_other')}
              </p>
            </div>
          </div>
        }
      </div>
    );
  }

  const archived = results.filter((x) => x.archived === true);
  archived.splice(16);
  const non_archived = results.filter((x) => !x.archived);
  non_archived.splice(16);

  return (
    <>
      {non_archived.length > 0 ? (
        <FileSystem
          folder_content={{
            children: [],
            docs: non_archived,
            name: 'search results',
            parent_id: undefined,
            logs: [],
            is_root: true,
          }}
          navigate={navigate}
          first_crumb_label={oh.translate('search_result') + ' /'}
          static={true}
        />
      ) : null}
      {archived.length > 0 ? (
        <FileSystem
          folder_content={{
            children: [],
            docs: archived,
            name: 'archived search results',
            parent_id: undefined,
            logs: [],
            is_root: true,
          }}
          navigate={navigate}
          first_crumb_label={oh.translate('archived_search_result') + ' /'}
          static={true}
        />
      ) : null}
    </>
  );
};

export default SearchResultList;
