import { a4_portrait } from '../standard';
import Graphic_customer_samtrafiken_footer from '../../img/customer/samtrafiken_footer.svg';

const samtrafiken_config = {
  colors: {
    green: '#4FAC3B',
    gray: '#6D6E6D',
  },
  footer_effective_height: 100,
};

export default {
  default: {
    document_specification:
      `
                height: ` +
      a4_portrait.height +
      `px;
                width: ` +
      a4_portrait.width +
      `px;
                background-color: #fff;
                padding: 0;
                position: relative;
                overflow: hidden;
            `,
    OrganizationProfile:
      `
                position: unset;
                .layer_1 {
                    background-image: url(` +
      Graphic_customer_samtrafiken_footer +
      `);
                    background-repeat: no-repeat;
                    background-position: bottom;
                    background-size: contain;
                    display: block;
                    position: fixed;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 400px;
                    z-index: 3;
                }
            `,
    Map: `
                height: 900px;
                position: relative;
                div.inner {
                    height: 900px;
                    width: 100%;
                }
            `,
    RichText:
      `
                &.type_title {
                    padding: 54px 60px 50px;
                    width: 100%;
                    margin: 0;
                    h1 {
                        font-family: "Trade Gothic LT W01 Bold", "Helvetica", sans-serif;
                        color: ` +
      samtrafiken_config.colors.green +
      `;
                        font-size: 96px;
                        font-weight: bold;
                        margin-bottom: 28px;
                    }
                    h2 {
                        font-family: "Trade Gothic LT W01 Roman", "Helvetica", sans-serif;
                        color: ` +
      samtrafiken_config.colors.gray +
      `;
                        font-size: 66px;
                        font-weight: normal;
                    }
                }
                &.type_body {
                    font-family: "OriginalGaramondW01-Rom", "Helvetica", sans-serif;
                    position: relative;
                    font-size: 32px;
                    text-align: left;
                    margin: 0;
                    padding: 0 128px 0;
                    p, span {
                        font-family: "OriginalGaramondW01-Rom", "Helvetica", sans-serif !important;
                    }
                }
            `,
  },
  templates: [
    {
      id: 'samtrafiken_redirect',
      name: 'DEMO Vit A4',
      types: ['redirect', 'text_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {},
    },
    {
      id: 'samtrafiken_redirect_green',
      name: 'DEMO RED',
      types: ['redirect_2', 'text_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        document_specification: `
                        background-color: #fff;
                        color: #000;
                    `,
        OrganizationProfile:
          `
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_samtrafiken_footer +
          `);
                        }
                    `,
        RichText: `
                        &.type_title {
                            color: #000;
                            h1 {
                                color: #000;
                            }
                            h2 {
                                color: #8A8A89;
                            }
                        }
                        &.type_body {
                            color: #000;
                            p, span {
                                color: #000;
                                margin: 28px 0;
                            }
                        }
                    `,
      },
    },
    {
      id: 'DEMO_MAP',
      name: 'DEMO MAP',
      types: ['map_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        Map:
          `
                        height: ` +
          a4_portrait.height +
          `px;
                        position: relative;
                        &.fullescreen_true{
                            height: ` +
          (a4_portrait.height - samtrafiken_config.footer_effective_height) +
          `px;
                        }
                        div.inner {
                            height: ` +
          a4_portrait.height +
          `px;
                            width: 100%;
                        }
                    `,
      },
    },
    {
      id: 'DEM',
      name: 'Helkarta A4',
      types: ['map_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        Map:
          `
                        height: ` +
          a4_portrait.height +
          `px;
                        position: relative;
                        &.fullescreen_true{
                            height: ` +
          a4_portrait.height +
          `px;
                        }
                        div.inner {
                            height: ` +
          a4_portrait.height +
          `px;
                            width: 100%;
                        }
                    `,
        OrganizationProfile: `
                        display: none !important;
                    `,
      },
    },
  ],
};
