import React from 'react';
import styled from 'styled-components';
import withStore from 'with-store';
import SymbolFetchingDoc from '../../img/SymbolFetchingDoc.svg';

const Container = styled.div`
  display: inline-block;
  position: relative;
  background-color: ${(props) => props.theme.colors.dark[1]};
  width: ${(props) => props.actual_size.width};
  height: ${(props) => props.actual_size.height};

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &.doc_preview_render {
    background-image: ${(props) =>
      props.preview_image
        ? 'url(' + URL.createObjectURL(props.preview_image) + ')'
        : 'url(' + SymbolFetchingDoc + ')'};
  }
  &.doc_preview_spinner {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    position: absolute !important;
    text-align: center;
    .lds-ring {
      display: inline-block;
      position: relative;
      width: 200px;
      height: 200px;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 201px;
      height: 201px;
      margin-top: 201px;
      border: 22px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .thumbnail {
    border-radius: 6px;
    position: relative;
    width: ${(props) => props.theme.sizes.doc.small.width + 'px'};
    height: ${(props) => props.theme.sizes.doc.small.height + 'px'};
    background-color: #abaaaa;
    opacity: ${(props) => (props.isDragging ? '0' : 1)};
  }
  &:hover {
    .doc_name {
      opacity: 0.9;
    }
  }
  .document_specification {
    position: relative;
  }
  .doc_name {
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    color: ${(props) => (props.dark_bg ? '#fff' : props.theme.colors.text)};
    margin-top: 14px;
    font-weight: 700;
    opacity: 0.6;
    text-align: center;
    font-size: 12px;
    p {
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

class DocPreview extends React.Component {
  constructor(props) {
    super(props);

    this.ContainerSpiner = styled.div``;
  }

  shouldComponentUpdate(next_props, next_state) {
    if (this.props.store.image.preview !== next_props.store.image.preview) {
      return true;
    }
    if (this.props.css_style !== next_props.css_style) {
      return true;
    }
    if (
      this.props.store.spinner.preview_count !==
      next_props.store.spinner.preview_count
    ) {
      return true;
    }
    return false;
  }

  getDocWidth(props) {
    switch (props.size) {
      case 'medium': {
        return props.theme.sizes.doc.medium.width + 'px';
      }
      default: {
        return props.theme.sizes.doc.small.width + 'px';
      }
    }
  }

  render() {
    let style = withStore.extensions.getStyle(this.props.style_id);
    if (!style) {
      throw new Error(
        "Styling missing for style_id '" +
          this.props.style_id +
          "'. Cannot open DocPreview.",
      );
    }
    let actual_size = {
      width: style.width + 'px',
      height: style.height + 'px',
    };

    return (
      <div className="doc_preview_container">
        <Container
          className={`doc_preview doc_preview_render`}
          centered={this.props.centered}
          preview_image={this.props.store.image.preview}
          dark_bg={this.props.dark_bg}
          onClick={() => (this.props.onClick ? this.props.onClick() : null)}
          size={this.props.size}
          actual_size={actual_size}
          style={this.props.css_style}
        ></Container>
        {this.props.store_ext.showPreviewSpinner() === true ? (
          <Container
            className={`doc_preview doc_preview_spinner`}
            centered={this.props.centered}
            size={this.props.size}
            actual_size={actual_size}
            style={this.props.css_style}
          >
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Container>
        ) : null}
      </div>
    );
  }
}

export default withStore(DocPreview);
