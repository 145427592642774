import React from 'react';

import PromptModal from './PromptModal';
// import DateInput from './DateInput';
// import NumberInput from './NumberInput';
// import SelectInput from './SelectInput';
import TextInput from './TextInput';

let custom_components = null;
function setCustomComponents(object_with_components) {
  custom_components = object_with_components;
}

///////////////////////////
// HOC
let hoc = (WrappedComponent) => {
  return class confirmationHOC extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        show: false,
        modal_props: {},
        values: [],
      };
      this.exports = {
        prompt: (prompt_request, confirmCB, cancelCB) => {
          this.initPrompt(
            prompt_request.inputs,
            prompt_request.props,
            confirmCB,
            cancelCB,
          );
        },
        cancel: () => {
          this.cancelRequest();
        },
      };
      this.confirmCB = null;
      this.cancelCB = null;

      this.input_components = {
        // date: DateInput,
        // number: NumberInput,
        // select: SelectInput,
        text: TextInput,
      };
    }

    initPrompt(inputs, props, confirmCB, cancelCB) {
      this.confirmCB = confirmCB || null;
      this.cancelCB = cancelCB || null;
      let values = inputs.map((input) => input.default_value);
      this.setState({
        show: true,
        modal_props: props,
        inputs: inputs,
        values: values,
      });
    }

    cancelRequest() {
      this.confirmCB = null;
      this.cancelCB = null;
      this.setState({
        show: false,
        inputs: [],
        values: [],
      });
    }

    userConfirmedCB() {
      this.setState({
        show: false,
      });
      if (this.confirmCB) {
        this.confirmCB(this.state.values);
        this.confirmCB = null;
        this.cancelCB = null;
      }
    }
    userCancelledCB() {
      this.setState({
        show: false,
      });
      if (this.cancelCB) {
        this.cancelCB();
        this.cancelCB = null;
        this.confirmCB = null;
      }
    }

    inputValueChangeCB(index, value) {
      let all_values = [].concat(this.state.values);
      all_values[index] = value;
      this.setState({
        values: all_values,
      });
    }

    renderInputs() {
      if (!this.state.inputs) {
        return null;
      }

      return this.state.inputs.map((input_request, index) => {
        let InputComponent = this.input_components[input_request.type];
        if (
          custom_components &&
          custom_components.hasOwnProperty(input_request.type)
        ) {
          InputComponent = custom_components[input_request.type];
        }
        return (
          <InputComponent
            key={index}
            modalConfirm={() => this.userConfirmedCB()}
            request={input_request}
            value={this.state.values[index]}
            onChange={(value) => {
              this.inputValueChangeCB(index, value);
            }}
          />
        );
      });
    }

    renderPrompt() {
      if (!this.state.show) {
        return null;
      }
      let Modal = PromptModal;
      if (custom_components && custom_components.hasOwnProperty('modal')) {
        Modal = custom_components.modal;
      }
      return (
        <Modal
          confirmCB={() => {
            this.userConfirmedCB();
          }}
          cancelCB={() => {
            this.userCancelledCB();
          }}
          renderInputs={() => {
            return this.renderInputs();
          }}
          {...this.state.modal_props}
        />
      );
    }

    render() {
      return (
        <div>
          <WrappedComponent UserPrompt={this.exports} {...this.props} />
          {this.renderPrompt()}
        </div>
      );
    }
  };
};

hoc.setCustomComponents = setCustomComponents;

export default hoc;
