import SockJS from 'sockjs-client';

let self = {
  ready: false,
  all_connected: false,
  allow_reconnect: false,
  paused: true,
  config: {},
  connections: {},
  inspectors: {},
  listeners: {},
  event_queue: [],
};

function flush() {
  self.event_queue = [];
}

function pause() {
  console.log('[Notifications] pause');
  self.paused = true;
}

function unpause() {
  console.log('[Notifications] unpause');
  self.paused = false;

  self.event_queue.forEach((event) => {
    emit(event);
  });
  self.event_queue = [];
}

function emit(event) {
  if (self.inspectors.hasOwnProperty(event.type)) {
    self.inspectors[event.type].forEach((inspector) => {
      inspector.fn(event);
    });
  }

  if (self.listeners.hasOwnProperty(event.type)) {
    self.listeners[event.type].forEach((listener) => {
      listener.fn(event);
    });
  }
}

function connectOne(url, conn) {
  console.log('[Notifications] connecting to ' + url);

  let sock = new SockJS(url);
  conn.sock = sock;

  conn.timeout = setTimeout(() => {
    if (conn.sock) {
      conn.sock.close();
      conn.sock = null;
    }
  }, self.config.connect_timeout);

  sock.onopen = () => {
    console.log('[Notifications] connected to ' + url);
    sock.send(window.sso.getJWT().getRaw());

    conn.reconnect_delay = 0;
    if (conn.timeout) {
      clearTimeout(conn.timeout);
      conn.timeout = null;
    }

    let live_connections = Object.keys(self.connections).reduce((a, c) => {
      return (
        a + (self.connections[c].sock && !self.connections[c].timeout ? 1 : 0)
      );
    }, 0);
    if (live_connections === self.config.urls.length) {
      if (self.all_connected === false) {
        self.all_connected = true;
        pause();
        flush();
        if (self.ready === false) {
          if (self.config.store) {
            self.config.store.actions.interaction.set({
              notifications_ready: true,
            });
          }
          self.ready = true;
        }
        emit({ type: 'connected', data: null });
      }
    }
  };

  sock.onclose = () => {
    console.log('[Notifications] disconnected from ' + url);
    conn.sock = null;

    if (conn.timeout) {
      clearTimeout(conn.timeout);
      conn.timeout = null;
    }

    let live_connections = Object.keys(self.connections).reduce((a, c) => {
      return (
        a + (self.connections[c].sock && !self.connections[c].timeout ? 1 : 0)
      );
    }, 0);
    if (live_connections < self.config.urls.length) {
      if (self.all_connected === true) {
        emit({ type: 'disconnected', data: null });
      }
    }
    self.all_connected = false;

    if (self.allow_reconnect) {
      console.log(
        '[Notifications] reconnecting to ' +
          url +
          ' in ' +
          Math.floor(conn.reconnect_delay / 1000) +
          ' seconds ...',
      );
      setTimeout(() => {
        connectOne(url, conn);
      }, conn.reconnect_delay);

      if (conn.reconnect_delay === 0) {
        conn.reconnect_delay = 1000;
      } else {
        conn.reconnect_delay = Math.min(
          conn.reconnect_delay * 2,
          self.config.reconnect_max_delay,
        );
      }
    }
  };

  sock.onmessage = (msg) => {
    if (self.all_connected) {
      // console.log("NOTIFICATION >> ", msg.data);
      let event = JSON.parse(msg.data);
      if (self.paused) {
        self.event_queue.push(event);
      } else {
        emit(event);
      }
    }
  };
}

function disconnect() {
  self.allow_reconnect = false;

  Object.keys(self.connections).forEach((url) => {
    if (self.connections[url].sock) {
      self.connections[url].sock.close();
    }
  });

  self.connections = {};
}

function connect() {
  console.log('[Notifications] connecting...');
  if (!self.config.urls || self.config.urls.length === 0) {
    return;
  }

  self.allow_reconnect = true;

  self.config.urls.forEach((url) => {
    self.connections[url] = {
      reconnect_delay: 0,
      sock: null,
      timeout: null,
    };
    connectOne(url, self.connections[url]);
  });
}

function setConfig(config) {
  let defaults = {
    connect_timeout: 10000,
    reconnect_max_delay: 16000,
  };

  self.config = Object.assign({}, defaults, config);

  console.log('[Notifications] config:', self.config);

  if (self.config.store) {
    self.config.store.actions.interaction.set({ notifications_ready: false });
  }

  if (self.config.urls && self.config.urls.length > 0) {
    connect();
  }
}

function addListener(ref, event_type, cb) {
  if (!self.listeners.hasOwnProperty(event_type)) {
    self.listeners[event_type] = [];
  }

  console.log('[Notifications] added listener for: ' + event_type);
  self.listeners[event_type].push({ ref: ref, fn: cb });
}

function clearListeners(ref) {
  console.log('[Notifications] cleared listeners');
  if (!ref) {
    self.listeners = {};
    return;
  }
  Object.keys(self.listeners).forEach((event_type) => {
    self.listeners[event_type] = self.listeners[event_type].filter(
      (x) => x.ref !== ref,
    );
  });
}

function addInspector(ref, event_type, cb) {
  if (!self.inspectors.hasOwnProperty(event_type)) {
    self.inspectors[event_type] = [];
  }

  self.inspectors[event_type].push({ ref: ref, fn: cb });
}

function clearInspectors(ref) {
  if (!ref) {
    self.inspectors = {};
    return;
  }
  Object.keys(self.inspectors).forEach((event_type) => {
    self.inspectors[event_type] = self.inspectors[event_type].filter(
      (x) => x.ref !== ref,
    );
  });
}

export default {
  unpause: unpause,
  disconnect: disconnect,
  setConfig: setConfig,
  addEventListener: addListener,
  clearEventListeners: clearListeners,
  addEventInspector: addInspector,
  clearEventInspectors: clearInspectors,
};
