import { a4_portrait, a3_portrait } from '../../standard';
import Graphic_customer_jlt_footer_a4__red from '../../../img/customer/jlt_footer_a4--red.svg';
import Graphic_customer_jlt_footer_a4__white from '../../../img/customer/jlt_footer_a4--white.svg';

const jlt_config = {
  colors: {
    red: '#D82332',
    gray: '#6D6E6D',
  },
  footer_effective_height: 100,
  footer_effective_height_a3: 132,
};

export default {
  default: {
    document_specification: `height: ${a4_portrait.height}px;
      width: ${a4_portrait.width}px;
      background-color: #fff;
      padding: 0;
      position: relative;
      overflow: hidden;
    `,
    OrganizationProfile: `
      position: unset;
      .layer_1 {
        background-image: url(${Graphic_customer_jlt_footer_a4__red});
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        display: block;
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 400px;
        z-index: 3;
      }
    `,
    Map: `
      height: 900px;
      position: relative;
      div.inner {
        height: 900px;
        width: 100%;
      }
    `,
    Text: `
      &.jlt_redirect_white_a4_v2, &.jlt_redirect_red_a4_v2, &.jlt_redirect_white_a3, &.jlt_redirect_red_a3 {
        white-space: pre-wrap;
        word-wrap: break-word;
        & .weight_bold {
          font-weight: bold;
        }
        & .align_left {
          text-align: left;
        }
        & .align_right {
          text-align: right;
        }
        & .align_center {
          text-align: center;
        }
        & .italic {
          font-style: italic;
        }
        & .text_small, & .text_medium, & .text_large {
          font-family: "OriginalGaramondW01-Rom", "Helvetica", sans-serif;
          position: relative;
          font-size: 24px;
          margin: 0;
          padding: 0 128px 0;
        }
        & .text_medium {
          font-size: 32px;
        }
        & .text_large {
          font-size: 40px;
        }
        & .title_small, & .title_medium, & .title_large {
          font-family: "Trade Gothic LT W01 Bold", "Helvetica", sans-serif;
          color: ${jlt_config.colors.red};
          font-size: 64px;
          font-weight: 900;
          padding: 54px 128px 50px;
          width: 100%;
          margin: 0;
        }
        & .title_medium {
          font-size: 96px;
        }
        & .title_large {
          font-size: 128px;
        }
        & .subtitle {
          font-family: "Trade Gothic LT W01 Roman", "Helvetica", sans-serif;
          color: ${jlt_config.colors.gray};
          font-size: 48px;
          padding: 8px 128px 32px;
          width: 100%;
          margin: 0;
        }
      }
      &.jlt_redirect_red_a4_v2, &.jlt_redirect_red_a3 {
        & .title_small, & .title_medium, & .title_large {
          color: #fff;
        }
        & .text_small, & .text_medium, & .text_large {
          color: #fff;
        }
        & .subtitle {
          color: #fff;
        }
      }
    `,
    RichText: `
      &.type_title {
        padding: 54px 128px 50px;
        width: 100%;
        margin: 0;
        h1 {
          font-family: "Trade Gothic LT W01 Bold", "Helvetica", sans-serif;
          color: ${jlt_config.colors.red};
          font-size: 96px;
          font-weight: bold;
          margin-bottom: 28px;
        }
        h2 {
          font-family: "Trade Gothic LT W01 Roman", "Helvetica", sans-serif;
          color: ${jlt_config.colors.gray};
          font-size: 66px;
          font-weight: normal;
        }
      }
      &.type_body {
        font-family: "OriginalGaramondW01-Rom", "Helvetica", sans-serif;
        position: relative;
        font-size: 32px;
        text-align: left;
        margin: 0;
        padding: 0 128px 0;
        p, span {
          font-family: "OriginalGaramondW01-Rom", "Helvetica", sans-serif !important;
        }
      }
    `,
  },
  templates: [
    {
      id: 'jlt_redirect_white_a4',
      name: 'Vit A4',
      types: ['redirect_2', 'text_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {},
    },
    {
      id: 'jlt_redirect_red_a4',
      name: 'Röd A4',
      types: ['redirect_2', 'text_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        document_specification:
          `
                        background-color: ` +
          jlt_config.colors.red +
          `;
                        color: #fff;
                    `,
        OrganizationProfile:
          `
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_jlt_footer_a4__white +
          `);
                        }
                    `,
        RichText: `
                        &.type_title {
                            color: #fff;
                            h1, h2 {
                                color: #fff;
                            }
                        }
                        &.type_body {
                            color: #fff;
                            p, span {
                                color: #fff;
                            }
                        }
                    `,
      },
    },

    {
      id: 'jlt_redirect_white_a4_v2',
      name: 'Vit A4',
      types: ['redirect_2_v2', 'text_only_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {},
    },
    {
      id: 'jlt_redirect_red_a4_v2',
      name: 'Röd A4',
      types: ['redirect_2_v2', 'text_only_v2'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        document_specification:
          `
                        background-color: ` +
          jlt_config.colors.red +
          `;
                        color: #fff;
                    `,
        OrganizationProfile:
          `
                        .layer_1 {
                            background-image: url(` +
          Graphic_customer_jlt_footer_a4__white +
          `);
                        }
                    `,
      },
    },
    //A3
    {
      id: 'jlt_redirect_white_a3',
      name: 'Vit A3',
      types: ['redirect_2_v2', 'text_only_v2'],
      version: 1,
      big: true,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        MapLegend: `
        padding: 8px 11px;
        border: 2px solid #000;
        border-radius: 11px;
        font-size: 22px;
        &.bottom_left {
          bottom: 28px;
          left: 17px;
        }
        &.bottom_right {
          bottom: 28px;
          right: 17px;
        }
        &.top_left {
          top: 28px;
          left: 17px;
        }
        &.top_right {
          top: 28px;
          right: 17px;
        }
        ul {
          li {
            margin-bottom: 3px;
            .legend_item_label {
              padding-left: 14px;
            }
            &:hover {
              .legend_item_label {
                &:after {
                  font-size: 19px;
                }
              }
            }
          }
        }
        .placeholder {
          width: 45px;
          height: 45px;
          & svg {
            width: 45px;
            height: 45px;
          }
        }
        `,
        document_specification: `height: ${a3_portrait.height}px;
      width: ${a3_portrait.width}px;
    `,
        OrganizationProfile: `
      .layer_1 {
        height: 565px;
      }
    `,
        Map: `
      height: 1273px;
      div.inner {
        height: 1273px;
      }
    `,
        Text: `
      &.jlt_redirect_white_a3 {
        & .text_small, & .text_medium, & .text_large {
          font-size: 34px;
          padding: 0 181px 0;
        }
        & .text_medium {
          font-size: 45px;
        }
        & .text_large {
          font-size: 56px;
        }
        & .title_small, & .title_medium, & .title_large {
          font-size: 90px;
          padding: 76px 181px 71px;
        }
        & .title_medium {
          font-size: 136px;
        }
        & .title_large {
          font-size: 181px;
        }
        & .subtitle {
          font-size: 67px;
          padding: 11px 181px 45px;
        }
      }
    `,
      },
    },
    {
      id: 'jlt_redirect_red_a3',
      name: 'Röd A3',
      types: ['redirect_2_v2', 'text_only_v2'],
      big: true,
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        MapLegend: `
        padding: 8px 11px;
        border: 2px solid #000;
        border-radius: 11px;
        font-size: 22px;
        &.bottom_left {
          bottom: 28px;
          left: 17px;
        }
        &.bottom_right {
          bottom: 28px;
          right: 17px;
        }
        &.top_left {
          top: 28px;
          left: 17px;
        }
        &.top_right {
          top: 28px;
          right: 17px;
        }
        ul {
          li {
            margin-bottom: 3px;
            .legend_item_label {
              padding-left: 14px;
            }
            &:hover {
              .legend_item_label {
                &:after {
                  font-size: 19px;
                }
              }
            }
          }
        }
        .placeholder {
          width: 45px;
          height: 45px;
          & svg {
            width: 45px;
            height: 45px;
          }
        }
        `,
        document_specification:
          `
          height: ${a3_portrait.height}px;
      width: ${a3_portrait.width}px;
                        background-color: ` +
          jlt_config.colors.red +
          `;
                        color: #fff;
                    `,
        OrganizationProfile:
          `
                        .layer_1 {
                          height: 565px;
                            background-image: url(` +
          Graphic_customer_jlt_footer_a4__white +
          `);
                        }
                    `,
        Map: `
                    height: 1273px;
                    div.inner {
                      height: 1273px;
                    }
                  `,
        Text: `
                    &.jlt_redirect_red_a3 {
                      & .text_small, & .text_medium, & .text_large {
                        font-size: 34px;
                        padding: 0 181px 0;
                      }
                      & .text_medium {
                        font-size: 45px;
                      }
                      & .text_large {
                        font-size: 56px;
                      }
                      & .title_small, & .title_medium, & .title_large {
                        font-size: 90px;
                        padding: 76px 181px 71px;
                      }
                      & .title_medium {
                        font-size: 136px;
                      }
                      & .title_large {
                        font-size: 181px;
                      }
                      & .subtitle {
                        font-size: 67px;
                        padding: 11px 181px 45px;
                      }
                    }
                  `,
      },
    },

    //////////
    //MAP ONLY
    {
      id: 'jlt_map_only_a4',
      name: 'Helkarta grafik A4',
      types: ['map_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        Map:
          `
                        height: ` +
          a4_portrait.height +
          `px;
                        position: relative;
                        &.fullescreen_true{
                            height: ` +
          (a4_portrait.height - jlt_config.footer_effective_height) +
          `px;
                        }
                        div.inner {
                            height: ` +
          a4_portrait.height +
          `px;
                            width: 100%;
                        }
                    `,
      },
    },
    {
      id: 'jlt_map_only_a4_no_graphics',
      name: 'Helkarta A4',
      types: ['map_only'],
      version: 1,
      height: a4_portrait.height,
      width: a4_portrait.width,
      styles: {
        Map:
          `
                        height: ` +
          a4_portrait.height +
          `px;
                        position: relative;
                        &.fullescreen_true{
                            height: ` +
          a4_portrait.height +
          `px;
                        }
                        div.inner {
                            height: ` +
          a4_portrait.height +
          `px;
                            width: 100%;
                        }
                    `,
        OrganizationProfile: `
                        display: none !important;
                    `,
      },
    },
    {
      id: 'jlt_map_only_a3',
      name: 'Helkarta grafik A3',
      big: true,
      types: ['map_only'],
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        MapLegend: `
        padding: 8px 11px;
        border: 2px solid #000;
        border-radius: 11px;
        font-size: 22px;
        &.bottom_left {
          bottom: 28px;
          left: 17px;
        }
        &.bottom_right {
          bottom: 28px;
          right: 17px;
        }
        &.top_left {
          top: 28px;
          left: 17px;
        }
        &.top_right {
          top: 28px;
          right: 17px;
        }
        ul {
          li {
            margin-bottom: 3px;
            .legend_item_label {
              padding-left: 14px;
            }
            &:hover {
              .legend_item_label {
                &:after {
                  font-size: 19px;
                }
              }
            }
          }
        }
        .placeholder {
          width: 45px;
          height: 45px;
          & svg {
            width: 45px;
            height: 45px;
          }
        }
        `,
        document_specification: `height: ${a3_portrait.height}px;
          width: ${a3_portrait.width}px;
          background-color: #fff;
          padding: 0;
          position: relative;
          overflow: hidden;
        `,
        Map:
          `
          height: ${a3_portrait.height}px;
          position: relative;
          &.fullescreen_true {
            height: ` +
          (a3_portrait.height - jlt_config.footer_effective_height_a3) +
          `px;
          }
          div.inner {
            height: ${a3_portrait.height}px;
            width: 100%;
          }
        `,
        OrganizationProfile: `
        .layer_1 {
          background-image: url(${Graphic_customer_jlt_footer_a4__red});
          width: ${a3_portrait.width}px;
          height: 565px;
        }
        `,
      },
    },
    {
      id: 'jlt_map_only_a3_no_graphics',
      name: 'Helkarta A3',
      types: ['map_only'],
      big: true,
      version: 1,
      height: a3_portrait.height,
      width: a3_portrait.width,
      styles: {
        MapLegend: `
        padding: 8px 11px;
        border: 2px solid #000;
        border-radius: 11px;
        font-size: 22px;
        &.bottom_left {
          bottom: 28px;
          left: 17px;
        }
        &.bottom_right {
          bottom: 28px;
          right: 17px;
        }
        &.top_left {
          top: 28px;
          left: 17px;
        }
        &.top_right {
          top: 28px;
          right: 17px;
        }
        ul {
          li {
            margin-bottom: 3px;
            .legend_item_label {
              padding-left: 14px;
            }
            &:hover {
              .legend_item_label {
                &:after {
                  font-size: 19px;
                }
              }
            }
          }
        }
        .placeholder {
          width: 45px;
          height: 45px;
          & svg {
            width: 45px;
            height: 45px;
          }
        }
        `,
        document_specification: `height: ${a3_portrait.height}px;
          width: ${a3_portrait.width}px;
          background-color: #fff;
          padding: 0;
          position: relative;
          overflow: hidden;
        `,
        Map:
          `
          height: ` +
          a3_portrait.height +
          `px;
          position: relative;
          &.fullescreen_true{
          height: ` +
          a3_portrait.height +
          `px;
          }
          div.inner {
            height: ` +
          a3_portrait.height +
          `px;
          width: 100%;
          }
        `,
        OrganizationProfile: `display: none !important;`,
      },
    },
  ],
};
