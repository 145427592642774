import React from 'react';
import { withRouter } from 'react-router';
import withStore from 'with-store';
import DocRenderer from '../base/DocRenderer';

import api_helpers from '../../helpers/api_helpers';
class RenderView extends React.Component {
  componentDidMount() {
    let doc_id = this.props.match.params.doc_id;
    api_helpers.fetchDocument(doc_id).then((doc) => {
      withStore.actions.docs.setDocsStateByArr([doc], true);
    });
  }
  render() {
    let doc = this.props.store_ext.getRenderDoc(this.props.match.params.doc_id);
    if (!doc) {
      return null;
    }
    let tmp_settings = Object.assign({}, doc.settings, doc.tmp_settings);
    return <DocRenderer doc={doc} settings={tmp_settings} />;
  }
}

export default withRouter(withStore(RenderView));
