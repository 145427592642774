import React from 'react';
import withStore from 'with-store';
import styled from 'styled-components';

/**
  interface Props {
    texts: TextParagraph[];
    style_id: string;
  }
 */

const Container = styled.div`
  ${(props) => props.textStyles && props.textStyles}
`;

/**
 Paragraph props = TextParagraph.
 */
const Paragraph = ({ text, justified, style, weight, italic }) => {
  return (
    <div className={justified + ' ' + style + ' ' + weight + ' ' + italic}>
      {text}
    </div>
  );
};

const Text = ({ paragraphs = [], store_ext, style_id }) => {
  return (
    <Container
      textStyles={store_ext.getTextStyles(style_id)}
      className={style_id}
    >
      {paragraphs.map((paragraph, i) => (
        <Paragraph key={'paragraph_' + i} {...paragraph} />
      ))}
    </Container>
  );
};

export default withStore(Text);
