export default [
  {
    title: '2.0.14',
    date: '2020-02-04',
    new: ['Möjligheten att ändra ordning på legenden.'],
    fixed: [
      'Tog bort möjligheten att ta bort rader i legenden i den renderade vyn.',
    ],
  },
  {
    title: '2.0.5',
    date: '2019-05-17',
    fixed: [
      'Fixat problem med storlek och position av förhandsgranskningsvyn.',
    ],
  },
  {
    title: '2.0.3',
    date: '2019-05-06',
    new: ['Anslagssök visar nu resultat från arkivet.'],
    fixed: [
      'Fixade fall där kartan inte kunde interageras med när högerpanelen var öppen.',
      'Tog bort oanvända H1 och H2 textval i brödtext.',
      'Textbubblor på kartan ska nu behålla sin position efter omladdning av dokumentet.',
    ],
  },
  {
    title: '2.0.0',
    date: '2019-05-02',
    new: [
      'Implementerat export i PNG format.',
      'Möjlighet att justera textstorlek.',
    ],
  },
  {
    title: '1.2.0',
    date: '2019-03-26',
    new: ['Implementerat sökfunktionalitet på startsidan.'],
  },
  {
    title: '1.1.0',
    date: '2019-03-21',
    new: [
      'Möjlighet att placera kartans legend i valfritt hörn.',
      'Markörer och linjer kan valfritt läggas till i legenden.',
    ],
  },
  {
    title: '1.0.0',
    date: '2018-12-12',
    new: ['Release av version 1'],
  },
];
