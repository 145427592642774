import * as types from './users_reducer';

export function setUser(user_data, state) {
  return {
    type: types.USERS_STATE_SET_USER,
    payload: user_data,
  };
}

export function setUserStateByArr(user_arr, fetched = false, state) {
  let by_id = {};
  user_arr.forEach((user) => {
    user.full_name = user.last_name
      ? user.first_name + ' ' + user.last_name
      : user.first_name;
    by_id[user.id] = user;
  });
  return {
    type: types.USERS_STATE_SET,
    payload: Object.assign({}, state, {
      by_id: by_id,
      all: [].concat(user_arr),
      fetched: fetched,
    }),
  };
}

export function setMyId(my_id, state) {
  return {
    type: types.USERS_STATE_SET_MY_ID,
    payload: my_id,
  };
}
