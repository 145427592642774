import React from 'react';
import withStore from 'with-store';
import styled from 'styled-components';

const Container = styled.div`
  ${(props) => props.styles}
`;

class OrganizationProfile extends React.Component {
  constructor(props) {
    super();
    this.styles = props.store_ext.getStyles(props.style_id)[
      'OrganizationProfile'
    ];
  }

  componentDidUpdate(prev_props) {
    if (prev_props.style_id !== this.props.style_id) {
      this.styles = this.props.store_ext.getStyles(this.props.style_id)[
        'OrganizationProfile'
      ];
      this.forceUpdate();
    }
  }

  render() {
    return (
      <Container styles={this.styles}>
        <div className="layer_1" />
        <div className="layer_2" />
        <div className="layer_3" />
      </Container>
    );
  }
}

export default withStore(OrganizationProfile);
