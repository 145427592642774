import * as types from './image_reducer';

export function setThumbnail(tn_data, state) {
  return {
    type: types.IMAGE_STATE_SET_TN,
    payload: tn_data,
  };
}

export function removeThumbnail(doc_id, state) {
  return {
    type: types.IMAGE_STATE_REMOVE_TN,
    payload: doc_id,
  };
}

export function setPreview(preview_data, state) {
  return {
    type: types.IMAGE_STATE_SET_PREVIEW,
    payload: preview_data,
  };
}
