import React from 'react';
import oh from 'output-helpers';
import styled, { keyframes } from 'styled-components';
import Button from './Button';
import TextInput from './prompt_hoc/TextInput';
import BoolInput from './prompt_hoc/BoolInput';
import GridInput from './prompt_hoc/GridInput';
const IconDraw = require('react-icons/lib/md/border-color');
const IconUp = require('react-icons/lib/md/arrow-drop-up');
const IconMarker = require('react-icons/lib/md/place');
const IconText = require('react-icons/lib/md/insert-comment');

// Note. this.tools is an array here

const anim_underline = keyframes`
            from {
                opacity: 0;
                height: 0;
            }
            to {
                height: 6px;
                opacity: 1;
            }
        `;

const Container = styled.div`
  position: absolute;
  z-index: 1;
  .tools_container {
    display: ${(props) => (props.hide ? 'none' : 'block')};
    width: ${(props) => props.theme.sizes.map.tools.width}px;
    box-shadow: ${(props) => props.theme.colors.shadow};
    background-color: #fff;
    border-radius: 0 0 0 8px;
    text-align: center;
    &.active {
      border-radius: 8px 8px 0 0;
    }
  }
`;

const StyledTool = styled.div`
  width: ${(props) => 100 / props.tools.length}%;
  color: ${(props) => props.theme.colors.dark[2]};
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  padding: 14px 0 12px;
  &:hover,
  &.active {
    .tool_icon {
      color: #fff;
    }
    .tool_icon {
      background-color: ${(props) =>
        props.theme.colors[props.tools[props.tool_index].color][2]};
      border-color: ${(props) =>
        props.theme.colors[props.tools[props.tool_index].color][1]};
    }
    .tool_label {
      opacity: 1;
    }
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    border-right: none;
  }
  .tool_icon {
    margin: auto;
    background-color: #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    border: 2px solid ${(props) => props.theme.colors.border};
    transition: all 0.2s;
    svg {
      margin-top: 17px;
      width: 30px;
      height: 30px;
    }
  }
  .tool_label {
    opacity: 0.5;
    transition: all 0.3s;
    margin-top: 8px;
  }
`;

const ToolUnderline = styled.div`
  animation: ${anim_underline} 0.4s;
  height: 6px;
  border-radius: 0 0 4px 4px;
  background-color: ${(props) => props.theme.colors.border};
  position: relative;
  z-index: 2;
  overflow: hidden;
  &.active {
    border-radius: 0;
  }
  .tool_underline,
  .tool_underline_shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${(props) => 100 / props.tools.length}%;
  }
  .tool_underline {
    transition: all 0.4s;
    background-color: ${(props) =>
      props.theme.colors[props.tools[props.tool_index].color][1]};
    z-index: 1;
  }
  .tool_underline_shadow {
    transition: all 0.8s;
    background-color: ${(props) =>
      props.theme.colors[props.tools[props.tool_index].color][2]};
  }
`;

const ToolOptions = styled.div`
  animation: ${anim_underline} 0.4s;
  box-shadow: ${(props) => props.theme.colors.shadow};
  border-radius: ${(props) => (props.stand_alone ? '8px' : '0 0 8px 8px')};
  width: ${(props) => props.theme.sizes.map.tools.width}px;
  .floating_tools_begin,
  .floating_tools_guide {
    padding: 20px;
  }
  .floating_tools_guide {
    border-radius: ${(props) => (props.stand_alone ? '8px 8px 0 0' : '0')};
    color: #fff;
    transition: all 0.4s;
    //background-color: ${(props) =>
      props.theme.colors[props.tools[props.tool_index].color][1]};
    background-image: linear-gradient(
      45deg,
      ${(props) => props.theme.colors[props.tools[props.tool_index].color][1]}
        0%,
      ${(props) => props.theme.colors[props.tools[props.tool_index].color][2]}
        100%
    );
    .guide_title {
      letter-spacing: 1px;
      opacity: 0.7;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .guide_subtitle {
      font-size: 15px;
      opacity: 1;
    }
  }
  .floating_tools_begin {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
  }
  .user_input {
    background-color: #fff;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    display: block;
    width: 100%;
    padding: 14px 20px;
    position: relative;
    z-index: 1;
    &:last-child {
      border: none;
    }
    .dropdown {
      display: table;
    }
    .setting_label {
      color: ${(props) => props.theme.colors.dark[0]};
      font-size: 14px;
      margin-bottom: 12px;
      font-weight: bold;
    }
  }
  .floating_tool_hide {
    position: absolute;
    z-index: -1;
    width: 90%;
    margin-left: 5%;
    padding: 4px;
    cursor: pointer;
    border-radius: 0 0 8px 8px;
    text-align: center;
    background-color: ${(props) => props.theme.colors.border};
    transition: all 0.3s;
    &:hover {
      background-color: ${(props) => props.theme.colors.border};
      padding: 8px;
      svg {
        fill: ${(props) => props.theme.colors.gray[0]};
      }
    }
    svg {
      height: 20px;
      width: 20px;
      fill: ${(props) => props.theme.colors.gray[1]};
    }
  }
`;

class FloatingTools extends React.Component {
  constructor(props) {
    super(props);

    this.tools = [];
    this.injectIconsInMapTools();
    const tool_values = this.setupToolsValues();
    this.state = {
      selected_tool: props.selectedTool ? props.selectedTool : false,
      tool_active: false,
      values: tool_values,
    };

    this.input_components = {
      // date: DateInput,
      // number: NumberInput,
      // select: SelectInput,
      grid: GridInput,
      bool: BoolInput,
      text: TextInput,
    };
  }

  clearSelectedTool() {
    //This is called externally!
    this.setState({
      selected_tool: false,
      tool_active: false,
    });
  }

  injectIconsInMapTools() {
    for (let key in this.props.mapTools) {
      if (!this.props.mapTools.hasOwnProperty(key)) continue;
      let tool = this.props.mapTools[key];
      switch (tool.id) {
        case 'bus_stop':
          tool.icon = <IconMarker />;
          break;
        case 'line':
          tool.icon = <IconDraw />;
          break;
        case 'text':
          tool.icon = <IconText />;
          break;
        case 'symbol':
          tool.icon = <IconMarker />;
          break;
        default:
          tool.icon = <IconText />;
      }
      this.tools.push(tool);
    }
  }

  setupToolsValues() {
    /*
            this.state.values = {
                line: {
                    line_width: 1,
                    color: 'red'
                }
            }
        */
    let tools_values = {};
    this.tools.forEach((tool, index) => {
      let this_tools_settings = {};
      for (let settings_key in tool.settings) {
        if (!tool.settings.hasOwnProperty(settings_key)) continue;
        let tool_setting = tool.settings[settings_key];
        this_tools_settings[settings_key] = tool_setting.value;
      }
      tools_values[tool.id] = this_tools_settings;
    });

    return tools_values;
  }

  renderToolOptions() {
    if (this.state.selected_tool === false || this.state.tool_active === true) {
      return null;
    }
    // console.log("this.state", this.state);
    // updated_all.findIndex(doc => doc.id === item.id)

    return (
      <ToolOptions
        stand_alone={this.state.tool_active}
        tool_index={this.state.selected_tool}
        tools={this.tools}
      >
        <div className="floating_tools_guide">
          <p className="guide_title">
            {oh.translate(
              this.tools[this.state.selected_tool].guide_text.title,
            )}
          </p>
          <p className="guide_subtitle">
            {oh.translate(
              this.tools[this.state.selected_tool].guide_text.subititle,
            )}
          </p>
        </div>

        {this.state.selected_tool === 1 ? (
          <div className="floating_tools_begin">
            <Button
              filled
              block
              big
              green
              onClick={() => {
                if (
                  this.tools[this.state.selected_tool].hasOwnProperty('saveCB')
                ) {
                  this.tools[this.state.selected_tool].saveCB();
                } else {
                  console.log('MISSING: saveCB');
                }
                this.setState({ tool_active: false });
              }}
              label={oh.translate('finish')}
            />
            <br />
            <Button
              filled
              block
              big
              dark
              onClick={() => {
                if (
                  this.tools[this.state.selected_tool].hasOwnProperty(
                    'cancelCB',
                  )
                ) {
                  this.tools[this.state.selected_tool].cancelCB();
                } else {
                  console.log('MISSING: cancelCB');
                }

                this.setState({ selected_tool: false, tool_active: false });
              }}
              label={oh.translate('cancel')}
            />
          </div>
        ) : (
          <div className="floating_tools_begin">
            <Button
              filled
              block
              big
              dark
              onClick={() => {
                if (
                  this.tools[this.state.selected_tool].hasOwnProperty(
                    'cancelCB',
                  )
                ) {
                  this.tools[this.state.selected_tool].cancelCB();
                } else {
                  console.log('MISSING: cancelCB');
                }

                this.setState({ selected_tool: false, tool_active: false });
              }}
              label={oh.translate('cancel')}
            />
          </div>
        )}

        <div
          className="floating_tool_hide"
          onClick={() => {
            if (
              this.state.selected_tool !== false &&
              this.tools[this.state.selected_tool].hasOwnProperty('cancelCB')
            ) {
              this.tools[this.state.selected_tool].cancelCB();
            } else {
              console.log('MISSING: cancelCB');
            }
            this.setState({ selected_tool: false, tool_active: false });
          }}
        >
          <IconUp />
        </div>
      </ToolOptions>
    );
  }

  getCurrentToolSettings() {
    return this.state.values[this.tools[this.state.selected_tool].id];
  }

  renderInputs(tool) {
    if (!tool) {
      return null;
    }
    let inputs = tool.settings;

    let input_array = [];

    for (var key in inputs) {
      if (!inputs.hasOwnProperty(key)) continue;
      const input_obj = inputs[key];
      input_obj.id = key;
      input_array.push(input_obj);
    }

    return input_array.map((input, index) => {
      let InputComponent = this.input_components[input.type];
      if (!InputComponent) {
        return <div className="user_input">Uknown input type</div>;
      }
      return (
        <InputComponent
          key={index}
          modalConfirm={() => {
            console.log('CONFIRM');
          }}
          request={input}
          value={this.state.values[tool.id][input.id]}
          onChange={(value) => {
            this.inputValueChangeCB(tool.id, input.id, value);
          }}
        />
      );
    });
  }

  inputValueChangeCB(tool_id, input_id, value) {
    let all_values = Object.assign({}, this.state.values);
    all_values[tool_id][input_id] = value;
    this.setState({
      values: all_values,
    });
  }

  renderToolActive() {
    if (this.state.tool_active === false) {
      return null;
    }

    return (
      <ToolOptions
        stand_alone={this.state.tool_active}
        tool_index={this.state.selected_tool}
        tools={this.tools}
      >
        <div className="floating_tools_guide">
          <p className="guide_title">
            {oh.translate(
              this.tools[this.state.selected_tool].active_guide_text.title,
            )}
          </p>
          <p className="guide_subtitle">
            {oh.translate(
              this.tools[this.state.selected_tool].active_guide_text.subititle,
            )}
          </p>
        </div>
        <div className="floating_tools_begin">
          <Button
            filled
            block
            big
            green
            onClick={() => {
              if (
                this.tools[this.state.selected_tool].hasOwnProperty('saveCB')
              ) {
                this.tools[this.state.selected_tool].saveCB();
              } else {
                console.log('MISSING: saveCB');
              }
              this.setState({ tool_active: false });
            }}
            label={oh.translate('finish')}
          />
          <br />
          <Button
            filled
            block
            big
            dark
            onClick={() => {
              if (
                this.tools[this.state.selected_tool].hasOwnProperty('cancelCB')
              ) {
                this.tools[this.state.selected_tool].cancelCB();
              } else {
                console.log('MISSING: cancelCB');
              }

              this.setState({ tool_active: false });
            }}
            label={oh.translate('cancel')}
          />
        </div>
      </ToolOptions>
    );
  }

  renderToolsButtons() {
    return this.tools.map((tool, i) => {
      return (
        <StyledTool
          tools={this.tools}
          key={i}
          tool_index={i}
          className={`tool ${this.state.selected_tool === i ? 'active' : ''}`}
          onClick={() => {
            if (
              this.state.selected_tool !== false &&
              this.tools[this.state.selected_tool].hasOwnProperty('cancelCB')
            ) {
              this.tools[this.state.selected_tool].cancelCB();
            } else {
              console.log('MISSING: cancelCB');
            }
            this.setState({
              selected_tool: this.state.selected_tool !== i ? i : false,
              tool_active: false,
            });
            this.props.closeAllTools();
            if (this.state.selected_tool !== i) {
              if (this.tools[i].hasOwnProperty('openCB')) {
                this.tools[i].openCB();
              } else {
                console.log('MISSING: openCB');
              }
            }
          }}
        >
          <div className="tool_icon">{tool.icon}</div>
          <div className="tool_label">
            <p>{oh.translate(tool.label)}</p>
          </div>
        </StyledTool>
      );
    });
  }

  render() {
    return (
      <Container hide={this.state.tool_active} className={`floating_tools`}>
        <div
          className={`tools_container ${
            this.state.selected_tool !== false ? 'active' : ''
          }`}
        >
          {this.renderToolsButtons()}
          {this.state.selected_tool !== false ? (
            <ToolUnderline
              tools={this.tools}
              className={`tool_underline_container ${
                this.state.selected_tool !== false ? 'active' : ''
              }`}
              tool_index={this.state.selected_tool}
            >
              <div
                className="tool_underline"
                style={{
                  left:
                    (this.state.selected_tool * 100) / this.tools.length + '%',
                }}
              />
              <div
                className="tool_underline_shadow"
                style={{
                  left:
                    (this.state.selected_tool * 100) / this.tools.length + '%',
                }}
              />
            </ToolUnderline>
          ) : null}
        </div>
        {this.renderToolOptions()}
        {this.renderToolActive()}
      </Container>
    );
  }
}

export default FloatingTools;
