import React from 'react';
import styled from 'styled-components';

let types = ['rich', 'single'];

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  .item {
    position: absolute;
    color: #000;
    min-height: 12px;
    min-width: 12px;
    border: 1px solid black;
    border-radius: 3px;
    padding: 4px 7px;
    background-color: white;
    pointer-events: none;
    transform: translateX(-50%);
    font-weight: bold;
    white-space: pre-wrap;
    & > p {
      font-size: 16px;
    }
    &.bubble {
    }
    &.single {
      white-space: nowrap;
    }
    .selected_routes_container {
      max-width: 244px;
      white-space: pre-line;
      margin-bottom: 4px;
      .route_style {
        display: inline-block;
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 4px 6px;
        margin: 2px;
        text-align: center;
        font-size: 14px;
        vertical-align: middle;
        height: 26px;
        position: relative;
        &:first-child {
          margin-left: 0;
        }
        &.circle {
          padding: unset;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 26px;
          .border_box {
            border-radius: 50%;
          }
        }
        .border_box {
          border: 1px solid #fff;
          border-radius: 4px;
          height: 22px;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .item.big {
    min-height: 17px;
    min-width: 17px;
    border: 2px solid black;
    border-radius: 4px;
    padding: 6px 10px;
    & > p {
      font-size: 22px;
    }
    .selected_routes_container {
      max-width: 345px;
      margin-bottom: 6px;
      .route_style {
        border: 3px solid transparent;
        border-radius: 6px;
        padding: 6px 9px;
        margin: 3px;
        font-size: 20px;
        height: 37px;
        &.circle {
          width: 37px;
        }
        .border_box {
          border: 2px solid #fff;
          border-radius: 6px;
          height: 31px;
        }
      }
    }
  }
`;

class MapOverlay extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.map_ref) {
      throw new Error("MapOverlay requires prop 'map_ref'.");
    }
    this.map = this.props.map_ref;
    this.onDragStartCB = () => {
      this.onDragStart();
    };
    this.onIdleCB = () => {
      this.onIdle();
    };
    this.map.registerDragStartCB(this.onDragStartCB);
    this.map.registerIdleCB(this.onIdleCB);

    this.drawn_items = {};
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.clearAll();
    this.map.unregisterDragStartCB(this.onDragStartCB);
    this.map.unregisterIdleCB(this.onIdleCB);
  }

  componentDidMount() {
    if (this.props.mounted) {
      this.props.mounted(this);
    }
  }

  onDragStart() {
    this.hide();
  }

  onIdle() {
    this.updatePositions();
    this.show();
  }

  updatePositions() {
    if (!this.ref || !this.map) {
      return;
    }

    Object.keys(this.drawn_items).forEach((item_id) => {
      let item = this.drawn_items[item_id];
      let pos = this.map.toPixel(item.coords);
      item.element.style.left = pos.screen[0] + 'px';
      item.element.style.top = pos.screen[1] + 18 + 'px';
      if (item.cull && pos.is_visible === false) {
        item.element.style.display = 'none';
      } else {
        item.element.style.display = 'block';
      }
    });
  }

  set(id, opts) {
    if (!this.ref || !this.map) {
      return;
    }

    if (opts.hasOwnProperty('type') && !types.includes(opts.type)) {
      throw new Error('Invalid type in map overlay item.');
    }
    if (!opts.hasOwnProperty('text')) {
      throw new Error('Missing text property in map overlay item.');
    }
    if (!opts.hasOwnProperty('coords')) {
      throw new Error('Missing coords in map overlay item.');
    }
    let cull = opts.cull || true;
    let pos = this.map.toPixel(opts.coords);

    let element;
    if (this.drawn_items.hasOwnProperty(id)) {
      element = this.drawn_items[id].element;
    } else {
      element = document.createElement('div');
      this.drawn_items[id] = {
        element: element,
        coords: opts.coords,
        cull: cull,
      };
    }
    element.className = `item ${opts.type} ${opts.big ? 'big' : ''}`;
    element.style.left = pos.screen[0] + 'px';
    element.style.top = pos.screen[1] + (opts.big ? 26 : 18) + 'px';

    while (element.firstChild && element.removeChild(element.firstChild));

    let text_element = document.createElement('p');
    text_element.innerHTML = opts.text;
    if (
      opts.hasOwnProperty('selected_routes') &&
      Array.isArray(opts.selected_routes)
    ) {
      let route_container = document.createElement('div');
      route_container.className = 'selected_routes_container';
      opts.selected_routes.forEach((selected_route_style) => {
        let route_element = document.createElement('div');
        let shape =
          selected_route_style.label.length > 2
            ? 'square'
            : selected_route_style.shape;
        route_element.className = 'route_style ' + shape;
        route_element.style.backgroundColor = selected_route_style.background;
        route_element.style.color = selected_route_style.foreground;
        route_element.innerHTML = selected_route_style.label;
        let border_box_element = document.createElement('div');
        border_box_element.className = 'border_box';
        route_element.appendChild(border_box_element);
        route_container.appendChild(route_element);
      });
      element.appendChild(route_container);
    }
    element.appendChild(text_element);

    if (cull && pos.is_visible === false) {
      element.style.display = 'none';
    }

    this.ref.appendChild(element);
  }

  remove(id) {
    if (!this.ref || !this.drawn_items.hasOwnProperty(id)) {
      return;
    }

    this.ref.removeChild(this.drawn_items[id].element);
    delete this.drawn_items[id];
  }

  clearAll() {
    if (!this.ref) {
      return;
    }
    Object.keys(this.drawn_items).forEach((item_id) => {
      let item = this.drawn_items[item_id];
      this.ref.removeChild(item.element);
    });
    this.drawn_items = {};
  }

  hide() {
    if (!this.ref) {
      return;
    }
    this.ref.style.display = 'none';
  }
  show() {
    if (!this.ref) {
      return;
    }
    this.ref.style.display = 'block';
  }

  render() {
    return (
      <Container
        innerRef={(ref) => {
          this.ref = ref;
        }}
      />
    );
  }
}

export default MapOverlay;
