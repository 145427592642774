export default {
  'sv-SE': {
    abort_edits: 'avbryt redigering',
    actions: 'åtgärder',
    archive_folder: 'arkiv',
    archive: 'arkivera',
    archived_doc: 'detta dokument är arkiverat',
    archived_items_cant_be_changed: 'arkiverade anslag kan inte redigeras',
    archived_search_result: 'sökresultat, arkiverade dokument',
    are_you_sure: 'är du säker?',
    at_least_two_characters_to_search: 'skriv minst två bokstäver för att söka',
    begin: 'börja',
    body: 'brödtext',
    bottom_left: 'nedre vänster hörn',
    bottom_right: 'nedre höger hörn',
    bread_text: 'brödtext',
    bulletin: 'anslag',
    bus_route: 'linje',
    bus_stop_state: 'status på hållplats',
    bus_stop: 'hållplats',
    busy: 'upptaget',
    cancel: 'avbryt',
    clone: 'kopia',
    color: 'färg',
    confirm_create_a_doc_copy: 'skapa en kopia av anslaget',
    confirm: 'bekräfta',
    confirming_will_delete: 'permanent borttagning',
    create_new_document: 'skapa nytt anslag',
    create_new_folder: 'skapa ny katalog',
    create: 'skapa',
    created_by: 'skapat av',
    created: 'skapat',
    cut_line: 'klipp linje',
    cut_me: 'klipp',
    cut: 'klipp',
    description: 'beskrivning',
    doc_has_invalid_style:
      'detta anslag kan inte renderas pga en ogilitig konfigurerad stil',
    doc_name: 'anslagnamn',
    doc_style: 'anslagstil',
    doc_type: 'anslagtyp',
    document_does_not_exist: 'dokument saknas eller är borttaget',
    document: 'anslag',
    draw_new_line: 'rita ny linje',
    drawing_new_line: 'ritar ny linje',
    duplicate: 'duplicera',
    edit_details: 'Ändra namn',
    edit_map: 'redigera kartan',
    edit_meta_information: 'Redigera information',
    edit_subtitle_active_work: 'Någon annan redigerar dokumentet',
    edit_subtitle: 'gör ändringar',
    edit: 'redigera',
    empty_folder_create: "använd 'Skapa' knappen ovan",
    empty_folder: 'tom katalog',
    empty_result_try_other: 'försök att söka på anslagets innehåll',
    empty_result: 'hittade inga anslag',
    enter_document_title: 'välj anslagstitel',
    enter_folder_title: 'välj katalogtitel',
    err_fetching_resources: 'Fel uppstod vid hämtning av resurser.',
    error_has_occured: 'fel har uppstått',
    error_report_failed: 'kunde inte skicka felrapport',
    error_report_sent: 'skickar felrapport',
    error: 'Error',
    export_subtitle: 'gör val för export',
    export: 'exportera',
    failed: 'misslyckades',
    filter: 'filtrera',
    finish: 'färdigställ',
    floating_text_block_value: 'text i textruta',
    floating_text_block: 'textruta',
    folder: 'katalog',
    go_to_start: 'gå till startsidan',
    history: 'historik',
    incompatable_option: 'inkompatibelt alternativ',
    information: 'information',
    last_edit: 'senast ändrat',
    legend_position: 'legendens position',
    line_color: 'linjefärg',
    line_style: 'linjestil',
    line_type_arrows: 'pilar',
    line_type_dashed: 'streckad',
    line_type_one_way_other: 'enkelriktad andra hållet',
    line_type_one_way: 'enkelriktad',
    line_type_solid: 'heldragen',
    line_type_temporary_break: 'avbrott',
    line: 'linje',
    loading: 'laddar',
    logging_in: 'loggar in',
    make_choices_for_make_text: 'Klicka på kartan för att placera text',
    make_choices_for_new_bus_stop: 'sök eller klicka på hållplatser på kartan',
    make_choices_for_new_line:
      'Sök fram en busslinje till vänster eller rita ut en egen på kartan',
    make_choices_for_new_symbol: 'Klicka på kartan för att placera symbol',
    map_only: 'helkarta',
    map: 'karta',
    marker_type_default: 'standard',
    marker_type_placed_bus_stop_closed: 'avstängd busshållplats',
    marker_type_placed_bus_stop: 'busshållplats',
    marker_type_placed_shopping: 'handel',
    marker_type_placed_temporary_bus_stop: 'tillfällig busshållplats grön',
    marker_type_placed_temporary_bus_stop_blue: 'tillfällig busshållplats blå',
    marker_type_placed_temporary_bus_stop_orange:
      'tillfällig busshållplats orange',
    marker_type_placed_temporary_bus_stop_red: 'tillfällig busshållplats röd',
    marker_type: 'typ av markör',
    marker: 'kartmarkör',
    markers: 'kartmarkörer',
    name: 'namn',
    new_document: 'nytt anslag',
    new_folder: 'ny katalog',
    no_marker_types: 'inga markörtyper definierade',
    no_periods: 'inga perioder',
    no_polyline_marker_types: 'inga linjetyper definierade',
    no_styles: 'ni saknar anslagstilar för den anslagtypen',
    no_types: 'det finns inga anslagstyper',
    not_yet_implemented: 'ännu inte implementerat',
    ok: 'ok',
    options_for: 'val för',
    OrganizationProfile: 'företagslogga',
    other_routes: 'andra linjer',
    other_period_stop: 'Hållplatsen har ingen data i vald period.',
    passing_routes: 'passerande linjer',
    period: 'period',
    place_bus_stop: 'placera hållplats',
    place_new_symbol: 'placera symbol',
    placing_bus_stop: 'Sök eller klicka på hållplatser',
    placing_new_symbol: 'välj markör och klicka sedan på kartan',
    please_contact_us: 'vänligen kontakta oss',
    push_edits: 'spara redigering',
    redirect_2: 'omledning typ 2',
    redirect: 'omledning',
    redirect_v2: 'omledning',
    redirect_2_v2: 'omledning typ 2',
    redo: 'återställ',
    remove: 'ta bort',
    route_variant: 'variant',
    save: 'spara',
    search_result: 'sökresultat',
    selected_routes: 'valda linjer',
    sending_error_report: 'skickar felrapport',
    sending: 'skickar',
    sent: 'skickat',
    some_missing_translation: 'saknas',
    sort: 'sortera',
    start: 'start',
    symbol: 'symbol',
    table_period: 'rebus export',
    text_in_legend: 'text i legenden',
    text_only: 'text',
    text_only_v2: 'text',
    text: 'text',
    thickness: 'linjebredd',
    title: 'titel',
    to_clone_subtitle: 'skapa en kopia',
    to_clone: 'kopiera',
    too_few_characters: 'för få bokstäver',
    top_left: 'över vänster hörn',
    top_right: 'över höger hörn',
    undo: 'ångra',
    untitled_document: 'namnlöst anslag',
    visible_in_legend: 'visas i legenden',
    working_on_document: 'arbetar på dokumentet',
    write_new_text: 'skapa ny textruta',
    writing_new_text: 'skriv texten här och flytta rutan på kartan',
    you_have_activated_the_tool: 'du har aktiverat verktyget',

    //styles
    title_small: 'liten titel',
    title_medium: 'medium titel',
    title_large: 'stor titel',
    text_small: 'liten text',
    text_medium: 'medium text',
    text_large: 'stor text',
    subtitle: 'subtitel',
    weight_normal: 'standard tjocklek',
    weight_bold: 'fet tjocklek',
    align_left: 'vänsterjusterad',
    align_center: 'centerjusterad',
    align_right: 'högerjusterad',
    italic: 'kursiv',
  },
  'en-US': {
    archive_folder: 'archive',
    enter_folder_title: 'enter folder title',
    enter_document_title: 'enter bulletin title',
    loading: 'loading',
    some_missing_translation: 'missing',

    err_fetching_resources: 'error when fetching resources.',
    error_report_failed: 'could not send error report',
    error_report_sent: 'sending error report',
    error: 'Error',
    please_contact_us: 'please contact us',
    failed: 'failed',
    sending: 'sending',
    sent: 'sent',
    error_has_occured: 'an error has occured',
    sending_error_report: 'sending error report',
    untitled_document: 'untitled document',

    too_few_characters: 'too few characters',
    at_least_two_characters_to_search:
      'enter at least two characters to search',
  },
};
