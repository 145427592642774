import React from 'react';
import withStore from 'with-store';
import styled from 'styled-components';
import oh from 'output-helpers';
import * as doc_definitions from '../../doc_definitions';
import OrganizationProfile from './doc_parts/OrganizationProfile';
import Map from './doc_parts/Map';
import RichText from './doc_parts/RichText';
import Text from './doc_parts/Text';
import MapLegend from '../base/MapLegend';

const Container = styled.div`
  position: relative;
  ${(props) => props.styles}
`;

//This component expects prop "doc", which should be a full document.
class DocRenderer extends React.Component {
  constructor(props) {
    super(props);

    if (
      props.store.styles.by_id.hasOwnProperty(props.settings.style_id) === false
    ) {
      this.invalid_style = true;
    }

    if (!this.invalid_style) {
      this.styles = props.store_ext.getStyles(props.settings.style_id)[
        'document_specification'
      ];
    }

    this.unmounted = false;
  }

  componentDidUpdate(prev_props) {
    if (
      !this.unmounted &&
      prev_props.settings.style_id !== this.props.settings.style_id
    ) {
      this.styles = this.props.store_ext.getStyles(
        this.props.settings.style_id,
      )['document_specification'];
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  renderParts(doc, settings) {
    let doc_type_definition = doc_definitions.types[settings.type_id];

    if (this.invalid_style) {
      return <div>{oh.translate('doc_has_invalid_style')}</div>;
    }

    if (!doc_type_definition) {
      throw new Error(
        'Doc type definition not found for type_id: ' +
          settings.type_id +
          ' in doc ' +
          doc.id +
          '.',
      );
    }

    let indexes = {
      map: -1,
      title: -1,
      body: -1,
      text: -1,
    };
    return doc_type_definition.parts.map((part_definition, i) => {
      if (part_definition.type === 'OrganizationProfile') {
        return (
          <OrganizationProfile
            key={'part_' + i}
            style_id={settings.style_id}
            preview={this.props.preview}
          />
        );
      } else if (part_definition.type === 'Map') {
        indexes.map++;
        return (
          <div key={'map_' + indexes.map} style={{ position: 'relative' }}>
            {this.renderLegend(settings, indexes.map)}
            <Map
              key={'part_' + i}
              settings={settings['map_' + indexes.map]}
              fullscreen={part_definition.fullscreen}
              style_id={settings.style_id}
              preview={this.props.preview}
            />
          </div>
        );
      } else if (part_definition.type === 'Text') {
        //V2
        indexes.text++;
        const key = 'text_' + indexes.text;
        return (
          <Text
            key={'part_' + i}
            paragraphs={settings[key]}
            style_id={settings.style_id}
          />
        );
      } else if (part_definition.type === 'RichText') {
        indexes[part_definition.text_type]++;
        const key =
          part_definition.text_type + '_' + indexes[part_definition.text_type];
        return (
          <RichText
            key={'part_' + i}
            text={settings[key]}
            text_type={part_definition.text_type}
            style_id={settings.style_id}
            preview={this.props.preview}
          />
        );
      }
      throw new Error(
        "Unknown document part definition! Cannot render part '" +
          part_definition.type +
          "'.",
      );
    });
  }

  renderLegend(settings, index) {
    return (
      <MapLegend
        position={settings['map_' + index].legend_position || 'bottom_left'}
        map_settings={settings['map_' + index]}
        style_id={settings.style_id}
      />
    );
  }

  render() {
    return (
      <Container
        styles={this.styles}
        className={`doc_render ${this.props.text_type}`}
      >
        {this.renderParts(this.props.doc, this.props.settings)}
      </Container>
    );
  }
}

export default withStore(DocRenderer);
