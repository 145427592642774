import React, { useState } from 'react';

import FaqBase from './FaqBase';
import FaqButton from './FaqButton';

/**
interface Props {
  h_pos: "left" | "right";
  v_pos: "top" | "bottom";
  offset?: {v: number, h: number};
  app_section: "map" | "edit" | "file_system";
}
 */

const FaqContainer = ({
  h_pos,
  v_pos,
  offset = { v: 0, h: 0 },
  app_section,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <FaqButton
        h_pos={h_pos}
        v_pos={v_pos}
        offset={offset}
        onClick={() => setOpen(true)}
      />
      {open && (
        <FaqBase app_section={app_section} onClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default FaqContainer;
