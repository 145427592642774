/**
interface FAQImg {
  type: "image";
  url: string;
}
interface FAQText {
  type: "text";
  question: string;
  answer; string;
}
type FAQData = {
  title: string;
  subtitle: string;
  content: (FAQImage | FAQText)[];
 */

export default {
  title: 'Vanliga frågor',
  subtitle: 'Filhantering',
  content: [
    {
      type: 'text',
      question: 'Hur kommer jag tillbaka till Dashboard från Anslag?',
      answer:
        'Välj knappen Anslag uppe till vänster i startvyn, välj knappen “Till Dashboard” nere till vänster.',
    },
    {
      type: 'text',
      question: 'Hur skapar jag en katalog (mapp)?',
      answer: 'Välj knappen “Skapa” uppe till höger i startvyn, välj “Katalog”',
    },
    {
      type: 'text',
      question: 'Hur arkiverar jag ett Anslag?',
      answer:
        'Greppa anslaget och för det till katalogen “Arkiv”, alternativt klicka på de tre prickarna uppe till höger på Anslaget och välj “Arkivera”, alternativt klicka på Anslaget, under “Åtgärder” välj “Arkivera” ',
    },
    {
      type: 'text',
      question: 'Hur sparar jag ett Anslag så att ingen kan ändra i det?',
      answer:
        'Genom att arkivera ett Anslag kan ingen redigera/ändra i det. Det går att kopiera Anslaget och därmed kan man redigera kopian.',
    },
    {
      type: 'text',
      question:
        'Kan man lyfta ur ett Anslag ur “Arkiv” för att redigera det igen?',
      answer: 'Nej, men man kan kopiera Anslaget och därmed redigera kopian.',
    },
    {
      type: 'text',
      question: 'Hur vet jag vem som skapat ett Anslag och när?',
      answer:
        'Klicka på önskat anslag, välj fliken “Information” för att se detaljer om Anslaget. Här ser man även vem som redigerat Anslaget senast.\n',
    },
    {
      type: 'text',
      question: 'Hur skapar jag ett nytt anslag?',
      answer:
        'Välj knappen “Skapa” uppe till höger i startvyn, välj “Anslag”. Typ av anslag (Omledning-, Text- eller Helkarta) väljer du senare i redigerings vyn.\n',
    },
    {
      type: 'text',
      question: 'Hur redigerar jag ett Anslag?',
      answer:
        'Klicka på ett befintligt Anslag, Under fliken “Åtgärder” väljer du “Redigera”\n',
    },
    {
      type: 'text',
      question: 'Hur kopierar jag ett Anslag?',
      answer:
        'Klicka på anslaget i katalogvyn, under “Åtgärder” väljer du “kopiera”, alternativt klicka på de tre prickarna uppe till höger i Anslaget, välj “Kopiera”.',
    },
  ],
};
