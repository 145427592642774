import React from 'react';
import SSO from 'sso-helper';
import withStore from 'with-store';
import Styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router';
import Notifications from '../../notifications';
import style_templates from '../../style_templates';
import oh from 'output-helpers';
import * as notification_helpers from '../../helpers/notification_helpers';
const IconBus = require('react-icons/lib/md/assignment-ind');

const anim_bounce = keyframes`
            0% {
                transform: translateY(-12px);
            }
            50% {
                transform: translateY(0px);
            }
            99% {
                transform: translateY(-12px);
            }
        `;

const Container = Styled.div`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            .icon_container{
                margin: 200px auto 100px;
                svg{
                    animation: ${anim_bounce} 1s infinite;
                    opacity: 0.3;
                    width: 120px;
                    height: 120px;
                }
            }
        `;

//This component expects to have a .config property.
class SSOComponent extends React.Component {
  constructor(props) {
    super(props);

    if (!window.sso) {
      let sso_config = null;
      if (props.config.api.hasOwnProperty('sso')) {
        sso_config = {
          login_uri: props.config.api.sso.login_uri,
          token_provider_uri: props.config.api.sso.api_uri,
          jwt_acquire_callback: () => {
            console.log('[JWT] acquire callback called');
            if (props.config.notifications) {
              Notifications.setConfig(
                Object.assign({}, props.config.notifications),
              );
              notification_helpers.registerAllListeners();
            }
            props.history.push(props.location.pathname);
            //Update language preference.
            oh.setConfig({ lang: window.sso.getJWT().getClaim('lang') });
          },
          jwt_release_callback: (was_logout) => {
            window.sso = null;
            if (props.config.notifications) {
              notification_helpers.clearAllListeners();
              Notifications.disconnect();
            }
            if (was_logout === false) {
              document.location.href = '/';
            }
          },
        };
      }
      window.sso = new SSO(props.config.service_name, sso_config);
      window.sso
        .init()
        .then(() => {
          if (!window.sso.isLoggedIn()) {
            document.location =
              props.config.api.sso.login_uri +
              '/login' +
              '?service=' +
              encodeURIComponent(props.config.service_name);
            return;
          }
          if (props.config.notifications) {
            Notifications.setConfig(
              Object.assign({}, props.config.notifications),
            );
            notification_helpers.registerAllListeners();
          }
        })
        .catch((err) => {
          console.error(err);
          //TODO: Display user alert, login request critical fail!
          window.sso.logout();
        });
    }
  }

  render() {
    if (!window.sso || !window.sso.isLoggedIn()) {
      return (
        <Container>
          <div className="icon_container">
            <IconBus />
          </div>
        </Container>
      );
    }

    if (this.props.store.users.my_id === -1) {
      oh.setConfig({ lang: window.sso.getJWT().getClaim('lang') });
      this.props.store_actions.users.setMyId(
        window.sso.getJWT().getClaim('uid'),
      );
      let my_org = window.sso.getJWT().getClaim('org');
      if (my_org === 'allbinary') {
        //If user is allbinary get the org we are developing for from the service_name.
        const svc_parts = this.props.store.config.service_name.split('_');
        my_org = svc_parts.length > 1 ? svc_parts[1] : '';
      }
      if (style_templates.hasOwnProperty(my_org) === false) {
        throw new Error("Org '" + my_org + "' has no style_templates defined.");
      }
      withStore.actions.styles.setStyles(
        style_templates.default,
        style_templates[my_org],
      );
      //Ensure that the above state change has gone through before rendering children.
      return null;
    }

    return <div>{this.props.children}</div>;
  }
}

export default withRouter(withStore(SSOComponent));
