import * as styles_reducer from './styles_reducer';

export function setStyles(default_styles, org_styles, state) {
  let styles_arr = org_styles.templates;

  styles_arr.sort((a, b) => {
    return a.name > b.name;
  });

  return {
    type: styles_reducer.SET_STYLES,
    payload: {
      default_styles: default_styles,
      org_default_styles: org_styles.default || {},
      styles_arr: styles_arr,
    },
  };
}
