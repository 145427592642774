import * as store_helpers from './store_helpers';

export const IMAGE_STATE_SET_TN = 'IMAGE_STATE_SET_TN';
export const IMAGE_STATE_REMOVE_TN = 'IMAGE_STATE_REMOVE_TN';
export const IMAGE_STATE_SET_PREVIEW = 'IMAGE_STATE_SET_PREVIEW';

let default_state = {
  by_id: {},
  all: [],
  preview: null,
};

export default function userReducer(state = default_state, action) {
  switch (action.type) {
    case IMAGE_STATE_SET_TN: {
      return store_helpers.SetItem(action.payload, state);
    }
    case IMAGE_STATE_REMOVE_TN: {
      let by_id_copy = Object.assign({}, state.by_id);
      if (by_id_copy.hasOwnProperty(action.payload)) {
        delete by_id_copy[action.payload];
      }

      let index = state.all.findIndex((tn) => tn.id === action.payload);
      let all_copy = [].concat(state.all);
      if (index >= 0) {
        all_copy.splice(index, 1);
      }
      return Object.assign({}, state, { by_id: by_id_copy, all: all_copy });
    }
    case IMAGE_STATE_SET_PREVIEW: {
      return Object.assign({}, state, { preview: action.payload });
    }
    default: {
      return state;
    }
  }
}
