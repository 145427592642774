import * as store_helpers from './store_helpers';

export const SET_OPEN = 'SET_OPEN';
export const UPDATE_OPEN = 'UPDATE_OPEN';
export const DELETE_OPEN = 'DELETE_OPEN';

let default_state = {
  fetched: false,
  all: [],
  by_id: {},
};

export default function openReducer(state = default_state, action) {
  switch (action.type) {
    case SET_OPEN: {
      let new_state = Object.assign({}, default_state, {
        all: [],
        by_id: {},
        fetched: action.payload.fetched,
      });
      action.payload.open.forEach((item) => {
        new_state = store_helpers.SetItem(item, new_state);
      });
      return new_state;
    }
    case UPDATE_OPEN: {
      return store_helpers.SetItem(action.payload, state);
    }
    case DELETE_OPEN: {
      let new_state = Object.assign({}, state);

      if (new_state.by_id.hasOwnProperty(action.payload.id)) {
        let idx = new_state.by_id[action.payload.id].users.findIndex(
          (x) => x.user_id === action.payload.user_id,
        );
        if (idx !== -1) {
          new_state.by_id[action.payload.id].users.splice(idx, 1);
        }
      }

      return new_state;
    }
    default: {
      return state;
    }
  }
}
