export function SetItem(item, state) {
  let updated_by_id = Object.assign({}, state.by_id);
  updated_by_id[item.id] = Object.assign({}, item);
  let updated_all = [].concat(state.all);
  let item_index = updated_all.findIndex((doc) => doc.id === item.id);
  if (item_index < 0) {
    updated_all.push(updated_by_id[item.id]);
  } else {
    updated_all[item_index] = updated_by_id[item.id];
  }
  return Object.assign({}, state, {
    by_id: updated_by_id,
    all: updated_all,
  });
}

export function DeleteItem(item, state) {
  let new_state = Object.assign({}, state);
  new_state.all = new_state.all.filter((x) => x.id !== item.id);
  delete new_state.by_id[item.id];
  return new_state;
}

export function ExtendItem(item, state) {
  if (state.by_id.hasOwnProperty(item.id) === false) {
    //Item can't be extended, it doesn't exist in current state.
    return SetItem(item, state);
  }

  return SetItem(Object.assign({}, state.by_id[item.id], item), state);
}

export function SetItems(item_arr, state, extend = false) {
  let updated_by_id = Object.assign({}, state.by_id);
  let updated_all = [].concat(state.all);
  item_arr.forEach((item) => {
    if (extend && updated_by_id.hasOwnProperty(item.id)) {
      updated_by_id[item.id] = Object.assign({}, updated_by_id[item.id], item);
    } else {
      updated_by_id[item.id] = Object.assign({}, item);
    }
    let item_index = updated_all.findIndex((doc) => doc.id === item.id);
    if (item_index < 0) {
      updated_all.push(updated_by_id[item.id]);
    } else {
      updated_all[item_index] = updated_by_id[item.id];
    }
  });
  return Object.assign({}, state, {
    by_id: updated_by_id,
    all: updated_all,
  });
}

export function ExtendItems(item_arr, state) {
  return SetItems(item_arr, state, true);
}
