import React from 'react';
import styled, { keyframes } from 'styled-components';
import { withRouter } from 'react-router';
import AllbinBar from 'allbin-bar';
import oh from 'output-helpers';
import withStore from 'with-store';
import * as doc_helpers from '../../helpers/document_helpers';
import PromptHOC from '../base/prompt_hoc/PromptHOC';
import FileSystem from '../base/FileSystem';
import Button from '../base/Button';
import SearchResultList from '../base/SearchResultList';
import FAQ from '../base/FAQ';
import SymbolNoDocs from '../../img/no_docs.png';
import SymbolEmptySearch from '../../img/empty_search.png';
import changelog from '../../changelog';
const IconSearch = require('react-icons/lib/md/search');
const IconClose = require('react-icons/lib/md/close');

const fadeIn = keyframes`
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
        `;

const Container = styled.div`
  .navbar {
    background: linear-gradient(
      to right,
      ${(props) => props.theme.colors.brand[1]} 0%,
      #029dfa 100%
    ) !important;
  }
  .create_button {
    cursor: pointer;
    border-radius: 50%;
    color: #fff;
    font-size: 64px;
    width: 100px;
    height: 100px;
    padding-bottom: 13px;
    box-shadow: 0 0 0 5px #0e0e0e59;
    background-color: #fb4d92;
    border: none;
    &:hover {
      box-shadow: 0 0 0 5px #0e0e0e59;
      background-color: #c83d74;
    }
  }
  .main_ctrl {
    background: linear-gradient(
      to right,
      ${(props) => props.theme.colors.brand[1]} 0%,
      #029dfa 100%
    );
    padding: 14px
      ${(props) =>
        props.theme.sizes.general.side_padding +
        'px ' +
        props.theme.sizes.general.top_padding +
        'px'};
    .dropdown {
      margin-right: 20px;
    }
    .right {
      float: right;
      position: relative;
    }
  }
  .main_directory_path {
    // TODO: move
    font-weight: 500;
    padding: 0 ${(props) => props.theme.sizes.general.side_padding + 'px'};
    margin-bottom: 4px;
  }
  .main_file_system {
    padding: 0 ${(props) => props.theme.sizes.general.side_padding + 'px'};
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-row-gap: 3em;
    grid-column-gap: 2em;
    margin-bottom: 100px;
    .doc_preview {
      //animation: ${fadeIn} 0.4s both;
      max-height: 240px;
      .doc_render {
        //transform: scale(0.15);
        //transform-origin: left top;
      }
    }
  }
  .search_block {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 200px;
    color: #fff;
    font-size: 28px;
    transition: all 0.4s;
    &:hover {
      .search_icon {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.3);
      .search_icon {
        border-radius: 200px 0 0 200px;
      }
      .search_input {
        input {
          width: 200px;
        }
      }
    }
    .search_icon {
      align-items: center;
      justify-content: center;
      display: inline-flex;
      vertical-align: top;
      cursor: pointer;
      transition: all 0.4s;
      padding: 6px 8px 6px 10px;
      border-radius: 200px;
    }
    .search_input {
      display: inline-block;
      vertical-align: top;
    }
    .search_input {
      input {
        transition: all 0.3s;
        width: 0;
        padding: 10px 0;
        display: block;
        background: transparent;
        border: none;
        font-size: 18px;
        color: #fff;
        margin-left: 6px;
      }
    }
  }
  .create_blur {
    display: none;
    &.active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
  .create_dropdown {
    z-index: 11;
    display: none;
    position: absolute;
    right: 50px;
    top: 60px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    &.active {
      display: block;
      animation: ${fadeIn} 0.4s both;
    }
    ul {
      li {
        cursor: pointer;
        transition: all 0.2s;
        border-bottom: 1px solid ${(props) => props.theme.colors.border};
        &:hover {
          background-color: #c83d74;
          color: #fff;
          border-color: transparent;
        }
        &:first-child {
          border-radius: 8px 8px 0 0;
        }
        &:last-child {
          border-radius: 0 0 8px 8px;
          border-bottom: none;
        }
        .create_label {
          padding: 20px 72px 20px 32px;
        }
      }
    }
  }

  .main_symbol {
    margin: 32px auto;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    .image {
      animation: ${fadeIn} 0.5s;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      height: 280px;
      &.empty_folder {
        background-image: url(${SymbolNoDocs});
      }
      &.empty_search {
        background-image: url(${SymbolEmptySearch});
      }
    }
    .label {
      animation: ${fadeIn} 0.5s 0.2s both;
      margin-top: 40px;
      color: ${(props) => props.theme.colors.brand[0]};
      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 12px;
        span {
          font-weight: normal;
          font-style: italic;
        }
      }
      .subtitle {
        opacity: 0.7;
      }
    }
  }
`;

class MainView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search_string: '',
      search_active: false,
      render_create_dropdown: false,
      selected_doc: null,
    };

    this.textInput = false;
  }

  triggerCreateFolder() {
    let inputs = [
      {
        type: 'text',
        label: oh.translate('title'),
        default_value: oh.translate('new_folder'),
      },
    ];
    let prompt_props = {
      message: oh.translate('enter_folder_title'),
      title: oh.translate('create_new_folder'),
      class: 'new_folder',
    };
    let createCallback = (values) => {
      this.props.store_actions.spinner.add();
      let name = values[0];
      let current_folder_id = this.props.match.params.folder_id
        ? parseInt(this.props.match.params.folder_id, 10)
        : this.props.store.folders.root_id;
      doc_helpers.createFolder(name, current_folder_id).then((folder) => {
        this.props.store_actions.folders.addFolder(folder);
        this.props.store_actions.spinner.remove();
        this.setState({ render_create_dropdown: false });
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      createCallback,
    );
  }

  triggerCreateDoc() {
    let inputs = [
      {
        type: 'text',
        label: oh.translate('title'),
        default_value: oh.translate('new_document'),
      },
    ];
    let prompt_props = {
      message: oh.translate('enter_document_title'),
      title: oh.translate('create_new_document'),
      class: 'new_doc',
    };
    let createCallback = (values) => {
      const name = values[0].trim();
      if (name.length === 0) {
        this.setState({ render_create_dropdown: false });
        return;
      }
      this.props.store_actions.spinner.add();
      let doc_data = {
        name: name,
      };
      let current_folder_id = this.props.match.params.folder_id
        ? parseInt(this.props.match.params.folder_id, 10)
        : this.props.store.folders.root_id;
      doc_helpers.createDocument(doc_data, current_folder_id).then((doc) => {
        this.props.store_actions.docs.addDocument(doc);
        this.setState({ render_create_dropdown: false });
        setTimeout(() => {
          this.props.store_actions.spinner.remove();
          this.props.history.push('/preview/' + doc.id + '/' + doc.folder_id);
        }, 1);
      });
    };
    this.props.UserPrompt.prompt(
      {
        inputs: inputs,
        props: prompt_props,
      },
      createCallback,
    );
  }

  render() {
    let root_id = this.props.store.folders.root_id;
    let current_folder_id = this.props.match.params.folder_id
      ? parseInt(this.props.match.params.folder_id, 10)
      : root_id;

    let folder_content = this.props.store_ext.getFolderContents(
      current_folder_id,
    );
    let search_result = [];
    if (this.state.search_string.trim().length > 1) {
      search_result = this.props.store_ext.searchDocs(
        this.state.search_string.trim(),
        true,
      );
    }

    return (
      <Container>
        <div
          onClick={() => this.setState({ render_create_dropdown: false })}
          className={
            'create_blur ' + (this.state.render_create_dropdown ? 'active' : '')
          }
        />
        <AllbinBar
          language={window.sso.getJWT().getClaim('lang')}
          background={'#1563EE'}
          navbar={true}
          styled_service_name={true}
          logout={true}
          service_name={'Anslag'}
          enable_password_reset={false}
          dashboard_link={true}
          contact={true}
          changelog={changelog}
          help={false}
          show_credentials={true}
        />
        <div className="main_ctrl">
          <div
            className={`search_block ${
              this.state.search_active ? 'active' : ''
            }`}
          >
            <div
              className="search_icon"
              onClick={() => {
                this.setState({
                  search_active: !this.state.search_active,
                  search_string: '',
                });
                this.textInput.focus();
              }}
            >
              {this.state.search_active ? <IconClose /> : <IconSearch />}
            </div>
            <div className="search_input">
              <input
                ref={(input) => {
                  this.textInput = input;
                }}
                value={this.state.search_string}
                onChange={(e) =>
                  this.setState({ search_string: e.target.value })
                }
              />
            </div>
          </div>

          <div className="right">
            {!this.state.search_active && !folder_content.is_archive && (
              <Button
                className="create_button"
                filled
                label={oh.translate('create')}
                onClick={() => {
                  this.setState({
                    render_create_dropdown: !this.state.render_create_dropdown,
                  });
                }}
              />
            )}

            <div
              className={`create_dropdown ${
                this.state.render_create_dropdown ? 'active' : ''
              }`}
            >
              <ul>
                <li onClick={() => this.triggerCreateFolder()}>
                  <div className="create_icon" />
                  <div className="create_label">{oh.translate('folder')}</div>
                </li>
                <li onClick={() => this.triggerCreateDoc()}>
                  <div className="create_icon" />
                  <div className="create_label">{oh.translate('bulletin')}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main_content">
          {this.state.search_string.trim() ? (
            <SearchResultList
              results={search_result}
              search_string={this.state.search_string.trim()}
              navigate={(path) => {
                if (path === '/') {
                  this.setState({
                    search_string: '',
                    search_active: false,
                  });
                } else {
                  this.props.history.push(path);
                }
              }}
            />
          ) : (
            <FileSystem
              folder_content={folder_content}
              navigate={(path) => {
                this.props.history.push(path);
              }}
              current_folder_id={current_folder_id}
            />
          )}
        </div>
        <FAQ app_section={'file_system'} />
      </Container>
    );
  }
}

export default withRouter(withStore(PromptHOC(MainView)));
