import * as store_helpers from './store_helpers';

export const USERS_STATE_SET = 'USERS_STATE_SET';
export const USERS_STATE_SET_USER = 'USERS_STATE_SET_USER';
export const USERS_STATE_SET_MY_ID = 'USERS_STATE_SET_MY_ID';

let default_state = {
  fetched: false,
  all: [],
  by_id: {},
  my_id: -1,
};

export default function userReducer(state = default_state, action) {
  switch (action.type) {
    case USERS_STATE_SET: {
      return Object.assign({}, state, action.payload);
    }
    case USERS_STATE_SET_MY_ID: {
      return Object.assign({}, state, { my_id: action.payload });
    }
    case USERS_STATE_SET_USER: {
      return store_helpers.SetItem(action.payload, state);
    }
    default: {
      return state;
    }
  }
}
