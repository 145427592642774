import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

const Container = styled.div`
  text-align: center;
  position: relative;
  overflow: auto;
`;

class Scroller extends React.Component {
  componentDidUpdate(prevProps) {}

  setCenter() {
    const node = ReactDOM.findDOMNode(this);
    node.scrollTop = (node.scrollHeight - node.clientHeight) / 2;
    node.scrollLeft = (node.scrollWidth - node.clientWidth) / 2;
  }

  render() {
    return (
      <Container className="preview_doc_render">
        {this.props.children}
      </Container>
    );
  }
}

export default Scroller;
