import oh from 'output-helpers';
import * as types from './folders_reducer';

let root_bit = 1 << 0;
let archive_bit = 1 << 1;

export function addFolder(folder_data, state) {
  return {
    type: types.FOLDERS_STATE_SET_FOLDER,
    payload: folder_data,
  };
}
export function setFolder(folder_data, state) {
  return {
    type: types.FOLDERS_STATE_SET_FOLDER,
    payload: folder_data,
  };
}
export function deleteFolder(folder_id, state) {
  return {
    type: types.FOLDERS_STATE_DELETE_FOLDER,
    payload: folder_id,
  };
}

export function setFoldersStateByArr(folder_arr, fetched = false, state) {
  let temp_children = {};
  let by_id = {};
  let all = [];
  let root_id = null;
  let archive_id = null;
  folder_arr.forEach((folder) => {
    let children = temp_children.hasOwnProperty(folder.id)
      ? temp_children[folder.id]
      : [];
    let temp_folder = Object.assign(
      {},
      {
        children: children,
        name: folder.name,
        id: folder.id,
        logs: folder.logs || [],
        parent_id: folder.parent_id,
        last_modified: folder.last_modified,
      },
    );

    //Set special flags.
    if (folder.flags & root_bit) {
      temp_folder.is_root = true;
      root_id = folder.id;
    }
    if (folder.flags & archive_bit) {
      temp_folder.is_archive = true;
      archive_id = folder.id;
      temp_folder.name = oh.translate('archive_folder');
    }

    if (by_id.hasOwnProperty(temp_folder.parent_id)) {
      by_id[temp_folder.parent_id].children.push(temp_folder.id);
    } else {
      if (temp_children.hasOwnProperty(temp_folder.parent_id) === false) {
        temp_children[temp_folder.parent_id] = [];
      }
      temp_children[temp_folder.parent_id].push(temp_folder.id);
    }
    by_id[folder.id] = temp_folder;
    all.push(temp_folder);
  });
  if (root_id === null || archive_id === null) {
    throw new Error('Root or Archive folder missing in folder array!');
  }
  by_id[archive_id].parent_id = root_id;
  by_id[root_id].children.push(archive_id);
  return {
    type: types.FOLDERS_STATE_SET,
    payload: {
      by_id: by_id,
      all: [].concat(all),
      fetched: fetched,
      archive_id: archive_id,
      root_id: root_id,
    },
  };
}
