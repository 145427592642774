export let types = {
  bus_stop: {
    id: 'bus_stop',
    label: 'bus_stop',
    guide_text: {
      title: 'place_bus_stop',
      subititle: 'make_choices_for_new_bus_stop',
    },
    active_guide_text: {
      title: 'placing_bus_stop',
      subititle: 'you_have_activated_the_tool',
    },
    icon: 'marker',
    color: 'brand',
    settings: {},
  },
  line: {
    id: 'line',
    label: 'line',
    guide_text: {
      title: 'draw_new_line',
      subititle: 'make_choices_for_new_line',
    },
    active_guide_text: {
      title: 'drawing_new_line',
      subititle: 'you_have_activated_the_tool',
    },
    icon: 'line',
    color: 'red',
    settings: {},
  },
  text: {
    id: 'text',
    label: 'text',
    guide_text: {
      title: 'write_new_text',
      subititle: 'make_choices_for_make_text',
    },
    active_guide_text: {
      title: 'writing_new_text',
      subititle: 'you_have_activated_the_tool',
    },
    icon: 'text',
    color: 'green',
    settings: {},
  },
  symbol: {
    id: 'symbol',
    label: 'symbol',
    guide_text: {
      title: 'place_new_symbol',
      subititle: 'make_choices_for_new_symbol',
    },
    active_guide_text: {
      title: 'placing_new_symbol',
      subititle: 'you_have_activated_the_tool',
    },
    icon: 'marker',
    color: 'teal',
    settings: {},
  },
};
