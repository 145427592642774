import React from 'react';
import oh from 'output-helpers';
import Input from '../Input';
import Dropdown from '../../base/Dropdown';
import Button from '../Button';
import RouteGridSelect from '../../base/RouteGridSelect';
import withConfig from 'with-config';

//import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Array } from 'es6-shim';
const IconClose = require('react-icons/lib/md/close');

const SelectedRoutes = ({ selected_routes, removeSelectedRoute }) => {
  if (selected_routes.length < 1) {
    return null;
  }
  return (
    <div className="setting">
      <p className="setting_label">{oh.translate('selected_routes')}</p>
      <RouteGridSelect
        options={selected_routes}
        onClick={(e) => {
          removeSelectedRoute(e);
        }}
      />
    </div>
  );
};

const PassingRoutes = ({
  passing_routes,
  addPassingRoute,
  other_period_stop,
}) => {
  if (other_period_stop) {
    return (
      <div className="setting">
        <p className="setting_label">{oh.translate('passing_routes')}</p>
        {other_period_stop && <p>{oh.translate('other_period_stop')}</p>}
      </div>
    );
  }
  if (passing_routes.length < 1) {
    return null;
  }
  return (
    <div className="setting">
      <p className="setting_label">{oh.translate('passing_routes')}</p>
      {other_period_stop && <p>{oh.translate('other_period_stop')}</p>}
      {!other_period_stop && (
        <RouteGridSelect
          options={passing_routes}
          onClick={(e) => {
            addPassingRoute(e);
          }}
        />
      )}
    </div>
  );
};

const LegendSettings = (props) => {
  const settings = props.map_settings;
  let text_in_legend = '';
  let legend_index = -1;
  let legend_content = [];
  if (
    settings.hasOwnProperty('legend_content') &&
    Array.isArray(settings.legend_content)
  ) {
    legend_content = settings.legend_content;
    legend_index = legend_content.findIndex(
      (content) => content.type === props.type,
    );
    if (legend_index > -1) {
      text_in_legend = legend_content[legend_index].text;
    }
  }
  return (
    <>
      <div className="setting">
        <p className="setting_label">{oh.translate('visible_in_legend')}</p>
        <div className="settings_checkbox">
          <input
            type="checkbox"
            checked={legend_index > -1}
            onChange={(e) => {
              const checked = e.target.checked;
              if (legend_index > -1 && !checked) {
                const updated_legend = [...legend_content];
                updated_legend.splice(legend_index, 1);
                props.onLegendChange(updated_legend);
              } else if (checked) {
                const texts = props.config.legend_icon_default_text;
                const default_text = texts.hasOwnProperty(props.type)
                  ? texts[props.type]
                  : '';
                const legend_entry = {
                  type: props.type,
                  text: default_text,
                };
                const updated_legend = [...legend_content];
                updated_legend.push(legend_entry);
                props.onLegendChange(updated_legend);
              }
            }}
          />
        </div>
        {legend_index < 0 ? null : (
          <div className="settings_checkbox_content">
            <Input
              value={text_in_legend}
              onChange={(e) => {
                const legend_entry = {
                  type: props.type,
                  text: e.target.value,
                };
                const updated_legend = [...legend_content];
                updated_legend[legend_index] = legend_entry;
                props.onLegendChange(updated_legend);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const MapSettingsPanelMarker = (props) => {
  return (
    <div className="floating_item_settings_container">
      <div className={`floating_item_settings marker`}>
        <div className="item_title">
          <p>
            {oh.translate('options_for')} {oh.translate('marker')}
          </p>
          <div
            className="item_close"
            onClick={() => {
              props.onClose();
            }}
          >
            <IconClose />
          </div>
        </div>
        <div className="item_settings">
          <div className="setting" style={{ zIndex: 2 }}>
            <p className="setting_label">{oh.translate('marker_type')}</p>
            <Dropdown
              block
              empty_message={oh.translate('no_marker_types')}
              selected={props.type}
              options={props.available_types}
              selectedOption={(item) => {
                props.onTypeChange(item);
              }}
            />
          </div>
          <div className="setting">
            <p className="setting_label">{oh.translate('text')}</p>
            <Input
              value={props.text}
              onChange={(e) => {
                props.onTextChange(e.target.value);
              }}
            />
          </div>
          <SelectedRoutes {...props} />
          <PassingRoutes {...props} />
          <div className="setting">
            <p className="setting_label">{oh.translate('other_routes')}</p>
            <RouteGridSelect
              options={props.other_routes}
              onClick={(e) => {
                props.addPassingRoute(e);
              }}
            />
          </div>
          <LegendSettings {...props} />
          <div className="setting_divider"></div>
          <div className="setting">
            <Button
              block
              filled
              red
              label={oh.translate('remove')}
              onClick={() => {
                props.onDelete();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withConfig(MapSettingsPanelMarker);
